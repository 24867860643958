import { waitSynchronously } from '@equips/common-resources';
import {
  deactivateServiceRequest,
  deleteServiceRequest,
  EquipmentStatusType,
  OrganizationFeatureFlagEnum,
  patchServiceRequest,
  reactivateServiceRequest,
  ServiceRequest,
  ServiceRequestStatusType,
} from '@equips/entities-schema';
import React, { useState } from 'react';
import { CellProps } from 'react-table';
import { useAuth } from '../../common/auth/AuthContext';
import DropDown from '../../common/components/DropDown';
import useMutation from '../../common/hooks/useMutation';
import Urls from '../../routes/Urls';
import ModifyTheServiceRequest, { modalStateOptions } from './components/ModifyServiceRequest/ModifyTheServiceRequest';

const mutateStatusOfEntity = async ({ entityId, action }) => {
  if (action === 'DELETE') await deleteServiceRequest({ entityId });
  if (action === 'DEACTIVATE') await deactivateServiceRequest({ entityId });
  if (action === 'REACTIVATE') await reactivateServiceRequest({ entityId });
};

export function ServiceRequestsRowActions(props: React.PropsWithChildren<CellProps<ServiceRequest, any>>) {
  const { userCan, roles, internalUsers, determineFeatureFlagVisibility } = useAuth();
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(modalStateOptions.closed);

  const entityId = props.row.original.metadata?.equipmentId;
  const deactivated = props.row.original.metadata?.deactivatedAt != null;
  const isInternalDispatchTurnedOn = !props.row.original.organizationMetadata?.autoForwardAllDispatchRequests;
  const isProposed = props.row.original.metadata?.requestStatus === ServiceRequestStatusType.Proposed;
  const isEquipmentProposed = props.row.original.metadata?.equipment?.metadata?.equipmentStatus === EquipmentStatusType.Proposed;

  const [updateServiceRequest, { saving }] = useMutation(patchServiceRequest, {
    successMessage: 'Service Request Status has been updated',
  });

  const approveServiceRequest = async (requestStatus: ServiceRequestStatusType) => {
    if (requestStatus === ServiceRequestStatusType.EquipsDispatch) {
      setModalState(modalStateOptions.dispatchToEquips);
    } else if (requestStatus === ServiceRequestStatusType.InternalDispatch && (!isInternalDispatchTurnedOn || isEquipmentProposed)) {
      setModalState(modalStateOptions.dispatchInternally);
    } else {
      updateServiceRequest({
        serviceRequestId: props.row.original.metadata?.serviceRequestId || '',
        metadata: { requestStatus: requestStatus },
      });
      await waitSynchronously(1000);
      window.location.reload();
    }
  };

  return (
    <>
      <DropDown
        containerClasses="more-actions"
        actions={[
          ...(props.row.original.metadata?.requestStatus === ServiceRequestStatusType.ProviderDispatch
            ? [
                {
                  Component: <>Technician Check In</>,
                  to: `${Urls.SERVICE_REQUESTS}/${props.row.original.metadata?.serviceRequestId}/checkin`,
                },
              ]
            : []),
          ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
            ? [
                {
                  Component: <>View equipment</>,
                  to: `${Urls.EQUIPMENT}/${props.row.original.metadata?.equipmentId}`,
                },
              ]
            : []),
          ...(isProposed && userCan([roles.customerOrganizationAdmin, ...internalUsers])
            ? [
                { border: true },
                {
                  Component: <>{saving ? 'Saving...' : 'Approve Request - Dispatch To Provider'}</>,
                  action: () => approveServiceRequest(ServiceRequestStatusType.EquipsDispatch),
                },
                {
                  Component: <>{saving ? 'Saving...' : 'Approve Request - Dispatch Internally'}</>,
                  action: () => approveServiceRequest(ServiceRequestStatusType.InternalDispatch),
                },
                {
                  Component: 'Deny',
                  action: () => {
                    setModalState(modalStateOptions.denyProposedSR);
                  },
                },
              ]
            : []),
          ...(deactivated && userCan(internalUsers)
            ? [
                { border: true },
                {
                  Component: <>{loading ? 'Loading...' : 'Reactivate'}</>,
                  action: async () => {
                    setLoading(true);
                    await mutateStatusOfEntity({ entityId, action: 'REACTIVATE' });
                    setLoading(false);
                  },
                },
                ...(userCan([roles.superGlobalAdmin])
                  ? [
                      {
                        Component: <>{loading ? 'Loading...' : 'Delete'}</>,
                        action: async () => {
                          setLoading(true);
                          await mutateStatusOfEntity({ entityId, action: 'DELETE' });
                          setLoading(false);
                        },
                      },
                    ]
                  : []),
              ]
            : [
                ...(userCan(internalUsers)
                  ? [
                      { border: true },
                      {
                        Component: <>{loading ? 'Loading...' : 'Deactivate'}</>,
                        action: async () => {
                          setLoading(true);
                          await mutateStatusOfEntity({ entityId, action: 'DEACTIVATE' });
                          setLoading(false);
                        },
                      },
                    ]
                  : []),
              ]),
        ]}
      />
      {modalState !== modalStateOptions.closed && (
        <ModifyTheServiceRequest
          fetch={() => null}
          modalState={modalState}
          setModalState={setModalState}
          serviceRequestId={props.row.original.metadata?.serviceRequestId || ''}
          equipment={props.row.original.metadata?.equipment}
          canDispatchInternally={isInternalDispatchTurnedOn}
          serviceRequestData={props.row.original}
        />
      )}
    </>
  );
}

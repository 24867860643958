import React from 'react';
import TextField from '@mui/material/TextField';
import { RecurrenceOptionsType } from '@equips/entities-schema';
import RadioCard from '../Form/RadioCards';
import FormGroup from '../Form/FormGroup';

type RequestFormRecurrenceProps = {
  recurrenceOption: RecurrenceOptionsType;
  recurrenceInterval: number | null;
  onChange: (args: { recurrenceInterval: number | null; recurrenceOption: RecurrenceOptionsType }) => void;
};

export const descriptionMap = {
  [RecurrenceOptionsType.Once]: () => `This service request will not repeat once completed.`,
  [RecurrenceOptionsType.Monthly]: () =>
    `This service request will repeat at the start of every month, after completion of the previous request.`,
  [RecurrenceOptionsType.Custom]: ({ recurrenceInterval }: { recurrenceInterval: number | null }) =>
    `This service request will repeat every ${
      recurrenceInterval === 1 ? 'day' : `${recurrenceInterval} days`
    }, after completion of the previous request.`,
};

const RequestFormRecurrenceOption = (props: RequestFormRecurrenceProps) => {
  const recurrenceHelperDescription = descriptionMap[props.recurrenceOption] || (() => '');

  return (
    <FormGroup className="mt-4" fullWidth>
      <RadioCard
        required
        id="recurrenceOption"
        label="When do you want this to happen next?"
        labelSubtitle={recurrenceHelperDescription({ recurrenceInterval: props?.recurrenceInterval })}
        cardSize="flex-grow"
        options={[
          { label: 'One Time', value: RecurrenceOptionsType.Once },
          { label: 'Monthly', value: RecurrenceOptionsType.Monthly },
          { label: 'Custom', value: RecurrenceOptionsType.Custom },
        ]}
        onChange={(value) =>
          props.onChange({
            recurrenceOption: value,
            recurrenceInterval: value === RecurrenceOptionsType.Custom ? props.recurrenceInterval : null,
          })
        }
        value={props.recurrenceOption || RecurrenceOptionsType.Once}
      />
      {props.recurrenceOption === RecurrenceOptionsType.Custom && (
        <div className="mt-4 flex items-center gap-3">
          Every
          <TextField
            value={props.recurrenceInterval}
            onChange={(e) => {
              const recurrenceInterval = parseInt(e.target.value);
              const recurrenceOption = RecurrenceOptionsType.Custom;

              props.onChange({ recurrenceInterval, recurrenceOption });
            }}
            id="customRecurrenceOption"
            InputProps={{
              inputProps: { type: 'number', min: 0 },
            }}
            variant="outlined"
            size="small"
            required={props.recurrenceOption === RecurrenceOptionsType.Custom}
          />
          Days
        </div>
      )}
    </FormGroup>
  );
};

export default RequestFormRecurrenceOption;

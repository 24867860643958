import React from 'react';
import { Link } from 'react-router-dom';
import { JsonParam } from 'serialize-query-params';
import { Invoice } from '@equips/entities-schema';
import Urls from '../../routes/Urls';
import DropDown from '../../common/components/DropDown';
import { enumObjectToSelectOptions, getEnumDisplayName } from '../../graphql/enums';
import { providerValueForFilter, clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { AuthContextValue } from '../../common/auth/AuthContext';
import { internalUsers } from '../../common/auth/roles';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import { centsToMoney } from '../../common/functions/moneyFunctions';
import { TableColumn } from '../../common/components/DataTable';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import DisplayTime from '../../common/components/Date/DisplayTime';
import TierIndicator from '../organizations/components/TierIndicator';
import { invoiceStatuses } from './invoiceStatuses';

export function getInvoiceColumns(userCan: AuthContextValue['userCan'], userCanViewDetails: boolean): TableColumn<Invoice>[] {
  return [
    {
      id: 'metadata.shortId',
      Header: 'Number',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => {
        const invoiceId = data?.row?.original?.metadata?.invoiceId;

        return userCanViewDetails ? (
          <div className="flex justify-between">
            <Link className="active font-bold" data-testid="toInvoiceFromTable" to={`${Urls.INVOICES}/${invoiceId}`}>
              {data.value}
            </Link>
            <DropDown
              isSmall
              containerClasses="more-actions"
              actions={[
                {
                  'data-invoiceid': invoiceId,
                  'data-testid': 'viewChargesLink',
                  Component: <>View charges</>,
                  to: `${Urls.CHARGES}/?invoiceId=${invoiceId}`,
                },
              ]}
            />
          </div>
        ) : (
          <>{data.value}</>
        );
      },
    },
    {
      id: 'metadata.externalInvoiceNumber',
      Header: 'Provider Invoice #',
      accessor: (data) => data.metadata?.externalInvoiceNumber,
      Cell: (data) => {
        const externalInvoiceNumber = data?.row?.original?.metadata?.externalInvoiceNumber || 'None';
        const fileGetUrl = data?.row?.original?.metadata?.file?.metadata?.presignedGetUrl ?? '#';

        return (
          <a className="active" href={fileGetUrl}>
            {externalInvoiceNumber}
          </a>
        );
      },
    },
    {
      id: 'metadata.invoiceStatus',
      Header: 'Status',
      accessor: (data) => data.metadata?.invoiceStatus,
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(invoiceStatuses),
        defaultValue: [],
        filterType: JsonParam,
      },
      width: defaultColumnWidths.mediumText,
      Cell: (data) => getEnumDisplayName(data.value, invoiceStatuses),
    },
    {
      id: 'equipment.metadata.shortId',
      Header: 'Equipment #',
      accessor: (data) => data.equipment?.[0]?.metadata?.shortId,
      disableSortBy: true,
      Cell: (data) => (
        <Link
          target="_blank"
          className="active"
          data-testid="toEquipment"
          to={`${Urls.EQUIPMENT}/${data?.row?.original?.equipment?.[0]?.equipmentId}`}
        >
          {data?.row?.original?.equipment?.[0]?.metadata?.shortId}
        </Link>
      ),
    },
    {
      id: 'equipment.metadata.equipmentName',
      Header: 'Equipment name',
      accessor: (data) => data.equipment?.[0]?.metadata?.equipmentName,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      Cell: (data) => data?.row?.original?.equipment?.[0]?.metadata?.equipmentName || '',
    },
    {
      id: 'equipment.specMetadata.category.metadata.name',
      Header: 'Equipment category',
      accessor: (data) => data.equipment?.[0]?.specMetadata?.category?.metadata?.name,
      disableSortBy: true,
    },
    {
      id: 'metadata.finalizedOn',
      Header: 'Finalized on',
      accessor: (data) => data.metadata?.finalizedOn,
      Cell: (data) => toDateStringFromUnixMillisecondTimestamp(data?.row?.original?.metadata?.finalizedOn) || 'Not finalized',
    },
    {
      id: 'metadata.finalizedByUserId',
      Header: 'Finalized by',
      accessor: (data) => data.metadata?.finalizedByUser,
      Cell: (data) => {
        return <Link to={`${Urls.USERS}/${data?.value?.metadata?.userId}`}>{data?.value?.metadata?.fullName ?? ''}</Link>;
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
      },
      disableSortBy: true,
    },
    {
      Header: 'Adjudicated on',
      id: 'metadata.adjudicatedOn',
      accessor: (data) => data.metadata?.adjudicatedOn,
      width: 150,
      toText: (data) =>
        `${
          toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.adjudicatedOn, {
            customFormatString: 'MM/DD/YYYY hh:mm a',
          }) || 'Not adjudicated'
        }`,
      Cell: (data) => <DisplayTime timestamp={data.value} />,
      filterOptions: {
        type: TableFilterTypes.dateRange,
        label: 'Adjudicated on',
      },
    },
    {
      Header: 'Adjudicated by',
      id: 'metadata.adjudicatedByUserName',
      accessor: (data) => data.metadata?.adjudicatedByUserName,
    },
    {
      id: 'metadata.adjudicatedByUserId',
      Header: 'Adjudicated by UserId',
      accessor: (data) => data.metadata?.adjudicatedByUserId,
      filterOptions: {
        type: TableFilterTypes.userSelect,
        label: 'Adjudicated by',
      },
    },
    {
      Header: 'Approved on',
      id: 'metadata.approvedOn',
      accessor: (data) => data.metadata?.approvedOn,
      width: 150,
      toText: (data) =>
        `${
          toDateStringFromUnixMillisecondTimestamp(data.value, {
            customFormatString: 'MM/DD/YYYY hh:mm a',
          }) || 'Not approved'
        }`,
      Cell: (data) => <DisplayTime timestamp={data.value} />,
      filterOptions: {
        type: TableFilterTypes.dateRange,
        label: 'Approved on',
      },
    },
    {
      id: 'metadata.approvedByUserId',
      Header: 'Approved by',
      accessor: (data) => data.metadata?.approvedByUser,
      Cell: (data) => {
        return <Link to={`${Urls.USERS}/${data?.value?.metadata?.userId}`}>{data?.value?.metadata?.fullName ?? ''}</Link>;
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
      },
      disableSortBy: true,
    },
    ...(userCan(internalUsers)
      ? [
          {
            id: 'metadata.invoiceTotal',
            Header: 'Total $$',
            accessor: (data) => data.metadata?.invoiceTotal,
            Cell: (data) => centsToMoney(data?.row?.original?.metadata?.invoiceTotal),
          },
          {
            id: 'metadata.variance',
            Header: 'Variance',
            disableSortBy: true,
            accessor: (data) => data?.metadata?.variance,
            Cell: (data) => {
              const variance = data?.value;
              const serviceRequestNumber = data?.row?.original?.metadata?.serviceRequestNumber;
              const hasMultipleSRNumbers = typeof serviceRequestNumber === 'string' && serviceRequestNumber.includes(',');
              const hasVariance = variance !== null || (variance !== undefined && typeof variance === 'number');

              if (hasMultipleSRNumbers) {
                return <span>More than 1 SR</span>;
              }

              return (hasVariance && variance < 0 && <span className="text-red-700">{centsToMoney(variance)}</span>) || '';
            },
          },
          {
            id: 'metadata.providerOrganizationId',
            Header: 'Provider',
            accessor: (data) => data.metadata?.providerOrganization?.metadata?.organizationName,
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.organizationSelect,
              label: 'Provider',
              typeOfOrganizationToFind: providerValueForFilter,
            },
            disableSortBy: true,
            Cell: (data) => (
              <Link
                className="secondary-active"
                to={`${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.providerOrganization?.metadata?.organizationId}`}
              >
                {data.value} <TierIndicator tier={data?.row?.original?.metadata?.providerOrganization?.metadata?.tier} />
              </Link>
            ),
          },
          {
            id: 'metadata.clientOrganizationId',
            Header: 'Client',
            accessor: (data) => data.metadata?.clientOrganization?.metadata?.organizationName,
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.organizationSelect,
              label: 'Client',
              typeOfOrganizationToFind: clientValueForFilter,
            },
            disableSortBy: true,
            Cell: (data) => (
              <Link
                className="secondary-active"
                to={`${Urls.ORGANIZATIONS}/${data?.row?.original?.metadata?.clientOrganization?.metadata?.organizationId}`}
              >
                {data.value} <TierIndicator tier={data?.row?.original?.metadata?.clientOrganization?.metadata?.tier} />
              </Link>
            ),
          },
          {
            id: 'metadata.assigneeUserId',
            Header: 'Assignee',
            accessor: (data) => data.metadata?.assigneeUserId,
            filterOptions: {
              hidden: !userCan(internalUsers),
              type: TableFilterTypes.userSelect,
            },
            Cell: (data) => data?.row?.original?.metadata?.assignee?.metadata?.fullName || '',
          },
          {
            id: 'metadata.followUpDate',
            Header: 'Follow-up date',
            accessor: (data) => data.metadata?.followUpDate,
            Cell: (data) => <DisplayTime timestamp={data.value} />,
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
          },
          {
            id: 'metadata.receivedAt',
            Header: 'Received',
            accessor: (data) => data.metadata?.receivedAt,
            Cell: (data) => <DisplayTime timestamp={data.value} />,
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
          },
          {
            id: 'metadata.maintenanceContractSavings',
            Header: 'Maintenance Contract Savings',
            accessor: (data) => data.metadata?.maintenanceContractSavings,
            Cell: (data) => centsToMoney(data?.row?.original?.metadata?.maintenanceContractSavings),
          },
        ]
      : []),
  ];
}

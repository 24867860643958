import React, { useState, useMemo } from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AccountTree from '@mui/icons-material/AccountTree';
import { Location, Maybe } from '@equips/entities-schema';
import { useLocations } from '../../hooks/useLocations';
import createRandomString from '../../functions/createRandomString';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import Input from '../Form/Input';
import InputLoader from '../Form/InputLoader';
import Button from '../Buttons/Button';
import { useAuth } from '../../auth/AuthContext';
import LocationCard from './LocationCard';
import ReactSelectRequiredInput from './ReactSelectRequiredInput';

const indentClasses = ['', 'pl-8', 'pl-16', 'pl-24'];

type LocationDialogBoxProps = {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  label?: string;
  emptySelectionText?: string;
  selectedLocation?: string | string[];
  locations: Maybe<Location>[];
  handleSelect: (...args: any[]) => any;
  includeEmptySelectOption?: boolean;
  showSublocations?: boolean;
  multiselect?: boolean;
};

export function LocationDialogBox({
  open,
  setOpen,
  handleSelect,
  label,
  locations,
  selectedLocation = [],
  includeEmptySelectOption = true,
  emptySelectionText = 'Any',
  showSublocations = true,
  multiselect = false,
}: LocationDialogBoxProps) {
  const allLocationIds: string[] = [];
  const [locationIds, setLocationIds] = useState(
    new Set<string>(Array.isArray(selectedLocation) ? selectedLocation : selectedLocation ? [selectedLocation] : []),
  );
  const [q, setQ] = useState('');

  const filteredLocations = locations
    .filter((location): location is Location => {
      if (!location) return false;
      const { metadata, address } = location;
      const sublocationsName = metadata?.sublocations?.map((sublocation) => sublocation?.metadata?.locationDisplayName) ?? [];
      const searchable = [metadata?.locationDisplayName, metadata?.shortId, formatAddressAsOneLine(address), ...sublocationsName].join(' ');
      return !!searchable.match(new RegExp(q ?? '', 'gi'));
    })
    .sort((a, b) => a?.metadata?.locationDisplayName?.localeCompare(b?.metadata?.locationDisplayName ?? '') ?? 0);

  const selectItem = (selected: boolean, locationId: string | null) => {
    if (!locationId) return;
    if (selected) {
      setLocationIds((prev) => new Set(prev.add(locationId)));
    } else {
      setLocationIds((prev) => {
        const newLocationIds = new Set(prev);
        newLocationIds.delete(locationId);
        return newLocationIds;
      });
    }
  };

  const createTree = (location: Location, level: number) => {
    const locationId = location?.locationId || '';
    const sublocations = location?.metadata?.sublocations;
    let sublocationIds = sublocations?.map((x) => x?.locationId || '') || [];
    return {
      element: (
        <LocationCard
          data-testid="locationDialogOption"
          className={`rounded-none border-x-0 border-b-0 ${indentClasses[level]}`}
          location={location}
          onClick={(value) => (multiselect ? selectItem(!locationIds.has(locationId), value) : handleSelect(value))}
          selected={locationIds.has(locationId)}
          onChange={multiselect ? (selected) => selectItem(selected, locationId) : undefined}
          expanded={!!q}
          actions={
            multiselect && sublocationIds.length > 0
              ? [
                  {
                    text: 'Select this location and all sublocations',
                    Icon: AccountTree,
                    onClick: () => {
                      setLocationIds((prev) => {
                        if (![...prev].includes(locationId)) {
                          return new Set([...prev, locationId, ...sublocationIds]);
                        } else {
                          return new Set([...[...prev].filter((id) => ![locationId, ...sublocationIds].includes(id))]);
                        }
                      });
                    },
                  },
                ]
              : []
          }
        >
          {sublocations?.map((sublocation: Location) => {
            const { element, sublocationIds: childSublocationIds } = createTree(sublocation, level + 1);
            sublocationIds = [...sublocationIds, ...childSublocationIds];
            return <div key={sublocation?.locationId}>{element}</div>;
          })}
        </LocationCard>
      ),
      sublocationIds,
    };
  };

  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="locationSelect" open={open} fullWidth maxWidth="sm">
      <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-400 bg-white">
        <DialogTitle id="locationSelect">{label}</DialogTitle>
        {multiselect && (
          <div className="p-3">
            <Button disabled={locationIds.size < 1} onClick={() => handleSelect(locationIds[0], [...locationIds])} blue tiny type="button">
              Apply Selection
            </Button>
          </div>
        )}
      </div>
      <div className="flex p-3">
        <Input
          autoFocus
          className="no-drag-zone"
          id="searchQueryForLocations"
          value={q}
          onChange={(event) => setQ(event.target.value)}
          placeholder="Search locations"
        />
        {q && (
          <button className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
            Clear
          </button>
        )}
      </div>
      <div className="h-64 overflow-scroll bg-gray-50">
        {includeEmptySelectOption && emptySelectionText && (
          <LocationCard
            className="rounded-none border-x-0 border-b-0"
            key="emptySelection"
            data-testid="locationDialogSelectAny"
            emptyText={emptySelectionText}
            onClick={handleSelect}
          />
        )}

        {multiselect && (
          <LocationCard
            className="rounded-none border-x-0 border-b-0"
            key="selectAll"
            data-testid="locationDialogSelectAll"
            emptyText="Select all"
            onClick={() => handleSelect(allLocationIds[0], [...allLocationIds])}
          />
        )}

        {filteredLocations.map((location) => {
          if (showSublocations) {
            const { element, sublocationIds } = createTree(location, 0);
            if (location?.locationId) {
              allLocationIds.push(location.locationId);
            }
            allLocationIds.push(...sublocationIds);
            return <>{element}</>;
          }

          return (
            <>
              <LocationCard data-testid="locationDialogOption" location={location} onClick={handleSelect} />
            </>
          );
        })}
      </div>
    </Dialog>
  );
}

type LocationsDialogSelectProps = {
  label?: string;
  entityName?: string;
  required?: boolean;
  loading?: boolean;
  unavailableLocationIds?: string[];
  availableLocationIds?: string[] | null;
  selectedLocationIdLoading?: boolean;
  includeLocationsNotOnUser?: boolean;
  emptySelectionText?: string;
  selectedLocation?: string | string[] | null;
  organizationId?: string | string[] | null;
  changeLocation: (...args: any[]) => any;
  fullWidth?: boolean;
  readOnly?: boolean;
  includeEmptySelectOption?: boolean;
  id: string;
  testId?: string;
  labelHelper?: string;
  multiselect?: boolean;
};

export default function LocationsDialogSelect({
  label = 'Location name',
  entityName = '',
  required = false,
  loading: isLoading = false,
  includeLocationsNotOnUser = false,
  unavailableLocationIds = [],
  availableLocationIds = [],
  selectedLocationIdLoading = false,
  emptySelectionText = 'Any',
  selectedLocation = null,
  organizationId = '',
  changeLocation,
  fullWidth = false,
  readOnly = false,
  includeEmptySelectOption = true,
  id = '',
  testId = 'selectALocation',
  labelHelper = '',
  multiselect,
}: LocationsDialogSelectProps) {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const locationHtmlId = id || `locationId${createRandomString(5)}`;
  let organizationIdForQuery = organizationId || [];
  if (Array.isArray(organizationIdForQuery) && organizationIdForQuery.length < 1) {
    organizationIdForQuery = auth?.organizationId || '';
  }

  const { loading, locations: unfilteredLocations } = useLocations(organizationIdForQuery, {
    excludeSublocations: true,
    includeLocationsNotOnUser,
  });
  let locations = unfilteredLocations.filter((location) => !unavailableLocationIds.includes(location?.metadata?.locationId || ''));

  if (Array.isArray(availableLocationIds) && availableLocationIds.length) {
    locations = locations.filter((location) => availableLocationIds.includes(location?.metadata?.locationId || ''));
  }

  const doNotRenderWithNoLocations = !locations || locations.length === 0;

  const selectedLocationName = useMemo(() => {
    const locationSearch = (locations: Maybe<Location>[]) => {
      if (!locations?.length) return;
      const selectedLocationNames: string[] = [];
      for (const location of locations) {
        const { locationId, locationDisplayName } = location?.metadata || {};
        if (locationId && (locationId === selectedLocation || (Array.isArray(selectedLocation) && selectedLocation.includes(locationId)))) {
          selectedLocationNames.push(locationDisplayName || '');
        }

        const sublocations = location?.metadata?.sublocations;

        if (sublocations) {
          const result = locationSearch(sublocations);
          if (result) {
            selectedLocationNames.push(...result);
          }
        }
      }

      return selectedLocationNames;
    };

    const locationNames = locationSearch(locations);
    return locationNames && locationNames.length > 0 ? locationNames.join(', ') : emptySelectionText;
  }, [locations, selectedLocation, emptySelectionText]);

  const handleSelect = (...args) => {
    changeLocation(...args);
    setOpen(false);
  };

  return (
    <>
      <FormGroup fullWidth={fullWidth} data-testid="locationSelection" className="relative">
        <Label label={label} id={locationHtmlId} required={required} helper={labelHelper} />
        {selectedLocationIdLoading || loading || isLoading ? (
          <InputLoader fullWidth />
        ) : doNotRenderWithNoLocations ? (
          <p data-testid="locationsSelectNoLocations">No locations to choose from.</p>
        ) : (
          <button
            className="w-full rounded border border-gray-300 bg-white p-2 text-left text-gray-900 hover:border-gray-400"
            type="button"
            id={locationHtmlId}
            data-testid={testId}
            onClick={() => (readOnly ? null : setOpen(true))}
          >
            {selectedLocationName}
          </button>
        )}
        {required && <ReactSelectRequiredInput isValid={selectedLocation} />}
      </FormGroup>

      <LocationDialogBox
        setOpen={setOpen}
        locations={locations}
        selectedLocation={selectedLocation || []}
        includeEmptySelectOption={includeEmptySelectOption}
        emptySelectionText={emptySelectionText}
        open={open}
        handleSelect={handleSelect}
        label={entityName || label}
        multiselect={multiselect}
      />
    </>
  );
}

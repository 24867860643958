import React, { useEffect, useState } from 'react';
import { EntityLabelEnum, Maybe, TagMetadata, TagMetadataPatchInput, patchUser, queryUsers } from '@equips/entities-schema';
import { CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import { Favorite } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import ClientTag from '../Tags/ClientTag';
import IconButton from '../Buttons/IconButton';
import { useAuth } from '../../auth/AuthContext';
import { getAasOrganizationId } from '../../functions/aasHelpers';
import { getTagsForSelect } from '../../../graphql/queries/tagGraphQLQueries';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type Nullable<T> = T | null | undefined;

interface UserTagsSelectProps {
  onChange: (value: TagMetadataPatchInput[]) => void;
  onDefaultTagChange?: (tagId: string | null) => void;
  organizationId: string;
  loading?: boolean;
  setDefaultTagUserEdit?: (tagId: string | null) => void;
  [key: string]: any;
  userId?: string;
  bulkEdit?: boolean;
  inlineEdit?: boolean;
  userEdit?: boolean;
  saving?: boolean;
}

const UserTagsSelect = ({
  onChange,
  onDefaultTagChange,
  organizationId,
  loading = false,
  setDefaultTagUserEdit,
  bulkEdit = false,
  inlineEdit = false,
  userEdit = false,
  userId,
  saving = false,
  ...restOfProps
}: UserTagsSelectProps) => {
  const [{ clientTags, allowedClientTags, defaultTagId }, setTagData] = useState({
    clientTags: [] as Maybe<TagMetadata>[],
    allowedClientTags: [] as Maybe<TagMetadata>[],
    defaultTagId: '' as Maybe<string>,
  });

  const [state, setState] = useState(clientTags || []);
  const { isAasUser } = useAuth();
  const organizationIdForQuery = isAasUser ? getAasOrganizationId() : organizationId;

  const { data: queryForAllowedTags } = useQuery(
    ['getTagsForSelect', organizationIdForQuery],
    () =>
      getTagsForSelect({
        search: '',
        entityLabel: EntityLabelEnum.User,
        organizationId: organizationIdForQuery,
      }),
    {
      enabled: !!organizationIdForQuery,
      select: (data) => {
        const options = data?.data?.tag?.data || [];
        return options;
      },
    },
  );

  const userGraphQLQuery = `
  metadata {
    organization {
      metadata {
        allowedClientTags {
          tagId
          entityLabel
          name
          organizationId
          style
        }
      }
    }
    defaultTagId
    clientTags {
      tagId
      name
      style
    }
  }
`;

  const { refetch } = useQuery(
    ['queryUsersForTagsSelect', userId],
    () =>
      queryUsers(
        { userId },
        {
          query: userGraphQLQuery,
        },
      ),
    {
      enabled: !!userId,
      onSuccess: (data) => {
        const metadata = data?.data?.users?.data?.[0]?.metadata || {};
        setTagData({
          ...metadata,
          clientTags: metadata?.clientTags || [],
          defaultTagId: metadata?.defaultTagId || '',
          allowedClientTags: metadata?.organization?.metadata?.allowedClientTags || [],
        });
      },
    },
  );

  useEffect(() => {
    if (clientTags?.length) setState(clientTags);
  }, [clientTags]);

  // If user only has 1 tag, assign it as the main tag
  useEffect(() => {
    if (saving && state?.length === 1) {
      setTagData?.((tagState) => ({
        ...tagState,
        defaultTagId: state?.[0]?.tagId || null,
      }));
      userEdit && setDefaultTagUserEdit?.(state?.[0]?.tagId || null);
    }
  }, [saving, state]);

  const reconcileTags = async () => {
    const newTag = allowedClientTags.filter((tag) => tag?.entityLabel === EntityLabelEnum.User).find((tag) => tag?.tagId === defaultTagId);
    newTag &&
      (await patchUser({
        userId: userId || '',
        metadata: { clientTags: [...clientTags, { tagId: newTag?.tagId }] as Nullable<TagMetadataPatchInput[]> },
      }));
    refetch();
  };

  useEffect(() => {
    reconcileTags();
  }, [allowedClientTags]);

  return (
    <>
      {!bulkEdit && (
        <>
          <div className="flex-column">
            <h2 className="py-1 text-sm font-semibold text-gray-700">User Tags</h2>
            <p className="font-semi-bold text-xs">
              Choose a main tag to be shown when a user posts a note by clicking the heart icon next to a tag
            </p>
          </div>
        </>
      )}
      <div className="flex-column w-full">
        {!bulkEdit && <h3 className="py-1 text-sm font-semibold text-gray-700">Tags</h3>}
        <Autocomplete
          className={bulkEdit ? 'px-1 pt-3' : ''}
          disabled={loading}
          size="small"
          fullWidth
          disableClearable
          multiple
          id={'user-tags-autocomplete'}
          options={
            bulkEdit
              ? queryForAllowedTags?.map((tag) => ({
                  tagId: tag?.metadata?.tagId,
                  name: tag?.metadata?.name,
                  style: tag?.metadata?.style,
                })) || []
              : allowedClientTags.filter((tag) => tag?.entityLabel === EntityLabelEnum.User) || []
          }
          value={state}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.name as string}
          isOptionEqualToValue={(option, value) => option?.tagId === value?.tagId}
          onChange={(_, value, reason, option) => {
            if (reason) {
              setState(value);
              onChange?.(value.map((val) => ({ tagId: val?.tagId || '', name: val?.name || '', style: val?.style })));
            }
            if (reason === 'removeOption') {
              const isMainTag = option?.option?.tagId === defaultTagId;
              userEdit && isMainTag && setDefaultTagUserEdit?.(null);
              inlineEdit && isMainTag && onDefaultTagChange?.(null);
              isMainTag &&
                setTagData?.((state) => ({
                  ...state,
                  defaultTagId: null,
                }));
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <ClientTag
                {...getTagProps({ index })}
                key={index}
                label={
                  <div className="flex items-center">
                    {option?.name}
                    {option?.tagId === defaultTagId && (
                      <IconButton
                        className="bg-transparent font-light hover:text-red-700"
                        style={{ color: 'red' }}
                        text="Remove main tag"
                        size="xs"
                        Icon={Favorite}
                        userEdit
                        onClick={() => {
                          setTagData?.((state) => ({
                            ...state,
                            defaultTagId: null,
                          }));
                          userEdit && setDefaultTagUserEdit?.(null);
                          inlineEdit && onDefaultTagChange?.(null);
                        }}
                      />
                    )}
                  </div>
                }
                style={option?.style}
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} className="flex items-center">
              <Checkbox
                disabled={loading}
                size="small"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ color: option?.style && JSON.parse(option?.style)?.backgroundColor }}
                checked={selected}
              />
              <p className="mr-auto text-sm">{option?.name}</p>
              {!bulkEdit && (
                <IconButton
                  className="mr-8 bg-transparent font-light"
                  text="Set or remove main tag"
                  size="xs"
                  style={option?.tagId === defaultTagId ? { color: 'red' } : { color: 'gray' }}
                  Icon={Favorite}
                  onClick={(e) => {
                    e.stopPropagation();
                    const foundTag = state.find((tag) => tag?.tagId === option?.tagId);
                    // Allow users to click the heart to apply the tag
                    if (!foundTag) {
                      const value = [...state, { tagId: option?.tagId || '', name: option?.name || '', style: option?.style }];
                      setState(value);
                      onChange?.(value.map((val) => ({ tagId: val?.tagId || '', name: val?.name || '', style: val?.style })));
                      setTagData?.((tagState) => ({
                        ...tagState,
                        defaultTagId: option?.tagId || null,
                      }));
                      userEdit && setDefaultTagUserEdit?.(option?.tagId || null);
                      inlineEdit && onDefaultTagChange?.(option?.tagId || null);
                    } else {
                      const mainTagId = foundTag?.tagId !== defaultTagId ? option?.tagId : null;
                      setTagData?.((tagState) => ({
                        ...tagState,
                        defaultTagId: mainTagId,
                      }));
                      userEdit && setDefaultTagUserEdit?.(mainTagId || null);
                      inlineEdit && onDefaultTagChange?.(mainTagId || null);
                    }
                  }}
                />
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              size={restOfProps?.size || 'small'}
              placeholder="Add tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="secondary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...restOfProps}
        />
      </div>
    </>
  );
};

export default UserTagsSelect;

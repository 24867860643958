import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Maybe, Location, patchLocation } from '@equips/entities-schema';
import Close from '@mui/icons-material/Close';
import Store from '@mui/icons-material/Store';
import { formatAddressAsOneLine } from '@equips/common-resources';
import IconButton from '@mui/material/IconButton';
import { LocationDialogBox } from '../../../common/components/Selects/LocationsDialogSelect';
import Button from '../../../common/components/Buttons/Button';
import Urls from '../../../routes/Urls';
import { AlertTypes, useAlert } from '../../../common/components/Alerts/AlertContext';
import { getLocationsByOrganizationId } from '../../../graphql/queries/locationGraphQLQueries';

function SublocationCard(props: { location: Maybe<Location>; handleRemove: (...args: any[]) => any }) {
  if (!props.location) return null;

  return (
    <button
      type="button"
      onClick={() => {
        window.open(`${Urls.LOCATIONS}/${props.location?.metadata?.locationId}`, '_blank');
      }}
      className="my-4 flex w-full items-center justify-between rounded-sm bg-gray-200 px-4 py-2"
    >
      <div className="flex items-center">
        <Store className="mr-3 text-gray-600" />
        <div className="text-left">
          <span>{props.location?.metadata?.locationName}</span>
          <div className="text-xs">{formatAddressAsOneLine(props.location?.address)}</div>
        </div>
      </div>
      <IconButton
        title="Remove Parent Location"
        onClick={(e) => {
          e.stopPropagation();
          props.handleRemove(props.location?.metadata?.locationId);
        }}
        size="large"
      >
        <Close />
      </IconButton>
    </button>
  );
}

export default function SublocationsDisplay(props: {
  /** Current location data*/
  location: Maybe<Location>;
  organizationId: Maybe<string>;
  refetchLocation: any;
}) {
  const showAlert = useAlert();

  const [openParentLocationSelect, setOpenParentLocationSelect] = useState(false);
  const [openSublocationSelect, setOpenSublocationSelect] = useState(false);

  const organizationId = props?.organizationId ?? '';

  const { data } = useQuery(
    ['useLocationsLocations', organizationId],
    () => getLocationsByOrganizationId({ organizationId, excludeSublocations: false }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const locations = data?.locations?.data ?? [];

  // cant assign itself as parent or sublocation
  // cant assign sibling as parent or sublocation
  // cant assign current parent as parent or sublocation

  const filteredLocations = locations.filter(
    (location) =>
      location?.metadata?.locationId !== props?.location?.locationId &&
      (props?.location?.metadata?.parentLocationId
        ? location?.metadata?.parentLocationId !== props?.location?.metadata?.parentLocationId &&
          location?.metadata?.locationId !== props?.location?.metadata?.parentLocationId
        : true),
  );

  const handleParentLocationSelect = async (id) => {
    try {
      await patchLocation({ locationId: props?.location?.locationId ?? '', metadata: { parentLocationId: id ?? '' }, address: {} });
    } catch (error) {
      return showAlert({ content: 'Unable to assign selected location as a parent location', type: AlertTypes.error });
    }
    showAlert({ content: 'Successfully assigned parent location' });
    setOpenParentLocationSelect(false);
    props.refetchLocation();
  };

  const handleRemoveParentLocation = async () => {
    try {
      await patchLocation({ locationId: props?.location?.locationId ?? '', metadata: { parentLocationId: null }, address: {} });
    } catch (error) {
      return showAlert({ content: 'Unable to remove parent location', type: AlertTypes.error });
    }
    showAlert({ content: 'Successfully removed parent location' });
    props.refetchLocation();
  };

  const handleSublocationSelect = async (id) => {
    try {
      await patchLocation({ locationId: id, metadata: { parentLocationId: props.location?.locationId }, address: {} });
    } catch (error) {
      return showAlert({ content: 'Unable to assign selected location as a sublocation', type: AlertTypes.error });
    }
    showAlert({ content: 'Successfully assigned sublocation' });
    setOpenSublocationSelect(false);
    props.refetchLocation();
  };

  const handleRemoveSublocation = async (id) => {
    try {
      await patchLocation({ locationId: id, metadata: { parentLocationId: null }, address: {} });
    } catch (error) {
      return showAlert({ content: 'Unable to remove sublocation', type: AlertTypes.error });
    }
    showAlert({ content: 'Successfully removed sublocation' });
    props.refetchLocation();
  };

  const parentLocation = props?.location?.metadata?.parentLocation;
  const sublocations = props?.location?.metadata?.sublocations;

  return (
    <>
      <div className="space-y-6 border-y border-gray-300 py-8">
        <div>
          <div className="flex flex-wrap items-center justify-between">
            <span className="font-semibold text-gray-700">Parent Location</span>
            <Button
              type="button"
              className="mt-2 block"
              tiny
              blue
              onClick={() => {
                setOpenParentLocationSelect(true);
              }}
            >
              Assign parent location
            </Button>
          </div>
          <SublocationCard location={parentLocation} handleRemove={handleRemoveParentLocation} />
        </div>
        <div>
          <div className="flex flex-wrap items-center justify-between">
            <span className="font-semibold text-gray-700">Sublocations</span>
            <Button
              type="button"
              className="mt-2 block"
              tiny
              blue
              onClick={() => {
                setOpenSublocationSelect(true);
              }}
            >
              Assign sublocations
            </Button>
          </div>
          {sublocations?.map((location) => (
            <SublocationCard key={location?.metadata?.locationId} location={location} handleRemove={handleRemoveSublocation} />
          ))}
        </div>
      </div>
      {openParentLocationSelect && (
        <LocationDialogBox
          open={openParentLocationSelect}
          setOpen={setOpenParentLocationSelect}
          handleSelect={handleParentLocationSelect}
          label="Parent Location"
          showSublocations={false}
          locations={filteredLocations}
          emptySelectionText="None"
        />
      )}
      {openSublocationSelect && (
        <LocationDialogBox
          open={openSublocationSelect}
          setOpen={setOpenSublocationSelect}
          handleSelect={handleSublocationSelect}
          label="Sublocation"
          showSublocations={false}
          locations={filteredLocations}
          emptySelectionText="None"
          multiselect
        />
      )}
    </>
  );
}

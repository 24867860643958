import CryptoJS from 'crypto-js';

export function decryptData({ encryptionKey, data }: { encryptionKey: string; data: string }): string {
  const split = data.indexOf(':');
  const iv = CryptoJS.enc.Hex.parse(data.slice(0, split));
  const ciphertext = data.slice(split + 1);
  const decryptedWA = CryptoJS.AES.decrypt(ciphertext, encryptionKey, { iv: iv });

  return decryptedWA.toString(CryptoJS.enc.Utf8);
}

import React, { lazy, Suspense, useEffect } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ClientOrganizationEnumType } from '@equips/entities-schema';
import NavBar from './common/components/Menu/NavBar';
import MainRouter from './routes/MainRouter';
import MainAlert from './common/components/Alerts/MainAlert';
import MainErrorBoundary from './common/components/ErrorBoundary/MainErrorBoundary';
import useLogin from './common/hooks/useAuthAndNetwork';
import MainLoader from './common/components/Loaders/Main';
import CachedAppDetector from './common/components/CachedAppDetector';
import { AuthorizationData, AuthProvider } from './common/auth/AuthContext';
import ModalProvider from './common/providers/ModalProvider';
import config from './config';
import { theme } from './styles/materialTheme';
import NavDataProvider from './common/providers/NavDataProvider';

const ErrorScreen = lazy(() => import('./screens/error-screen/ErrorScreen'));

export default function App({ fakeAuth }: { fakeAuth?: AuthorizationData }) {
  const { authorizationData, isAuthenticating, AuthWarningModals } = useLogin(fakeAuth);
  const serverError = authorizationData?.serverError;

  useEffect(() => {
    if (config?.isProd) console.log('EQUIPS', new Date(__buildDate__).toDateString(), __buildVersion__);
    if (config?.isProd) {
      LogRocket.init('9srnkm/equips-webapp', { release: __buildVersion__ });
      LogRocket.identify(authorizationData?.userId || '', {
        email: authorizationData?.email || '',
        name: authorizationData?.fullName || '',
        role: authorizationData?.authorizationRole || '',
      });
      setupLogRocketReact(LogRocket);
    }
    if (config?.isProd) {
      if (
        authorizationData &&
        authorizationData.userId &&
        authorizationData.email &&
        authorizationData.fullName &&
        authorizationData.authorizationRole &&
        authorizationData.organizationId &&
        authorizationData?.organization?.metadata?.organizationName &&
        authorizationData.organization?.metadata?.clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement
      ) {
        window.pendo.initialize({
          visitor: {
            id: authorizationData.userId,
            email: authorizationData.email,
            full_name: authorizationData.fullName,
            role: authorizationData.authorizationRole,

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: authorizationData.organizationId,
            name: authorizationData.organization.metadata.organizationName,

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            client_organization_type: authorizationData?.organization?.metadata?.clientOrganizationType,
          },
        });
      }
    }
  }, [authorizationData]);

  return (
    <Suspense fallback={<MainLoader />}>
      {serverError ? (
        <ErrorScreen title="Sorry, there seems to be an issue right now. Our engineering team has been notified." />
      ) : isAuthenticating ? (
        <MainLoader />
      ) : (
        <MainAlert>
          <AuthProvider auth={authorizationData}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ModalProvider>
                      <NavDataProvider userId={authorizationData?.userId}>
                        <NavBar>
                          <Suspense fallback={<div className="min-h-screen" />}>
                            <MainErrorBoundary>
                              <main>
                                <AuthWarningModals />
                                <MainRouter />
                                <CachedAppDetector />
                              </main>
                            </MainErrorBoundary>
                          </Suspense>
                        </NavBar>
                      </NavDataProvider>
                    </ModalProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </QueryParamProvider>
          </AuthProvider>
        </MainAlert>
      )}
    </Suspense>
  );
}

import React, { useState } from 'react';
import { Equipment } from '@equips/entities-schema';
import Button from '../Buttons/Button';
import ImageDisplay from '../Images/ImageDisplay';

type SubmitRequestPinnedInfoProps = {
  onCancel: () => void;
  onContinue: () => void;
  equipment?: Equipment[];
};

export function SubmitRequestPinnedInfo({ equipment, onCancel, onContinue }: SubmitRequestPinnedInfoProps) {
  const filteredEquipment = equipment?.filter((eq) => eq.metadata?.pinnedNote || eq.metadata?.pinnedImageId);
  const [selected, setSelected] = useState(filteredEquipment?.[0]);
  return (
    <div className="p-4">
      {filteredEquipment && filteredEquipment.length > 1 && (
        <div className="mb-2 rounded-lg border bg-gray-100 text-xs text-gray-700">
          <div className="m-2 flex gap-2 overflow-x-auto whitespace-nowrap">
            {filteredEquipment.map((e) => (
              <Button key={e?.equipmentId} blue outlined={selected !== e} tiny onClick={() => setSelected(e)}>
                {e?.metadata?.shortId}
              </Button>
            ))}
          </div>
        </div>
      )}
      <p>This equipment has pinned information, please review before continuing the service request.</p>
      {selected?.metadata?.pinnedNote && (
        <div className="py-4">
          <div className="font-semibold text-gray-700">Pinned Note</div>
          <div className="border-y border-gray-300 p-4" dangerouslySetInnerHTML={{ __html: selected?.metadata?.pinnedNote }}></div>
        </div>
      )}
      {selected?.metadata?.pinnedImageId && (
        <div className="pb-8 pt-2">
          <div className="font-semibold text-gray-700">Pinned Image</div>
          <ImageDisplay attachmentId={selected?.metadata?.pinnedImageId || undefined} showPlaceholder />
        </div>
      )}
      <div className="flex justify-between">
        <Button outlined type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button blue data-testid="pinnedNoteContinueButton" type="button" onClick={onContinue}>
          Continue creating service request
        </Button>
      </div>
    </div>
  );
}

import {
  queryLocations,
  ElasticsearchSearchTypesEnum,
  SortByElasticSearchOptions,
  LocationsQueryLocationsArgs,
  addressFields,
  Maybe,
  Location,
  NotesSearchInput,
} from '@equips/entities-schema';
import { gql } from '../graphqlHelperFunctions';
import { postToLocationsEndpoint as untypedPostToLocationsEndpoint } from '../postToEndpoint';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { NoteListFields, NoteListFragment } from '../../common/components/ViewPage/NoteListFragment';
import { TableFetch } from '../../common/components/DataTable';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { defaultColumns } from './../../common/components/Table/defaultColumns';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from './../../common/components/Date/DateRangeFilter';
import { categoryGraphQlNode } from './categoryQueries';

const postToLocationsEndpoint = async ({ query, variables }: { query: string; variables: LocationsQueryLocationsArgs }) => {
  return untypedPostToLocationsEndpoint({ query, variables });
};

export const getDeactivatedAtByLocationId = async ({ locationId }) => queryLocations({ locationId });

export const getLocationNameById = async ({ locationId }) => queryLocations({ locationId });

export const getEquipmentMatrixReport = async ({ organizationId }) =>
  postToLocationsEndpoint({
    query: gql`
      query ($search: LocationsSearchInput) {
        locations(search: $search) {
          data {
            metadata {
              locationName
              locationId
            }
            equipment {
              metadata {
                equipmentId
                spec {
                  metadata {
                    ${categoryGraphQlNode}
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 500,
        filters: [
          {
            metadata: { organizationId: [{ keyword: [{ term: organizationId }] }] },
          },
        ],
      },
    },
  });

const locationFields = `
  locationId
  metadata {
    locationName
    locationId
    autogenerated
    createdAt
    createdByUserId
    deactivatedAt
    deactivatedByUserId
    legacyLocationId
    locationDisplayName
    mainContactUserId
    mainContactUser {
        metadata {
          fullName
          phoneNumberWithCountryCode
          email
        }
    }
    modifiedAt
    modifiedByUserId
    organizationId
    parentLocationId
    shortId
    timezone
  }
  address {
    ${addressFields}
  }`;

const locationFieldsWithActiveEquipmentCount = `
  ${locationFields}
  activeEquipmentCount
`;

export const getLocationsByOrganizationId = async ({
  organizationId,
  excludeSublocations,
  includeActiveEquipmentCount = false,
}: {
  organizationId?: string | string[];
  excludeSublocations?: boolean;
  includeActiveEquipmentCount?: boolean;
}) => {
  const defaultLocationFields = !includeActiveEquipmentCount ? locationFields : locationFieldsWithActiveEquipmentCount;

  return queryLocations(
    {
      search: {
        size: 1000,
        sortBy: [
          { metadata: { shortId: { keyword: SortByElasticSearchOptions.Asc } } },
          { metadata: { locationName: { keyword: SortByElasticSearchOptions.Asc } } },
        ],
        filters: [
          {
            metadata: {
              ...(organizationId
                ? {
                    organizationId: [{ keyword: Array.isArray(organizationId) ? [{ terms: organizationId }] : [{ term: organizationId }] }],
                  }
                : {}),
              ...(excludeSublocations
                ? {
                    parentLocationId: [{ keyword: [{ notExists: true }] }],
                  }
                : {}),
            },
          },
        ],
      },
    },
    {
      query: `
          ${defaultLocationFields}
          metadata {
            sublocations {
              ${defaultLocationFields}
              metadata {
                sublocations {
                  ${defaultLocationFields}
                }
              }
            }
          }
        `,
    },
  );
};

export const getLocationsForGroupViewByOrganizationId = async ({ organizationId }) =>
  queryLocations(
    {
      search: {
        includeTotalCount: true,
        sortBy: [
          { metadata: { shortId: { keyword: SortByElasticSearchOptions.Asc } } },
          { metadata: { locationName: { keyword: SortByElasticSearchOptions.Asc } } },
        ],
        filters: [
          {
            metadata: {
              ...(organizationId
                ? {
                    organizationId: [{ keyword: Array.isArray(organizationId) ? [{ terms: organizationId }] : [{ term: organizationId }] }],
                  }
                : {}),
            },
          },
        ],
      },
    },
    {
      query: `
          metadata {
            locationName
            locationId
            parentLocationId
            sublocations{
              locationId
            }
          }
          address {
            ${addressFields}
          }
        `,
    },
  );

export const getLocationsForTable: TableFetch<Maybe<Location>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }

  const { data } = await queryLocations(
    {
      search: {
        q: globalFilter,
        size: pageSize,
        from: pageSize * pageIndex,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        sortBy: transformTableSortToGraphQLSort(sortBy),
        includeTotalCount,
        filters: [
          {
            metadata: {
              ...(filterMap['metadata.organizationId'] && filterMap['metadata.organizationId'].length > 0
                ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
                : {}),
              ...(filterMap['parentLocation'] ? { parentLocationId: [{ keyword: [{ terms: filterMap['parentLocation'] }] }] } : {}),
              ...(filterMap['isSublocation'] ? { parentLocationId: [{ keyword: [{ exists: true }] }] } : {}),
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
            },
            organizationMetadata: {
              ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
            },
            address: {
              ...(filterMap['address.state'] ? { stateUnabbreviated: [{ keyword: [{ term: filterMap['address.state'] }] }] } : {}),
            },
            tagMetadata: {
              tagId: !filterMap['tagMetadata.tagId']?.includes('none')
                ? toTerms<string>(filterMap['tagMetadata.tagId'])
                : [{ keyword: [{ exists: false }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        locationName
        locationId
        shortId
        contacts {
          fullName
          phone
          email
        }
        ${defaultColumns.createdColumnQueryString}
        ${defaultColumns.modifiedColumnQueryString}
        clientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
        deactivatedAt
        parentLocation{
          metadata{
            locationId
            locationDisplayName
          }
        }
        sublocations{
          metadata{
            locationId
            locationDisplayName
          }
        }
      }
      organizationMetadata {
        organizationId
        organizationName
      }
      address {
        ${addressFields}
      }
      mailingAddress{
        ${addressFields}
      }
    `,
    },
  );

  return { data: data?.locations?.data ?? [], totalCount: data?.locations?.totalCount };
};

export const getLocationsByMetadataField = async ({ value, field, showActivatedAndDeactivated }) =>
  queryLocations({
    search: {
      size: 200,
      from: 0,
      filters: [
        {
          metadata: {
            ...(showActivatedAndDeactivated ? { deactivatedAt: [{ all: true }] } : {}),
            [field]: { keyword: [{ term: value }] },
          },
        },
      ],
    },
  });

export const getLocationByLocationId = async ({ locationId }) =>
  queryLocations(
    { locationId },
    {
      query: `
    locationId
    metadata {
      homeMainContractNo
      locationName
      locationId
      imageId
      autogenerated
      createdAt
      createdByUser {
        metadata {
          fullName
        }
      }
      deactivatedAt
      deactivatedByUserId
      legacyLocationId
      locationDisplayName
      modifiedAt
      modifiedByUser {
        metadata {
          fullName
        }
      }
      organizationId
      organization {
        metadata {
          urlDomain
          allowedClientTags {
            tagId
            entityLabel
            name
            organizationId
            style
          }
        }
      }
      parentLocationId
      shortId
      timezone
      mainContactUserId
      mainContactUser {
        metadata {
          fullName
          phoneNumberWithCountryCode
          email
        }
      }
      contacts {
        fullName
        email
        phone
      }
      clientTags {
        tagId
        entityLabel
        name
        style
        organizationId
      }
      parentLocation{
        metadata {
          locationName
          locationId
        }
        address {
          ${addressFields}
        }
      }
      sublocations{
        locationId
        metadata {
          locationName
          locationId
          autogenerated
          createdAt
          createdByUserId
          deactivatedAt
          deactivatedByUserId
          legacyLocationId
          locationDisplayName
          modifiedAt
          modifiedByUserId
          organizationId
          parentLocationId
          shortId
          timezone
        }
        address {
          ${addressFields}
        }
      }
    }
    address {
      ${addressFields}
    }
    mailingAddress{
      ${addressFields}
    }
    openServiceRequestCount
    activeEquipmentCount
  `,
    },
  );

export const getLocationsWithEquipmentByOrganizationId = async ({ organizationId }) =>
  postToLocationsEndpoint({
    query: gql`
      query ($search: LocationsSearchInput) {
        locations(search: $search) {
          data {
            metadata {
              locationId
              locationDisplayName
              shortId
              organizationId
            }
            address {
              line1
              line2
              city
              stateUnabbreviated
              zip
            }
            equipment {
              metadata {
                equipmentId
                shortId
                subclasses
                serialNumber
                provider {
                  metadata {
                    organizationName
                  }
                  provider {
                    dispatchPhone
                  }
                }
              }
              coveragesMetadata {
                conditions
                contractId
                onDate
                offDate
                coverageStatus
              }
              specMetadata {
                ${categoryGraphQlNode}
                manufacturer
                model
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 1000,
        sortBy: [{ metadata: { locationName: { keyword: SortByElasticSearchOptions.Asc } } }],
        filters: [
          {
            metadata: {
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
            },
          },
        ],
      },
    },
  });

export const getLocationsClientToProviderDispatchNote = async ({ entityId }) =>
  postToLocationsEndpoint({
    query: gql`
      query ($locationId: String!) {
        locations(locationId: $locationId) {
          data {
            clientToProviderDispatchNote {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    variables: { locationId: entityId },
  });

export const getLocationNotes = async ({ entityId }: { entityId: string; noteSearch?: NotesSearchInput }) => {
  const data = await queryLocations(
    { locationId: entityId },
    {
      query: `notes { ${NoteListFields} }`,
    },
  );
  return data.data?.locations?.data?.[0]?.notes ?? [];
};

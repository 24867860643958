import React, { lazy, useState } from 'react';
import { StringParam, NumberParam, JsonParam, BooleanParam } from 'use-query-params';
import { UseFiltersInstanceProps } from 'react-table';
import { EntityLabelEnum, OrganizationTagsEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import FormGroup from '../Form/FormGroup';
import StateSelector from '../Selects/StateSelector';
import CountrySelector from '../Selects/CountrySelector';
import StyledSelect from '../Selects/StyledSelect';
import Input from '../Form/Input';
import CurrencyRangeFilter from '../Form/CurrencyRangeFilter';
import ContractSelect from '../Selects/ContractSelect';
import TagInput from '../Form/TagInput';
import LocationsDialogSelect from '../Selects/LocationsDialogSelect';
import CurrencyInput from '../Form/CurrencyInput';
import { EquipmentSearchableSelection } from '../ServiceRequest/EquipmentSearchableSelection';
import { clientValueForFilter, providerValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import MultiSelect from '../Selects/MultiSelect';
import Label from '../Form/Label';
import RecurrenceIntervalSelect from '../Selects/RecurrenceIntervalSelect';
import CategorySelect from '../Selects/CategorySelect';
import RecurrenceOptionSelect from '../Selects/RecurrenceOptionSelect';
import { getAasFailCodes } from '../../../graphql/queries/aasFailCodeQueries';
import Switch from '../Switch/Switch';
import { TableColumnInstance } from './types';

const DateRangeFilter = lazy(() => import('../Date/DateRangeFilter'));
const SelectDateRangeFromAgreements = lazy(() => import('../Date/SelectDateRangeFromAgreements'));
const OrganizationsSelect = lazy(() => import('../Selects/OrganizationsSelect'));
const OrganizationMultiSelect = lazy(() => import('../Selects/OrganizationMultiSelect'));
const ClientTagsMultiSelect = lazy(() => import('../Selects/ClientTagsMultiSelect'));
const UserFilter = lazy(() => import('../Table/UserFilter'));
const SubcategoryFilter = lazy(() => import('../Table/SubcategoryFilter'));
const AasFailureCodesAutocomplete = lazy(() => import('../../../screens/aas/components/AasFailureCodesAutocomplete'));

export enum TableFilterTypes {
  aasFailCodesMultiSelect = 'aasFailCodesMultiSelect',
  clientTagsMultiSelect = 'clientTagsMultiSelect',
  currencyInput = 'currencyInput',
  currencyRange = 'currencyRange',
  dateRange = 'dateRange',
  biDirectionalDateRange = 'biDirectionalDateRange',
  dateRangeOrContractSelect = 'dateRangeOrContractSelect',
  organizationSelect = 'organizationSelect',
  organizationMultiSelect = 'organizationMultiSelect',
  locationSelect = 'locationSelect',
  equipmentSearchable = 'equipmentSearchable',
  categorySelect = 'categorySelect',
  subcategorySelect = 'subcategorySelect',
  agreementSelect = 'agreementSelect',
  userSelect = 'userSelect',
  stateSelect = 'stateSelect',
  countrySelect = 'countrySelect',
  multiSelect = 'multiSelect',
  select = 'select',
  tags = 'tags',
  text = 'text',
  /** Special type for choosing recurrence interval */
  recurrenceInterval = 'recurrenceInterval',
  recurrenceOption = 'recurrenceOption',
  switch = 'switch',
  /** Exposes the filter via the query string but does not display a filter input on the interface*/
  hidden = 'hidden',
}
export type TableFilterOptions = {
  type: TableFilterTypes;
  prependedOptions?: { label: string; value: string }[];
  emptySelectionText?: string;
  /** Used for select */
  options?: { label: string; value: string }[];
  hidden?: boolean;
  /** The default value for the given filter. Defaults to: "" */
  defaultValue?: any;
  filterType?: typeof StringParam | typeof BooleanParam | typeof NumberParam | typeof JsonParam;
  label?: string | React.ReactNode;
  labelHelper?: string;
  showFilterOptionWhen?: (filters: Record<string, any>) => boolean;
  typeOfOrganizationToFind?: 'ANY' | typeof clientValueForFilter | typeof providerValueForFilter | string;
  organizationIdLocation?: string;
  locationIdLocation?: string;
  equipmentClass?: string;
  includeEmptySelectOption?: boolean;
  buildOptionsFromActiveFilters?: (currentyActiveFilters: { [key: string]: any }) => { value: string; label: string }[];
  isSmall?: boolean;
  isSidebar?: boolean;
  /** Determine if the filter would appear on mobile */
  showOnMobile?: boolean;
  entityLabel?: EntityLabelEnum;
  allowNullNotNull?: boolean;
  organizationTags?: OrganizationTagsEnum[];
};

export type DisplayTableOptionByTypeProps = TableFilterOptions & {
  column: TableColumnInstance;
  filterMap: Record<string, any>;
} & Pick<UseFiltersInstanceProps<any>, 'setFilter'>;

export function DisplayTableOptionByType({
  column,
  filterMap,
  setFilter,
  type,
  prependedOptions,
  emptySelectionText,
  options,
  label,
  organizationIdLocation = 'metadata.organizationId',
  locationIdLocation = 'metadata.locationId',
  buildOptionsFromActiveFilters,
  isSidebar,
  entityLabel,
  ...restOfOptions
}: DisplayTableOptionByTypeProps) {
  let availableOptions = options || [];
  const [textInput, setTextInput] = useState(filterMap?.[column.id]);

  if (buildOptionsFromActiveFilters) {
    availableOptions = buildOptionsFromActiveFilters(filterMap);
  }

  const { data: aasFailCodeOptions } = useQuery(
    ['getAasFailCodes', type === TableFilterTypes.aasFailCodesMultiSelect],
    () => getAasFailCodes(),
    {
      enabled: type === TableFilterTypes.aasFailCodesMultiSelect,
      select: (data) => data?.data?.map((aasFailCode) => aasFailCode?.metadata),
    },
  );

  if (type === TableFilterTypes.aasFailCodesMultiSelect) {
    return (
      <FormGroup fullWidth>
        <Label id={column.id} label={label} helper={restOfOptions?.labelHelper} />
        <AasFailureCodesAutocomplete
          hideLabel
          options={aasFailCodeOptions ?? []}
          disabledDebounce={true}
          selected={filterMap?.[column.id]}
          onChange={(aasFailCodes) => {
            const selections = aasFailCodes.map((aasFailCode) => aasFailCode.aasFailCodeId);
            setFilter(column.id, (selections?.length || 0) > 0 ? selections : undefined);
          }}
        />
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.clientTagsMultiSelect) {
    return (
      <ClientTagsMultiSelect
        {...restOfOptions}
        fullWidth
        isClearable
        label={String(label)}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        organizationId={filterMap?.[organizationIdLocation || '']}
        entityLabel={entityLabel as EntityLabelEnum}
        value={filterMap?.[column.id]}
        prependedOptions={prependedOptions}
        setValue={(selections) => {
          setFilter(column.id, selections && selections.length > 0 ? selections.map((selection) => selection?.value) : undefined);
        }}
        isTableFilter
      />
    );
  }

  if (type === TableFilterTypes.currencyInput) {
    return (
      <CurrencyInput
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        cents={filterMap?.[column.id]}
        handleCents={(cents) => setFilter(column.id, cents || undefined)}
        label={String(label)}
        fullWidth
      />
    );
  }

  if (type === TableFilterTypes.currencyRange) {
    return (
      <CurrencyRangeFilter
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        key={column.id}
        label={label}
        value={filterMap?.[column.id]}
        setValue={(value) => setFilter(column.id, value || undefined)}
      />
    );
  }

  if (type === TableFilterTypes.categorySelect) {
    const value = Array.isArray(filterMap?.[column.id])
      ? filterMap?.[column.id]
      : filterMap?.[column.id]?.includes(',')
      ? filterMap?.[column.id]?.split(',')
      : filterMap?.[column.id];
    return (
      <CategorySelect
        {...restOfOptions}
        fullWidth
        key={column.id}
        data-testid={`tableFilter-${column.id}`}
        emptySelectionText={emptySelectionText || 'Any'}
        label={String(label)}
        onChange={(value, all) => setFilter(column.id, all || value || undefined)}
        selectedCategoryIds={value}
        multiselect
      />
    );
  }

  if (type === TableFilterTypes.subcategorySelect) {
    const value = Array.isArray(filterMap?.[column.id])
      ? filterMap?.[column.id]
      : filterMap?.[column.id]?.includes(',')
      ? filterMap?.[column.id]?.split(',')
      : filterMap?.[column.id];

    return (
      <SubcategoryFilter
        {...restOfOptions}
        fullWidth
        key={column.id}
        data-testid={`tableFilter-${column.id}`}
        emptySelectionText={emptySelectionText || 'Any'}
        label={String(label)}
        onChange={(value) => setFilter(column.id, value)}
        value={value}
        multiselect
        isClearable
      />
    );
  }

  if (type === TableFilterTypes.agreementSelect) {
    return (
      <ContractSelect
        {...restOfOptions}
        prependedOptions={prependedOptions}
        fullWidth
        key={column.id}
        emptySelectionText={emptySelectionText || 'Any'}
        label={String(label)}
        organizationId={filterMap?.[organizationIdLocation || '']}
        setContractId={(value) => setFilter(column.id, value || undefined)}
        contractId={filterMap?.[column.id]}
      />
    );
  }

  if (type === TableFilterTypes.userSelect) {
    return (
      <UserFilter
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={String(label)}
        value={filterMap?.[column.id]}
        setValue={(value) => setFilter(column.id, value || undefined)}
        emptySelectionText={emptySelectionText || 'Any'}
      />
    );
  }

  if (type === TableFilterTypes.locationSelect) {
    // We persist filters to the URL, so this could be a string representation of an array.
    const value = Array.isArray(filterMap?.[column.id])
      ? filterMap?.[column.id]
      : filterMap?.[column.id]?.includes(',')
      ? filterMap?.[column.id]?.split(',')
      : filterMap?.[column.id];
    return (
      <LocationsDialogSelect
        {...restOfOptions}
        fullWidth
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        emptySelectionText={emptySelectionText || 'Any'}
        label={String(label)}
        organizationId={filterMap?.[organizationIdLocation || '']}
        changeLocation={(value, all) => setFilter(column.id, all || value || undefined)}
        selectedLocation={value}
        multiselect
      />
    );
  }

  if (type === TableFilterTypes.organizationSelect) {
    return (
      // @ts-ignore Unknown reason. Please investigate me.
      <OrganizationsSelect
        {...restOfOptions}
        typeOfOrganizationToFind={restOfOptions?.typeOfOrganizationToFind}
        fullWidth
        isClearable
        label={String(label)}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        organizationId={filterMap?.[column.id]}
        changeOrganization={(selection) => {
          setFilter(column.id, selection?.value || undefined);
        }}
      />
    );
  }

  if (type === TableFilterTypes.organizationMultiSelect) {
    return (
      <OrganizationMultiSelect
        {...restOfOptions}
        typeOfOrganizationToFind={restOfOptions?.typeOfOrganizationToFind}
        fullWidth
        isClearable
        label={String(label)}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        value={filterMap?.[column.id]}
        setValue={(selections) => {
          // @ts-ignore Unknown reason. Please investigate me.
          setFilter(column.id, selections?.length > 0 ? selections : undefined);
        }}
      />
    );
  }

  if (type === TableFilterTypes.multiSelect) {
    return (
      <MultiSelect
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        testId={`${column.id}MultiSelect`}
        options={availableOptions}
        fullWidth
        label={String(label)}
        isClearable
        handleChange={(selections) => {
          setFilter(column.id, (selections?.length || 0) > 0 ? selections : undefined);
        }}
        selectedValues={filterMap?.[column.id]}
      />
    );
  }

  if (type === TableFilterTypes.equipmentSearchable) {
    return (
      <EquipmentSearchableSelection
        includeAnyText={restOfOptions.includeEmptySelectOption ? 'Any' : ''}
        equipmentId={filterMap?.[column.id]}
        organizationId={filterMap?.[organizationIdLocation]}
        locationId={filterMap?.[locationIdLocation]}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        handleSelect={(equipmentId) => setFilter(column.id, equipmentId || undefined)}
      />
    );
  }

  if (type === TableFilterTypes.dateRange) {
    return (
      <DateRangeFilter
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={label || ''}
        options={options}
        value={filterMap?.[column.id]}
        setValue={(value) => setFilter(column.id, value || undefined)}
      />
    );
  }

  if (type === TableFilterTypes.biDirectionalDateRange) {
    return (
      <DateRangeFilter
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={label || ''}
        options={options}
        value={filterMap?.[column.id]}
        setValue={(value) => setFilter(column.id, value || undefined)}
        biDirectionalMode={true}
      />
    );
  }

  if (type === TableFilterTypes.dateRangeOrContractSelect) {
    return (
      <SelectDateRangeFromAgreements
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={String(label) || ''}
        value={filterMap?.[column.id]}
        setValue={(value) => setFilter(column.id, value || undefined)}
        organizationId={filterMap?.[organizationIdLocation || '']}
        isSidebar={isSidebar}
      />
    );
  }

  if (type === TableFilterTypes.stateSelect) {
    return (
      <StateSelector
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={String(label)}
        fullWidth
        value={filterMap?.[column.id]}
        onChange={(event) => setFilter(column.id, event.target.value)}
      />
    );
  }

  if (type === TableFilterTypes.countrySelect) {
    return (
      <CountrySelector
        {...restOfOptions}
        key={column.id}
        id={`tableFilter-${column.id}`}
        data-testid={`tableFilter-${column.id}`}
        label={String(label)}
        fullWidth
        value={filterMap?.[column.id]}
        onChange={(event) => setFilter(column.id, event.target.value)}
      />
    );
  }

  if (type === TableFilterTypes.select) {
    return (
      <FormGroup fullWidth key={column.id}>
        <Label id={column.id} label={label} helper={restOfOptions?.labelHelper} />
        <StyledSelect
          {...restOfOptions}
          key={column.id}
          id={`tableFilter-${column.id}`}
          data-testid={`tableFilter-${column.id}`}
          value={filterMap?.[column.id]}
          onChange={(event) => setFilter(column.id, event.target.value)}
        >
          {options?.map(({ label, value }) => (
            <option value={value} key={label}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.recurrenceInterval) {
    return (
      <RecurrenceIntervalSelect
        id={`tableFilter-${column.id}`}
        value={filterMap?.[column.id]}
        onChange={(event) => setFilter(column.id, event.target.value)}
      />
    );
  }

  if (type === TableFilterTypes.recurrenceOption) {
    return (
      <RecurrenceOptionSelect
        id={`tableFilter-${column.id}`}
        value={filterMap?.[column.id]}
        onChange={(event) => setFilter(column.id, event.target.value)}
      />
    );
  }

  if (type === TableFilterTypes.switch) {
    return (
      <FormGroup fullWidth>
        <Label id={`tableFilter-${column.id}`} label={label} />
        <Switch
          data-testid={`tableFilter-${column.id}`}
          id={`tableFilter-${column.id}`}
          checked={filterMap?.[column.id]}
          onChange={(event) => setFilter(column.id, event.target.checked)}
        />
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.tags) {
    return (
      <TagInput
        key={column.id}
        selectedTags={filterMap?.[column.id] ? filterMap?.[column.id].split(',') : undefined}
        onChange={(value) => setFilter(column.id, value.join(','))}
        label={String(label) || 'Filter by tag'}
        emptyMessage="Filter by another tag"
      />
    );
  }

  if (type === TableFilterTypes.text) {
    return (
      <FormGroup fullWidth key={column.id}>
        <Label id={column.id} label={label} helper={restOfOptions?.labelHelper} />
        <Input
          id={`tableFilter-${column.id}`}
          data-testid={`tableFilter-${column.id}`}
          value={textInput}
          placeholder={filterMap?.placeholder || label}
          onChange={(event) => setTextInput(event.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && setFilter(column.id, event.target.value)}
        />
      </FormGroup>
    );
  }

  if (type === TableFilterTypes.hidden) return null;

  console.error('form type ' + type + ' has no form element set to render for it');

  return null;
}

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import {
  EntityLabelEnum,
  Maybe,
  NoteAuthorizationLevel,
  NoteMetadata,
  NoteMetadataPostInput,
  NotesTagsEnum,
  postNote,
  AttachmentType,
  queryUsers,
  queryServiceRequests,
  ElasticsearchSearchTypesEnum,
  SortByElasticSearchOptions,
  queryEquipment,
  Note,
  patchNote,
  querySquads,
  queryInvoices,
} from '@equips/entities-schema';
import debouncePromise from 'awesome-debounce-promise';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import AssignmentTurnedInOutlined from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import AttachFile from '@mui/icons-material/AttachFile';
import Group from '@mui/icons-material/Group';
import LibraryAdd from '@mui/icons-material/LibraryAdd';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import Notifications from '@mui/icons-material/Notifications';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import Person from '@mui/icons-material/Person';
import Work from '@mui/icons-material/Work';
import { MentionsInput, Mention, MentionItem as _MentionItem, OnChangeHandlerFunc } from 'react-mentions';
import { useQuery } from '@tanstack/react-query';
import { AuthorizationData, useAuth } from '../../auth/AuthContext';
import Label from '../Form/Label';
import Button from '../Buttons/Button';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { createAttachment } from '../../functions/createAttachment';
import DateInput from '../Date/DateInput';
import {
  dateStringFormattedForHtmlInput,
  getJavascriptDateXDaysAgo,
  getUnixMillisecondTimestampXMonthsAgo,
  toStringFromUnixMilliseconds,
  toUnixMillisecondsFromString,
} from '../../functions/dateFunctions';
import { getServiceRequestUsersForTagging } from '../../../graphql/queries/serviceRequestGraphQLQueries';
import { acceptedFileTypes, attachmentLimit, errorMessage, maxAttachmentSize } from '../../constants/attachments';
import Skeleton from '../Loaders/Skeleton';
import config from '../../../config';
import NoteAttachment from './NoteAttachment';
import { NoteListFields } from './NoteListFragment';
import './NoteForm.css';
import { NoteType } from './NoteListWithForm';

const USER_MENTION_PREFIX = '{user}:';
const SQUAD_MENTION_PREFIX = '{squad}:';

const unauthenticatedPath = `${config.apiGateway.endpoint}/unauth/notes`;
const invoiceTypes = ['application/pdf', 'text/csv'];
const reminderDateMin = dateStringFormattedForHtmlInput(getJavascriptDateXDaysAgo(-1));

export interface NoteFormProps {
  note?: Note;
  entityId?: string;
  parentLabel: EntityLabelEnum;
  onCreated?: (note: Maybe<Partial<NoteMetadata>>) => any;
  tags?: NotesTagsEnum[];
  allowInvoices?: boolean;
  allowReminders?: boolean;
  onClose?: (...args: any[]) => any;
  defaultCheckedVisibleTo?: NoteAuthorizationLevel;
  onInputChange?: (event: { target: { value: string } }) => void;
  noteType?: NoteType;
  hideVisibility?: boolean;
  // All other props
  [x: string]: any;
}

export interface VisibilityOption {
  label: string;
  show: boolean;
  defaultValue: boolean;
}

function getAuthorizationLevel(selections: string[]): NoteAuthorizationLevel {
  const visibility = new Set(selections);
  if (visibility.has('Everyone')) {
    return NoteAuthorizationLevel.All;
  } else if (visibility.has('Client') || visibility.has('My Organization')) {
    return NoteAuthorizationLevel.ClientAndEquips;
  } else if (visibility.has('Provider')) {
    return NoteAuthorizationLevel.ProviderAndEquips;
  } else {
    return NoteAuthorizationLevel.EquipsOnly;
  }
}

export function createVisibilityOptions({
  auth,
  isInternalUser,
  hideVisibility,
}: {
  auth: Maybe<AuthorizationData>;
  isInternalUser: boolean;
  hideVisibility: boolean;
}): VisibilityOption[] {
  const visibilityOptions = [
    {
      label: 'Client',
      show: isInternalUser,
      defaultValue: false,
    },
    {
      label: 'Provider',
      show: isInternalUser,
      defaultValue: false,
    },
    {
      label: 'Equips only',
      show: isInternalUser,
      defaultValue: isInternalUser && !hideVisibility,
    },
    {
      label: 'My Organization',
      show: !!auth && !isInternalUser,
      defaultValue: false,
    },
    { label: 'Everyone', show: true, defaultValue: !isInternalUser || hideVisibility },
  ];

  return visibilityOptions;
}

export const getInitialVisibility = (visibilityOptions: VisibilityOption[], authorizationLevel?: Maybe<NoteAuthorizationLevel>) => {
  switch (authorizationLevel) {
    case NoteAuthorizationLevel.All:
      return ['Everyone'];
    case NoteAuthorizationLevel.ClientAndEquips:
      return ['Client'];
    case NoteAuthorizationLevel.ProviderAndEquips:
      return ['Provider'];
    case NoteAuthorizationLevel.EquipsOnly:
      return ['Equips only'];
  }
  return visibilityOptions.filter((opt) => opt.show && opt.defaultValue).map((opt) => opt.label);
};

// Fix bad external typing
interface MentionItem extends Omit<_MentionItem, 'type'> {
  type: string;
}

export default function NoteForm({ note, entityId, parentLabel, onCreated, noteType, hideVisibility = false, ...props }: NoteFormProps) {
  if (props.allowInvoices && parentLabel !== EntityLabelEnum.ServiceRequest) throw new Error('Invoice upload is only allowed on SRs.');
  const [message, setMessage] = useState(note?.metadata?.message || '');
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>(note?.metadata?.mentionedUserIds || []);
  const [mentionedSquadIds, setMentionedSquadIds] = useState<string[]>(note?.metadata?.mentionedSquadIds || []);
  const [saving, setSaving] = useState(false);
  const [attachedInvoice, setAttachedInvoice] = useState(false);
  const [attachments, setAttachments] = useState<FileWithPath[]>([]);
  const [reminderOpen, setReminderOpen] = useState<HTMLElement | null>(null);
  const [reminderDate, setReminderDate] = useState<string | undefined>(
    note?.metadata?.remindAt ? toStringFromUnixMilliseconds(note.metadata.remindAt) : undefined,
  );
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const showAlert = useAlert();
  const { auth, userCan, internalUsers, isAasUser } = useAuth();

  const isInternalUser = userCan(internalUsers);
  const visibilityOptions = createVisibilityOptions({ isInternalUser, auth, hideVisibility });
  const initialVisibility = getInitialVisibility(visibilityOptions, note?.metadata?.authorizationLevel);
  const [visibility, setVisibility] = useState<string[]>(Array.from(new Set(initialVisibility)));
  const authorizationLevel = getAuthorizationLevel(visibility);
  const isInternalNote = noteType === NoteType.Internal;
  const [isClosingNote, setIsClosingNote] = useState(false);
  const [loadingMention, setLoadingMention] = useState(false);

  const submitForm = async () => {
    setSaving(true);

    if (note) {
      const noteId = note.noteId || note?.metadata?.noteId;
      if (noteId) {
        const { data } = await patchNote(
          {
            noteId,
            metadata: {
              noteId,
              message: props?.formatMessage ? props.formatMessage(message) : message,
              authorizationLevel: authorizationLevel ? authorizationLevel : null,
              mentionedUserIds: mentionedUserIds,
              mentionedSquadIds,
              remindAt: toUnixMillisecondsFromString(reminderDate),
            },
          },
          { query: NoteListFields },
        );

        if (onCreated) onCreated(data?.patch?.metadata);
      }
    } else {
      const attachmentMetadata = await Promise.all(
        attachments.map((attachment) => {
          if (!entityId || !auth?.organizationId) throw new Error('Missing entityId or organizationId');
          return createAttachment(
            attachment as File,
            {
              organizationId: auth.organizationId,
              parentId: entityId,
              parentLabel,
              type: AttachmentType.NoteAttachment,
            },
            !!auth,
          );
        }),
      );

      const metadata: NoteMetadataPostInput = {
        parentId: entityId as string,
        parentLabel: parentLabel,
        message: props?.formatMessage ? props.formatMessage(message) : message,
        authorizationLevel: authorizationLevel ? authorizationLevel : null,
        attachmentIds: attachmentMetadata.map((metadata) => metadata?.attachmentId),
        mentionedUserIds: mentionedUserIds,
        mentionedSquadIds,
        remindAt: toUnixMillisecondsFromString(reminderDate),
        tags: [
          ...(attachedInvoice ? [NotesTagsEnum.Invoice] : []),
          ...(reminderDate ? [NotesTagsEnum.Reminder] : []),
          ...(isInternalNote ? [NotesTagsEnum.InternalNote] : []),
          ...(isClosingNote ? [NotesTagsEnum.ClosingNotes] : []),
          ...(props.tags ?? []),
        ],
      };

      const { data } = auth
        ? await postNote({ metadata }, { query: NoteListFields })
        : await axios.post<{ post: Note }>(unauthenticatedPath, metadata, {
            headers: { 'Content-Type': 'application/json' },
          });

      if (onCreated) onCreated(data?.post?.metadata);
    }

    setSaving(false);
    setAttachedInvoice(false);
  };

  const addAttachments = (newFiles: FileWithPath[]) => {
    if (attachedInvoice) {
      const acceptedTypes = newFiles.every((f) => invoiceTypes.includes(f.type));
      if (!acceptedTypes) return showAlert({ content: 'Invoices must be PDF or CSV.', type: AlertTypes.error });
    } else if (attachments.length + newFiles.length > attachmentLimit) {
      return showAlert({ content: `There is a ${attachmentLimit} attachment limit per note.`, type: AlertTypes.error });
    }

    const acceptedFiles = newFiles.filter((file) => {
      const oversized = file.size > maxAttachmentSize;

      if (oversized) {
        showAlert({
          content: `${file.name} is above the ${maxAttachmentSize / 1e6}MB size limit.`,
          type: AlertTypes.error,
        });
      }

      return !oversized;
    });
    setAttachments([...acceptedFiles, ...attachments]);
  };

  const removeAttachment = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: addAttachments,
    onDropRejected: () => {
      showAlert({
        content: errorMessage,
        type: AlertTypes.error,
      });
    },
    accept: acceptedFileTypes,
    maxSize: maxAttachmentSize,
    multiple: true,
  });

  const addInvoice = () => {
    setAttachedInvoice(true);
    open();
  };

  const insertMention = (prefix: string) => {
    if (!inputRef.current) return;

    const getPadding = (char?: string) => (!char || char.match(/\s/) ? '' : ' ');
    const cursor = inputRef.current.selectionStart;
    const padBefore = getPadding(message[cursor - 1]);
    const padAfter = getPadding(message[cursor]);

    const newMessage = message.slice(0, cursor) + padBefore + prefix + padAfter + message.slice(cursor);
    setMessage(newMessage);
    const newCursor = cursor + padBefore.length + prefix.length;

    // Let input value update
    setTimeout(() => {
      inputRef.current?.setSelectionRange(newCursor, newCursor);
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (attachments.length === 0) setAttachedInvoice(false);
  }, [attachments]);

  const onInputChange: OnChangeHandlerFunc = (event, newValue, plainTextValue, mentions) => {
    if (props?.onInputChange) {
      props.onInputChange(event);
    }
    setMessage(newValue);

    setMentionedUserIds(mentions.filter((m) => m.id.includes(USER_MENTION_PREFIX)).map((m) => m.id.replace(USER_MENTION_PREFIX, '')));
    setMentionedSquadIds(mentions.filter((m) => m.id.includes(SQUAD_MENTION_PREFIX)).map((m) => m.id.replace(SQUAD_MENTION_PREFIX, '')));
  };

  const { data } = useQuery(
    ['getServiceRequestUsersForTagging', entityId],
    () => getServiceRequestUsersForTagging({ serviceRequestId: entityId }),
    {
      enabled: parentLabel === EntityLabelEnum.ServiceRequest && !!entityId && !!auth,
      select: (data) => data?.data?.serviceRequests?.data?.[0]?.metadata,
    },
  );

  const clientOrganizationId = data?.organizationId ?? '';
  const providerOrganizationId = data?.provider?.metadata?.organizationId ?? '';

  // If you are an Equips user you can mention anyone who is a GA or SGA additionally you can mention anyone at the client or provider org
  // If you are not an Equips user you can mention anyone at your org or the provider org or your AM
  const { data: usersData } = useQuery(
    ['mentionableUsers'],
    () =>
      queryUsers(
        {
          search: {
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            filters:
              userCan(internalUsers) && parentLabel === EntityLabelEnum.ServiceRequest
                ? [
                    { metadata: { organizationId: [{ keyword: [{ terms: [clientOrganizationId, providerOrganizationId] }] }] } },
                    { metadata: { authorizationRole: [{ keyword: [{ terms: internalUsers }] }] } },
                  ]
                : [
                    { metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } },
                    { metadata: { userId: [{ keyword: [{ term: auth?.organization?.metadata?.accountManagerId ?? '' }] }] } },
                  ],
            sortBy: [{ metadata: { fullName: { keyword: SortByElasticSearchOptions.Asc } } }],
          },
        },
        { query: `userId metadata { fullName }` },
      ),
    {
      // use this to defer execution of this query until getServiceRequestUsersForTagging finishes
      enabled: !(parentLabel === EntityLabelEnum.ServiceRequest && (clientOrganizationId || providerOrganizationId)),
      select: (data) => data?.data?.users?.data,
    },
  );

  // Query mentionable squads
  const { data: squadsData } = useQuery(
    ['mentionableSquads'],
    () =>
      querySquads(
        {
          search: {
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            filters: [{ metadata: { organizationId: [{ keyword: [{ term: clientOrganizationId }] }] } }],
            sortBy: [{ metadata: { name: { keyword: SortByElasticSearchOptions.Asc } } }],
          },
        },
        { query: `squadId metadata { name }` },
      ),
    {
      // use this to defer execution of this query until getServiceRequestUsersForTagging finishes
      enabled: !!clientOrganizationId,
      select: (data) => data?.data?.squad?.data,
    },
  );

  const mentionables = useMemo(() => {
    const mentionableSquads =
      squadsData
        ?.map((squad) => ({ id: `${SQUAD_MENTION_PREFIX}${squad?.squadId}`, display: squad?.metadata?.name, type: 'squad' }))
        .filter((item): item is MentionItem => !!item.id && !!item.display) || [];

    let mentionItems: MentionItem[] = [];

    if (parentLabel === EntityLabelEnum.ServiceRequest && !userCan(internalUsers)) {
      const clientAndProviderUsersFormattedForTagging = (data?.clientUsersEmailAndIds ?? [])
        .concat(data?.providerUsersEmailAndIds ?? [])
        ?.map((user) => ({ id: `${USER_MENTION_PREFIX}${user?.userId}`, display: user?.fullName, type: 'user' }))
        .filter((item): item is MentionItem => !!item.id && !!item.display);

      if (clientAndProviderUsersFormattedForTagging && clientAndProviderUsersFormattedForTagging.length) {
        mentionItems = [...clientAndProviderUsersFormattedForTagging, ...mentionableSquads];
      }
    } else {
      const mentionableUsers = usersData
        ?.map((user) => ({ id: `${USER_MENTION_PREFIX}${user?.userId}`, display: user?.metadata?.fullName, type: 'user' }))
        .filter((item): item is MentionItem => !!item.id && !!item.display);

      if (mentionableUsers && mentionableUsers.length) {
        mentionItems = [...mentionableUsers, ...mentionableSquads];
      }
    }

    return mentionItems.sort((a, b) => a.display.localeCompare(b.display) ?? 0);
  }, [parentLabel, userCan, internalUsers, data, usersData, squadsData]);

  const queryMentionableRequests = useCallback(
    debouncePromise(async (search: string, callback: (items: MentionItem[]) => void) => {
      setLoadingMention(true);
      const { data } = await queryServiceRequests(
        {
          search: {
            q: search,
            size: 10,
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            // For now to make this more performant for our internal users just query back a year.
            // Long term we should add GIN indexes on our key shortIds
            filters: userCan(internalUsers)
              ? [{ metadata: { createdAt: [{ range: { gte: getUnixMillisecondTimestampXMonthsAgo(12) } }] } }]
              : [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
            sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
          },
        },
        { query: `serviceRequestId metadata { serviceRequestDisplayName }` },
      );
      const requests = data?.serviceRequests?.data
        ?.map((sr) => {
          const name = sr?.metadata?.serviceRequestDisplayName ?? '';
          const display = name.length < 50 ? name : name.slice(0, 50) + '...';
          return { id: sr?.serviceRequestId, display, type: 'request' };
        })
        .filter((item): item is MentionItem => !!item.id && !!item.display);
      callback(requests ?? []);
      setLoadingMention(false);
    }, 1000),
    [],
  );

  const queryMentionableEquipment = useCallback(
    debouncePromise(async (search: string, callback: (items: MentionItem[]) => void) => {
      setLoadingMention(true);
      const { data } = await queryEquipment(
        {
          search: {
            q: search,
            size: 10,
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            filters: [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
            sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
          },
        },
        { query: `equipmentId metadata { equipmentName shortId }` },
      );
      const equipment = data?.equipment?.data
        ?.map((eq) => {
          const name = eq?.metadata?.shortId ? `${eq?.metadata?.shortId} - ${eq?.metadata?.equipmentName}` : '';
          const display = name.length < 50 ? name : name.slice(0, 50) + '...';
          return { id: eq?.equipmentId, display, type: 'equipment' };
        })
        .filter((item): item is MentionItem => !!item.id && !!item.display);
      callback(equipment ?? []);
      setLoadingMention(false);
    }, 1000),
    [],
  );

  const queryMentionableInvoices = useCallback(
    debouncePromise(async (search: string, callback: (items: MentionItem[]) => void) => {
      setLoadingMention(true);
      const { data } = await queryInvoices(
        {
          search: {
            q: search,
            size: 10,
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            filters: userCan(internalUsers)
              ? []
              : [{ metadata: { clientOrganizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
            sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
          },
        },
        { query: `invoiceId metadata { title shortId }` },
      );
      const invoices = data?.invoices?.data
        ?.map((invoice) => {
          const name = invoice?.metadata?.shortId ? `${invoice?.metadata?.shortId} - ${invoice?.metadata?.title}` : '';
          const display = name.length < 50 ? name : name.slice(0, 50) + '...';
          return { id: invoice?.invoiceId, display, type: 'invoice' };
        })
        .filter((item): item is MentionItem => !!item.id && !!item.display);
      callback(invoices ?? []);
      setLoadingMention(false);
    }, 1000),
    [],
  );

  const getShowableVisibilityOptions = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const selectedOptions = e.target.value as unknown as string[];
    const visibilityIncludesEveryone = visibility.indexOf('Everyone') > -1;
    const selectedOptionsIncludesEveryone = selectedOptions.indexOf('Everyone') > -1;
    const allShowableOptions = visibilityOptions.filter((opt: VisibilityOption) => opt.show).map((opt: VisibilityOption) => opt.label);

    if (selectedOptions.length === 0) return initialVisibility;

    if (!visibilityIncludesEveryone && !selectedOptionsIncludesEveryone)
      return selectedOptions.length === allShowableOptions.length - 1 && allShowableOptions.length > 2 ? ['Everyone'] : selectedOptions;
    if (!visibilityIncludesEveryone) return ['Everyone'];
    if (!selectedOptionsIncludesEveryone && initialVisibility[0] !== 'Everyone') return initialVisibility;
    if (visibility.length < selectedOptions.length) return selectedOptions;

    return selectedOptions.filter((opt: string) => opt !== 'Everyone');
  };

  const renderSuggestion = (entry) => {
    if (loadingMention) {
      return <Skeleton width={100} />;
    }

    const isSquad = entry.id.startsWith(SQUAD_MENTION_PREFIX);

    return (
      <div className="flex text-gray-700">
        <div className={`px-2 ${isSquad ? 'text-equipsOrange' : 'text-equipsGreen'}`}>
          <Tooltip title={isSquad ? 'Squad' : 'User'}>{isSquad ? <Group /> : <Person />}</Tooltip>
        </div>
        <div>{entry.display}</div>
      </div>
    );
  };

  const noteIconButtons: {
    title: string;
    component: JSX.Element;
    extras?: JSX.Element;
    show: boolean;
  }[] = [
    {
      title: 'Attach a file',
      component: (
        <IconButton disabled={saving || attachedInvoice} onClick={open} size="large">
          <AttachFile />
        </IconButton>
      ),
      show: true,
    },
    {
      title: 'Attach an invoice',
      component: (
        <IconButton
          disabled={saving || attachments.length > 0}
          onClick={addInvoice}
          className={attachedInvoice ? 'text-blue-600' : ''}
          size="large"
        >
          <MonetizationOn />
        </IconButton>
      ),
      show: !!props?.allowInvoices && !isAasUser,
    },
    {
      title: 'Mention someone',
      component: (
        <IconButton onClick={() => insertMention('@')} size="large">
          <AlternateEmail />
        </IconButton>
      ),
      show: true,
    },
    {
      title: 'Link to another request',
      component: (
        <IconButton onClick={() => insertMention('SR')} size="large">
          <LibraryAdd />
        </IconButton>
      ),
      show: true,
    },
    {
      title: 'Link to equipment',
      component: (
        <IconButton onClick={() => insertMention('EQ')} size="large">
          <Work />
        </IconButton>
      ),
      show: !isAasUser,
    },
    {
      title: 'Remind me about this',
      component: (
        <IconButton onClick={(e) => setReminderOpen(e.currentTarget)} className={reminderDate ? 'text-blue-600' : ''} size="large">
          {reminderDate ? <NotificationsActive /> : <Notifications />}
        </IconButton>
      ),
      extras: (
        <Popover
          open={!!reminderOpen}
          anchorEl={reminderOpen}
          onClose={() => setReminderOpen(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="p-3">
            <Label id="remindAtDate" label="Remind me on" helper="Set a date to receive a reminder by email" />
            <DateInput
              id="remindAtDate"
              type="date"
              value={reminderDate}
              onChange={({ target: { value } }) => setReminderDate(value)}
              tiny
              min={reminderDateMin}
            />
          </div>
        </Popover>
      ),
      show: !!props?.allowReminders,
    },
    {
      title: 'Mark as Closing note',
      component: (
        <IconButton onClick={() => setIsClosingNote((state) => !state)} size="large">
          {isClosingNote ? <AssignmentTurnedIn /> : <AssignmentTurnedInOutlined />}
        </IconButton>
      ),
      show: parentLabel === EntityLabelEnum.ServiceRequest && !isAasUser,
    },
  ];

  return (
    // Don't show file picker onClick. Dummy handler doesn't need a11y lint.
    // eslint-disable-next-line
    <div {...getRootProps({ className: 'relative' })} onClick={() => {}}>
      {isDragActive && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex scale-105 transform items-center justify-center rounded-2xl border-4 border-dashed border-gray-700 bg-gray-400 bg-opacity-75 p-10 text-lg font-bold opacity-75">
          <AttachFile></AttachFile> Drop files here
        </div>
      )}
      <input {...getInputProps()} />
      <div data-testid="noteForm">
        <div className="relative flex flex-wrap items-center justify-between rounded-t-xl border border-b-0 border-gray-300 bg-gray-50 px-1 text-sm">
          {auth && (
            <div className="flex">
              {noteIconButtons.map(
                (item) =>
                  item.show && (
                    <>
                      <Tooltip title={item.title} key={item.title}>
                        {item.component}
                      </Tooltip>
                      {item?.extras}
                    </>
                  ),
              )}
            </div>
          )}

          {!hideVisibility && (
            <div className="flex items-center gap-2 p-1">
              <label className="whitespace-nowrap font-semibold text-gray-700" htmlFor="noteAuthorizationLevel">
                Visible to
              </label>
              <div>
                <Select
                  id="noteAuthorizationLevel"
                  multiple
                  value={visibility}
                  onChange={(e) => setVisibility(Array.from(new Set(getShowableVisibilityOptions(e))))}
                  input={<InputBase className="mt-1 w-44 text-sm" />}
                  renderValue={(selected: string[]) => (visibility.includes('Everyone') ? 'Everyone' : selected.join(', '))}
                >
                  {visibilityOptions
                    .filter((opt) => opt.show)
                    .map((option) => (
                      <MenuItem
                        disabled={initialVisibility[0] !== 'Everyone' && visibility.indexOf('Everyone') > -1 && option.label !== 'Everyone'}
                        dense
                        key={option.label}
                        value={option.label}
                      >
                        <Checkbox checked={visibility.indexOf(option.label) > -1} />
                        <ListItemText className="text-sm" primary={option.label} />
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          )}
        </div>

        <div className="custom-suggestions">
          <MentionsInput
            inputRef={inputRef}
            allowSpaceInQuery={true}
            value={message}
            className="noteInput"
            onChange={onInputChange}
            required
            id="noteInput"
            data-testid="noteInput"
            allowSuggestionsAboveCursor
          >
            <Mention
              trigger="@"
              data={mentionables || []}
              markup="@[__display__](__id__)"
              className="rounded-lg bg-blue-100"
              renderSuggestion={renderSuggestion}
            />
            <Mention
              trigger="SR"
              data={queryMentionableRequests}
              markup="SR[__display__](__id__)"
              className="rounded-lg bg-orange-100"
              {...(loadingMention ? { renderSuggestion: () => <Skeleton width={100} /> } : null)}
            />
            <Mention
              trigger="EQ"
              data={queryMentionableEquipment}
              markup="EQ[__display__](__id__)"
              className="rounded-lg bg-orange-100"
              {...(loadingMention ? { renderSuggestion: () => <Skeleton width={100} /> } : null)}
            />
            <Mention
              trigger="IN"
              data={queryMentionableInvoices}
              markup="IN[__display__](__id__)"
              className="rounded-lg bg-orange-100"
              {...(loadingMention ? { renderSuggestion: () => <Skeleton width={100} /> } : null)}
            />
          </MentionsInput>
        </div>

        {attachments.length > 0 && (
          <div className="ml-1 mt-5 text-sm text-gray-800">
            <strong>Attachments</strong>
            <span className="ml-3 text-xs text-gray-500">
              These attachments will be visible to Equips employees and other users in your organization.
            </span>
            <ul className="my-2 flex flex-wrap gap-2">
              {attachments.map((file, index) => (
                <NoteAttachment key={file.name} name={file.name} type={file.type} onRemove={() => removeAttachment(index)} />
              ))}
            </ul>
          </div>
        )}
        {props.children}
        <div className="mt-1 flex justify-end">
          <input
            hidden
            accept={acceptedFileTypes.join()}
            id="uploadButtonInput"
            type="file"
            multiple
            onChange={(e) => addAttachments(Array.from(e.target.files ?? []))}
          />
          {props.onClose && (
            <Button disabled={saving} blue className="mx-1 mt-2 px-8" onClick={() => props.onClose?.()}>
              Cancel
            </Button>
          )}
          {!props.overrideSaveButton ? (
            <Button disabled={saving || !message} className="mx-1 mt-2 px-8" loading={saving} onClick={submitForm}>
              Save
            </Button>
          ) : (
            props.overrideSaveButton({ submitForm, saving, message })
          )}
        </div>
      </div>
    </div>
  );
}

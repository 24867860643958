import {
  ElasticsearchSearchTypesEnum,
  InvoicesQueryInvoicesArgs,
  queryInvoices,
  InvoiceStatusEnumType,
  Maybe,
  Invoice,
  InvoicesSearchInput,
  fullInvoicesMetadataGraphqlNode,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import { gql } from '../graphqlHelperFunctions';
import { toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST } from '../../common/functions/expensiveDateFunctions';
import { postToInvoicesEndpoint as untypedPostToInvoicesEndpoint } from '../postToEndpoint';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { NoteListFields } from '../../common/components/ViewPage/NoteListFragment';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { TableFetch, TableFetchArgs } from '../../common/components/DataTable';
import { getAasOrganizationId } from '../../common/functions/aasHelpers';
import { getUhwOrganizationId } from '../../common/functions/uhwHelpers';
import { categoryGraphQlNode } from './categoryQueries';

export interface InvoiceQueryOptions {
  hideAASInvoices: boolean;
  hideUHWInvoices: boolean;
}

const postToInvoicesEndpoint = async ({ query, variables }: { query: string; variables: InvoicesQueryInvoicesArgs }) => {
  return untypedPostToInvoicesEndpoint({ query, variables });
};

export const invoiceSelectSearch = async (searchQuery) =>
  await queryInvoices(
    {
      search: {
        size: 10,
        // All invoice shortIds start with IN and have no lowercase letters
        q: searchQuery.toUpperCase(),
        sortBy: [{ metadata: { shortId: { keyword: SortByElasticSearchOptions.Desc } } }],
      },
    },
    { query: `metadata { invoiceId shortId }` },
  );

export const getInvoiceBySearch = async (searchQuery) =>
  postToInvoicesEndpoint({
    query: gql`
      query InvoicesSearchInput($search: InvoicesSearchInput) {
        invoices(search: $search) {
          data {
            metadata {
              invoiceId
              shortId
            }
          }
        }
      }
    `,
    variables: { search: { q: searchQuery } },
  });

export const getInvoiceNotes = async ({ entityId }) => {
  const data = await queryInvoices(
    { invoiceId: entityId },
    {
      query: `notes { ${NoteListFields} }`,
    },
  );
  return data.data?.invoices?.data?.[0]?.notes ?? [];
};

export const getAnInvoiceById = async ({ invoiceId }) =>
  await queryInvoices(
    { invoiceId },
    {
      query: `
        metadata {
          serviceRequests{
            metadata{
              shortId
              serviceRequestId
            }
          }
          shortId
          invoiceStatus
          technicianName
          providerOrganizationId
          providerOrganization {
            metadata {
              organizationName
            }
          }
          clientOrganizationId
          clientOrganization {
            metadata {
              organizationName
            }
          }
          fileId
          file {
            metadata {
              text
              textract
            }
          }
          fixes
          shortId
          adjustedInvoiceSavings
          externalInvoiceNumber
          externalServiceNumber
          externalInvoiceDate
          externalServiceDate
          serviceRequestNumber
          serialNumber
          repairDescription
          invoiceTotal
          invoiceTax
          receivedAt
        }
      `,
    },
  );

export const getSignedUrlByInvoiceId = async ({ invoiceId }) =>
  postToInvoicesEndpoint({
    query: gql`
      query getInvoicePresignedGetUrl($invoiceId: String) {
        invoices(invoiceId: $invoiceId) {
          data {
            metadata {
              file {
                metadata {
                  fileExtension
                  presignedGetUrl
                }
              }
              quoteFile {
                metadata {
                  fileExtension
                  presignedGetUrl
                }
              }
            }
          }
        }
      }
    `,
    variables: { invoiceId },
  });

export const getInvoicesForProcessingTimeReport = async ({ startDateTimestamp, endDateTimestamp, organizationId }) =>
  queryInvoices(
    {
      search: {
        size: 500,
        filters: [
          {
            metadata: {
              invoiceStatus: [
                {
                  keyword: [
                    {
                      term: InvoiceStatusEnumType.Adjudicated,
                    },
                  ],
                },
              ],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          invoiceId
          createdAt
          adjudicatedOn
        }
    `,
    },
  );

export const getInvoiceByIdForMainView = async ({ invoiceId }) => {
  return queryInvoices(
    { invoiceId },
    {
      query: `
      charges {
        metadata {
          chargeStatus
          adjudicationResult
          primaryAdjudicationReason
        }
      }
      metadata {
        invoiceId
        shortId
        title
        deactivatedAt
        createdAt
        modifiedAt
        adjustedInvoiceSavings
        maintenanceContractSavings
        clientOrganizationId
        invoiceStatus
        finalizedOn
        adjudicatedOn
        adjudicatedByUserName
        followUpDate
        fileId
        quoteFileId
        serviceRequestNumber
        externalInvoiceDate
        externalServiceDate
        externalInvoiceNumber
        externalServiceNumber
        serviceRequestNumber
        serialNumber
        repairDescription
        invoiceTotal
        invoiceTax
        receivedAt
        fixes
        invoiceTotal
        invoiceTax
        variance
        clientOrganization {
          metadata {
            organizationBillingMethod
            organizationName
            isTrial
            tier
          }
        }
        providerOrganizationId
        providerOrganization {
          metadata {
            organizationName
            organizationId
            tier
          }
          provider{
            dispatchEmail
            dispatchPhone
          }
        }
        createdByUser {
          metadata {
            fullName
          }
        }
        modifiedByUser {
          metadata {
            fullName
          }
        }
        assigneeUserId
        assignee {
          metadata {
            fullName
          }
        }
        serviceRequests{
          metadata {
            serviceRequestId
            shortId
            providerRefId
            createdAt
            providerId
          }
          incidents {
            incidentId
            metadata{
              organizationId
            }
          }
          equipmentMetadata{
            shortId
            serialNumber
          }
        }
        file {
          metadata {
            text
            textract
          }
        }
      }
      equipment {
        equipmentId
        metadata {
          shortId
          equipmentName
          serialNumber
          location{
            metadata{
              locationId
              locationName
              organization{
                metadata{
                  organizationName
                  organizationId
                }
              }
            }
            address {
              line1
              line2
              city
              stateUnabbreviated
              zip
              phone
            }
          }
        }
        specMetadata {
          ${categoryGraphQlNode}
        }
      }`,
    },
  );
};

export const getEquipmentByInvoiceId = async ({ invoiceId }) => {
  return queryInvoices(
    { invoiceId },
    {
      query: `equipment{
        metadata {
          equipmentId
          equipmentName
        }
        specMetadata {
          ${categoryGraphQlNode}
        }
      }`,
    },
  );
};

export const mainTableQueryString = `
  metadata {
    invoiceId
    invoiceStatus
    createdAt
    modifiedAt
    receivedAt
    finalizedOn
    finalizedByUserId
    finalizedByUser{
      metadata{
        userId
        fullName
      }
    }
    adjudicatedOn
    approvedOn
    approvedByUser{
      metadata{
        userId
        fullName
      }
    }
    approvedByUserId
    followUpDate
    shortId
    externalInvoiceNumber
    externalServiceNumber
    externalInvoiceDate
    externalServiceDate
    serviceRequestNumber
    maintenanceContractSavings
    serialNumber
    repairDescription
    invoiceTotal
    invoiceTax
    variance
    createdByUser {
      metadata {
        fullName
      }
    }
    modifiedByUser {
      metadata {
        fullName
      }
    }
    assignee {
      metadata {
        fullName
      }
    }
    providerOrganizationId
    providerOrganization {
      metadata {
        organizationName
        organizationId
        tier
      }
    }
    clientOrganizationId
    clientOrganization {
      metadata {
        organizationName
        organizationId
        tier
      }
    }
    file {
      metadata {
        presignedGetUrl
      }
    }
  }
  equipment {
    equipmentId
    metadata {
      shortId
      equipmentName
    }
    specMetadata {
      ${categoryGraphQlNode}
    }
  }
`;

export const paymentInvoicesQueryString = `
  metadata {
    invoiceId
    invoiceStatus
    createdAt
    denialInvoiceFileId
    denialInvoicePaidAt
    modifiedAt
    followUpDate
    invoiceDisplayStatus
    shortId
    externalInvoiceNumber
    externalServiceNumber
    externalInvoiceDate
    externalServiceDate
    serviceRequestNumber
    repairDescription
    createdByUser {
      metadata {
        fullName
      }
    }
    modifiedByUser {
      metadata {
        fullName
      }
    }
    assignee {
      metadata {
        fullName
      }
    }
    providerOrganizationId
    providerOrganization {
      metadata {
        organizationName
        organizationId
      }
    }
    clientOrganizationId
    clientOrganization {
      metadata {
        organizationName
        organizationId
      }
    }
    denialInvoiceFile {
      metadata {
        presignedGetUrl
        metadata
      }
    }
    legacyPaymentNotificationSentAt
    serviceRequests {
      metadata {
        serviceRequestId
      }
    }
  }
  equipment {
    equipmentId
    specMetadata {
      ${categoryGraphQlNode}
    }
    metadata {
      shortId
      equipmentName
      location {
        address {
          city
          line1
          line2
          stateUnabbreviated
          zip
          countryUnabbreviated
        }
      }
    }
    specMetadata {
      primaryClassId
    }
  }
`;

const customDateFunctionParams = {
  customDatestringToTimestampFunction: toUTCUnixMillisecondFromStringRoundedToStartOfDayInEST,
};

export const getPaymentInvoicesForMainTable = async (
  { sortBy, globalFilter = '', pageSize = 10, pageIndex = 0, filterMap, includeTotalCount = false }: TableFetchArgs,
  { hideAASInvoices, hideUHWInvoices }: InvoiceQueryOptions,
) => {
  const search: InvoicesSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.assigneeUserId']
                ? { assigneeUserId: [{ keyword: [{ term: filterMap['metadata.assigneeUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.providerOrganizationId']
                ? { providerOrganizationId: [{ keyword: [{ term: filterMap['metadata.providerOrganizationId'] }] }] }
                : {}),
              clientOrganizationId: [
                {
                  keyword: [
                    {
                      ...(filterMap['metadata.clientOrganizationId'] ? { term: filterMap['metadata.clientOrganizationId'] } : {}),
                      notTerms: [hideAASInvoices ? getAasOrganizationId() : '', hideUHWInvoices ? getUhwOrganizationId() : ''],
                    },
                    {
                      exists: false,
                    },
                  ],
                },
              ],
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              ...(filterMap['metadata.claimOrigination']
                ? { claimOrigination: [{ keyword: [{ term: filterMap['metadata.claimOrigination'] }] }] }
                : {}),
              ...(filterMap['metadata.clientId']
                ? { clientOrganizationId: [{ keyword: [{ term: filterMap['metadata.clientId'] }] }] }
                : {}),
              ...(filterMap['metadata.denialInvoiceFileId'] === 'true' ? { denialInvoiceFileId: [{ keyword: [{ notTerm: null }] }] } : {}),
              ...(filterMap['metadata.denialInvoiceFileId'] === 'false' ? { denialInvoiceFileId: [{ keyword: [{ term: null }] }] } : {}),
              denialInvoicePaidAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(
                filterMap['metadata.denialInvoicePaidAt'],
                customDateFunctionParams,
              ),
              ...(filterMap['metadata.denialInvoicePaid'] === 'true' && !filterMap['metadata.denialInvoicePaidAt']
                ? { denialInvoicePaidAt: [{ exists: true }] }
                : {}),
              ...(filterMap['metadata.denialInvoicePaid'] === 'false' && !filterMap['metadata.denialInvoicePaidAt']
                ? { denialInvoicePaidAt: [{ exists: false }] }
                : {}),
            },
            providerOrganization: {
              ...(filterMap['metadata.providerId'] ? { organizationId: [{ keyword: [{ term: filterMap['metadata.providerId'] }] }] } : {}),
            },
          },
        ]
      : [],
  };
  const { data } = await queryInvoices({ search }, { query: paymentInvoicesQueryString });

  return { totalCount: data?.invoices?.totalCount, data: data?.invoices?.data ?? [] };
};

export const getInvoicesForMainTable = async (
  { sortBy, globalFilter = '', pageSize = 10, pageIndex = 0, filterMap, includeTotalCount = false }: TableFetchArgs,
  { hideAASInvoices, hideUHWInvoices }: InvoiceQueryOptions,
) => {
  const search: InvoicesSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              ...(filterMap['metadata.invoiceStatus']
                ? { invoiceStatus: toTerms<InvoiceStatusEnumType>(filterMap['metadata.invoiceStatus']) }
                : {}),
              adjudicatedOn: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.adjudicatedOn']),
              approvedOn: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.approvedOn']),
              ...(filterMap['metadata.adjudicatedByUserId']
                ? { adjudicatedByUserId: [{ keyword: [{ term: filterMap['metadata.adjudicatedByUserId'] }] }] }
                : {}),
              followUpDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.followUpDate']),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              receivedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.receivedAt']),
              ...(filterMap['metadata.approvedByUserId']
                ? { approvedByUserId: [{ keyword: [{ term: filterMap['metadata.approvedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.finalizedByUserId']
                ? { finalizedByUserId: [{ keyword: [{ term: filterMap['metadata.finalizedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.assigneeUserId']
                ? { assigneeUserId: [{ keyword: [{ term: filterMap['metadata.assigneeUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.providerOrganizationId']
                ? { providerOrganizationId: [{ keyword: [{ term: filterMap['metadata.providerOrganizationId'] }] }] }
                : {}),
              clientOrganizationId: [
                {
                  keyword: [
                    {
                      ...(filterMap['metadata.clientOrganizationId'] ? { term: filterMap['metadata.clientOrganizationId'] } : {}),
                      notTerms: [hideAASInvoices ? getAasOrganizationId() : '', hideUHWInvoices ? getUhwOrganizationId() : ''],
                    },
                    {
                      exists: false,
                    },
                  ],
                },
              ],
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              ...(filterMap['metadata.claimOrigination']
                ? { claimOrigination: [{ keyword: [{ term: filterMap['metadata.claimOrigination'] }] }] }
                : {}),
            },
          },
        ]
      : [],
  };

  const { data } = await queryInvoices({ search }, { query: mainTableQueryString });

  return { totalCount: data?.invoices?.totalCount, data: data?.invoices?.data ?? [] };
};

export const paymentScreenQueryString = `
  metadata {
    closingNotes{
      metadata {
        noteId
        message
      }
    }
    serviceRequests{
      metadata{
        shortId
        title
        serviceRequestId
      }
    }
    quickbooksInvoice {
      Id
      DocNumber
      DueDate
      TotalAmt
      Balance
      MetaData {
        CreateTime
      }
    }
    coverageStatus
    invoiceDisplayStatus
    charges {
      metadata {
        amountInCents
        chargeStatus
        adjudicationResult
      }
    }
    invoiceId
    invoiceStatus
    createdAt
    modifiedAt
    shortId
    externalInvoiceNumber
    externalInvoiceDate
    externalServiceDate
    serviceRequestNumber
    invoiceTotal
    invoiceTax
    createdByUser {
      metadata {
        fullName
      }
    }
    modifiedByUser {
      metadata {
        fullName
      }
    }
    clientOrganizationId
    file {
      metadata {
        presignedGetUrl
      }
    }
    denialInvoiceFile {
      metadata {
        presignedGetUrl
      }
    }
  }
  equipment {
    equipmentId
    locationMetadata {
      shortId
      locationName
      locationId
      locationDisplayName
    }
    metadata {
      shortId
      equipmentName
    }
    specMetadata {
      ${categoryGraphQlNode}
    }
  }
`;

export const getInvoicesForPaymentScreen: TableFetch<Maybe<Invoice>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const search: InvoicesSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              ...(filterMap['metadata.invoiceStatus']
                ? { invoiceStatus: toTerms<InvoiceStatusEnumType>(filterMap['metadata.invoiceStatus']) }
                : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
              ...(filterMap['metadata.clientOrganizationId']
                ? { clientOrganizationId: [{ keyword: [{ term: filterMap['metadata.clientOrganizationId'] }] }] }
                : {}),
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
            },
          },
        ]
      : [],
  };

  const { data } = await queryInvoices({ search }, { query: paymentScreenQueryString });

  return { totalCount: data?.invoices?.totalCount, data: data?.invoices?.data ?? [] };
};

export const getQBInvoiceFromInvoiceByOrganizationIdAndDateRange = async ({ organizationId, endDate, startDate }) =>
  postToInvoicesEndpoint({
    query: gql`
      query InvoicesSearchInput($search: InvoicesSearchInput) {
        invoices(search: $search) {
          data {
            metadata {
              quickbooksInvoice {
                Balance
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 1000,
        filters: [
          {
            metadata: {
              clientOrganizationId: [{ keyword: [{ term: organizationId }] }],
              createdAt: [{ range: { lte: endDate, gte: startDate } }],
            },
          },
        ],
      },
    },
  });

export const getInvoiceStatusesForCharts = async ({ organizationId, endDate, startDate }) =>
  postToInvoicesEndpoint({
    query: gql`
      query InvoicesSearchInput($search: InvoicesSearchInput) {
        invoices(search: $search) {
          data {
            metadata {
              coverageStatus
              invoiceDisplayStatus
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 1000,
        filters: [
          {
            metadata: {
              clientOrganizationId: [{ keyword: [{ term: organizationId }] }],
              createdAt: [{ range: { lte: endDate, gte: startDate } }],
            },
          },
        ],
      },
    },
  });

export const getInvoicesForChargesEntryScreen = async ({ invoiceId }) =>
  await queryInvoices(
    { invoiceId },
    {
      query: `
      ${fullInvoicesMetadataGraphqlNode} 
      metadata {
        clientOrganization {
          metadata {
            isTrial 
            quickbooksCompanyId 
            organizationBillingMethod
          }
        } 
        providerOrganization {
          metadata { 
            quickbooksCompanyId 
            quickbooksVendorId 
          } 
        } 
      }`,
    },
  );

import React, { useEffect, useState } from 'react';
import {
  EntityLabelEnum,
  Equipment,
  GeolocationType,
  Location,
  OrganizationFeatureFlagEnum,
  queryOrganizations,
  queryUsers,
  RecurrenceOptionsType,
  RequestOriginationType,
  ServiceTypeEnum,
} from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import LocationOn from '@mui/icons-material/LocationOn';
import dayjs from 'dayjs';
import { useAuth } from '../../auth/AuthContext';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../functions/phoneFunctions';
import AttachmentDropzone from '../Files/AttachmentDropzone';
import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import UserSelectDialog from '../Selects/UserSelectDialog';
import SymptomsSelect from '../Selects/SymptomsSelect';
import Button from '../Buttons/Button';
import Label from '../Form/Label';
import FlatAccordion from '../Accordion/FlatAccordion';
import DateInput from '../Date/DateInput';
import { toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from '../../functions/dateFunctions';
import { EquipmentMultiCard } from '../Equipment/EquipmentMultiCard';
import { RichTextEditor } from '../RichTextEditor';
import LocationCard from '../Selects/LocationCard';
import ChecklistManager from '../Checklists/ChecklistManager';
import ExpansionPanel from '../Accordion/ExpansionPanel';
import { ClientTagsSelect } from '../Selects/ClientTagsSelect';
import CurrencyInput from '../Form/CurrencyInput';
import DateTimeInput from '../Date/DateTimeInput';
import AdditionalContactsContainer from './AdditionalContactsContainer';
import { RequestFormConsumables } from './RequestFormConsumables';
import { RequestFormData } from './SubmitRequest';
import { generateDefaultTitle } from './submitRequestMutations';
import RequestFormRecurrence from './RequestFormRecurrence';
import RecurrenceOptionSelect from './RequestFormRecurrenceOption';

type RequestFormProps = {
  value: RequestFormData;
  equipment: Equipment[];
  location?: Location;
  geolocation?: GeolocationType;
  organizationId?: string;
  isSaving: boolean;
  onChange: (value: RequestFormData) => unknown;
  onSubmit: () => unknown;

  /** Request is made from PM schedule page */
  pmView?: boolean;
};

export function RequestForm(props: RequestFormProps) {
  const { value, onChange, equipment, location, geolocation } = props;

  const { userCan, internalUsers, determineFeatureFlagVisibility } = useAuth();

  const [mainContactData, setMainContactData] = useState({ fullName: '', phoneNumber: '' });
  const [assignedInternalUserData, setAssignedInternalUserData] = useState({ fullName: '', phoneNumber: '' });

  const [userPickerShowing, setUserPickerShowing] = useState(false);
  const [assignedInternalUserPickerShowing, setAssignedInternalUserPickerShowing] = useState(false);
  const [preventiveDisclaimer, setPreventiveDisclaimer] = useState(value.serviceType === 'Preventive' ? false : null);
  const [description, setDescription] = useState('');

  const { data: isOrganizationDispatchingExternally } = useQuery(
    ['GetOrganizationAutoForwardAllDispatchRequests', props.organizationId],
    async () => queryOrganizations({ organizationId: props.organizationId }, { query: `metadata{ autoForwardAllDispatchRequests }` }),
    {
      select: (data) => data?.data?.organizations?.data?.[0]?.metadata?.autoForwardAllDispatchRequests ?? true,
      enabled: value.serviceType === 'Preventive',
    },
  );

  /** Get default main contact data */
  const { data: defaultMainContactData } = useQuery(
    [`InteractiveServiceRequestFormUser${value.mainContactUserId}`],
    async () =>
      queryUsers({
        userId: value.mainContactUserId,
      }),
    { enabled: !!value.mainContactUserId, select: (data) => data?.data?.users?.data?.[0]?.metadata },
  );

  const defaultMainContactFullName = defaultMainContactData?.fullName;
  const defaultMainContactPhoneNumber = defaultMainContactData?.phoneNumberWithCountryCode;

  let defaultTitle = generateDefaultTitle({
    ...value,
    categoryName: equipment && equipment.length === 1 ? equipment?.[0]?.specMetadata?.category?.metadata?.name : undefined,
  });

  useEffect(() => {
    onChange({
      ...value,
      title: (defaultTitle = generateDefaultTitle({
        ...value,
        categoryName: equipment && equipment.length === 1 ? equipment?.[0]?.specMetadata?.category?.metadata?.name : undefined,
      })),
    });
  }, [value.symptoms]);

  useEffect(() => {
    onChange({
      ...value,
      description,
    });
  }, [description]);

  return (
    <div>
      <div className="mb-5">
        <div className="mb-4">
          <div className="text-sm">
            {equipment.length > 0 && <EquipmentMultiCard equipment={equipment} />}
            {location && (
              <div className="ml-11 mt-1">
                <span className="font-semibold text-gray-700">Location:</span>
                <LocationCard location={location} />
              </div>
            )}
            {geolocation && (
              <>
                <div className="mt-2 flex items-start gap-5">
                  <LocationOn className=" text-equipsLightBlue" />
                  <div>
                    {geolocation.displayName}
                    <p className="text-equipsLightBlue">
                      Lat: {geolocation.lat}, Long: {geolocation.lng}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="mb-2" />
        {value.serviceType === ServiceTypeEnum.Corrective ? (
          !determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Symptoms) &&
          equipment &&
          equipment.length > 0 && (
            <SymptomsSelect
              label="Issues"
              categoryId={equipment?.[0]?.specMetadata?.categoryId ?? undefined}
              fullWidth
              changeSymptoms={(items) => onChange({ ...value, symptoms: items })}
              selectedSymptoms={value.symptoms}
              required
            />
          )
        ) : value.serviceType === ServiceTypeEnum.Consumables ? (
          <RequestFormConsumables
            partNumber={value.partNumber}
            partQuantity={value.partQuantity}
            onChange={(v) => onChange({ ...value, ...v })}
          />
        ) : null}

        {value.serviceType === ServiceTypeEnum.Corrective && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalDueDate) && (
          <>
            <Label id="createdAt" label="Due date" helper="Due date of a recurring or non-recurring service request" />
            <DateTimeInput
              isWhite
              id="dueDateModule"
              value={value?.dueDate || dayjs().valueOf()}
              onChange={(dueDate) => onChange({ ...value, dueDate: dayjs(dueDate).valueOf() })}
            />
          </>
        )}

        {value.serviceType === ServiceTypeEnum.Preventive && props?.pmView && (
          <>
            <Label id="createdAt" label="Due date" helper="Expected date for PM" />
            <DateTimeInput
              isWhite
              id="dueDateModule"
              value={value?.dueDate || dayjs().valueOf()}
              onChange={(dueDate) => onChange({ ...value, dueDate: dayjs(dueDate).valueOf() })}
            />
          </>
        )}

        {value.serviceType === ServiceTypeEnum.Preventive &&
          determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalScheduledDate) &&
          !props?.pmView && (
            <>
              <Label id="createdAt" label="Scheduled date" helper="Scheduled date of PM request" />
              <DateTimeInput
                isWhite
                id="scheduledDateModule"
                value={value?.scheduledDate || dayjs().valueOf()}
                onChange={(scheduledDate) => onChange({ ...value, scheduledDate: dayjs(scheduledDate).valueOf() })}
              />
            </>
          )}

        {value.serviceType === ServiceTypeEnum.Corrective &&
          determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalRecurrence) && (
            <RecurrenceOptionSelect
              recurrenceOption={value.recurrenceOption || RecurrenceOptionsType.Once}
              recurrenceInterval={value.recurrenceInterval || 1}
              onChange={(v) => onChange({ ...value, ...v })}
            />
          )}

        {value.serviceType !== ServiceTypeEnum.Corrective &&
          value.serviceType !== ServiceTypeEnum.Consumables &&
          determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalRecurrence) && (
            <RequestFormRecurrence
              recurrenceInterval={value.recurrenceInterval ?? undefined}
              onChange={(v) => onChange({ ...value, ...v })}
            />
          )}
      </div>

      <div className="mb-3">
        <TextField
          id="title"
          label="Title"
          fullWidth
          variant="outlined"
          placeholder={defaultTitle}
          value={value.title}
          onChange={(e) => onChange({ ...value, title: e.target.value })}
          minRows="3"
        />
      </div>

      <div className="prose my-4 max-w-none">
        <Label id="description" label="Description" required />
        <div className="my-2">
          <RichTextEditor defaultValue={description} onChange={setDescription} />
        </div>
        <FormHelperText id="description-helper-text">Example: "I tried to clear the unit, but it's still not working."</FormHelperText>
      </div>

      <div className="mb-8">
        <AttachmentDropzone
          data-testid="photoUpload"
          label="Attach Files"
          maxAttachments={50}
          acceptedFileTypes={['image/*', 'application/pdf']}
          errorMessage="The file could not be uploaded. Attachments must be images or PDFs under 5MB."
          onChange={(attachments) => onChange({ ...value, attachments })}
        />
      </div>

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalTag) && (
        <div className="mb-8">
          <ClientTagsSelect
            label={userCan(internalUsers) ? 'Client Tags' : 'Tags'}
            entityLabel={EntityLabelEnum.ServiceRequest}
            onChange={(clientTags) => onChange({ ...value, clientTags })}
            organizationId={props.organizationId ?? ''}
            fullWidth
          />
        </div>
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalChecklist) && (
        <div className="mb-8">
          <ExpansionPanel title="Add a Checklist">
            <ChecklistManager
              organizationId={props.organizationId}
              onSelect={(blueprint) => onChange({ ...value, checklistBlueprint: blueprint })}
            />
          </ExpansionPanel>
        </div>
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalExternalWorkerOrderNumber) && (
        <TextField
          className="mb-4"
          id="externalWorkOrderNo"
          label="External Work Order No."
          fullWidth
          variant="outlined"
          placeholder="WO# 1234567"
          value={value.externalWorkOrderNo}
          onChange={(e) => onChange({ ...value, externalWorkOrderNo: e.target.value })}
        />
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalPoAmount) && (
        <CurrencyInput
          id="poAmount"
          data-testid="poAmount"
          className="mb-4"
          label="PO Amount"
          fullWidth
          cents={value.poAmount}
          placeholder="$0.00"
          handleCents={(poAmount) => onChange({ ...value, poAmount })}
        />
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalBillable) && (
        <Checkbox
          inputTestId="billable"
          label="Mark this Service Request as billable"
          id="billable"
          checked={value.billable}
          onChange={(e) => onChange({ ...value, billable: e.target.checked })}
        />
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalMainContact) && (
        <>
          <Label id="mainContactSelect" label="Main contact" />
          <Input
            data-testid="mainContactSelect"
            id="mainContactSelect"
            className="mb-4 cursor-pointer md:max-w-sm"
            onClick={() => setUserPickerShowing(true)}
            value={`${defaultMainContactFullName || mainContactData.fullName} - ${toDisplayPhoneFromPhoneWithCountryCode(
              defaultMainContactPhoneNumber || mainContactData.phoneNumber,
            )}`}
            readOnly
          />
          <UserSelectDialog
            locationId={location?.locationId ?? undefined}
            organizationId={props.organizationId ?? ''}
            handleSelect={({ userId, fullName, user }) => {
              onChange({ ...value, mainContactUserId: userId });
              setMainContactData({ fullName, phoneNumber: user.metadata.phoneNumberWithCountryCode });
            }}
            open={userPickerShowing}
            handleClose={() => setUserPickerShowing(false)}
          />
        </>
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalAssignedInternalUser) && (
        <>
          <Label id="assignedInternalUserSelect" label="Assign Internal User" />
          <Input
            data-testid="assignedInternalUserSelect"
            id="assignedInternalUserSelect"
            className="mb-4 cursor-pointer md:max-w-sm "
            onClick={() => setAssignedInternalUserPickerShowing(true)}
            value={`${
              assignedInternalUserData?.fullName
                ? `${assignedInternalUserData.fullName} - ${toDisplayPhoneFromPhoneWithCountryCode(assignedInternalUserData.phoneNumber)}`
                : `No User is Selected`
            } `}
            readOnly
          />
          <UserSelectDialog
            locationId={location?.locationId ?? undefined}
            organizationId={props.organizationId ?? ''}
            handleSelect={({ userId, fullName, user }) => {
              onChange({ ...value, assignedInternalUserId: userId });
              setAssignedInternalUserData({ fullName, phoneNumber: user.metadata.phoneNumberWithCountryCode });
            }}
            open={assignedInternalUserPickerShowing}
            handleClose={() => setAssignedInternalUserPickerShowing(false)}
            showAllUsers={true}
          />
        </>
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalAdditionalContacts) && (
        <FlatAccordion title="Additional Contacts">
          <AdditionalContactsContainer
            locationId={location?.locationId ?? undefined}
            organizationId={props.organizationId}
            contacts={value.contacts}
            setContactString={(contacts) => onChange({ ...value, contacts })}
          />
        </FlatAccordion>
      )}

      {determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.SrModalRequestedProvider) && equipment?.length === 1 && (
        <TextField
          id="requestedProviderId"
          select
          label="Requested Provider"
          helperText={!equipment?.[0]?.metadata?.possibleProviders?.length && 'No providers have been configured for this equipment.'}
          value={value.requestedProviderId ?? ''}
          onChange={(e) => onChange({ ...value, requestedProviderId: e.target.value as string })}
          variant="outlined"
          className="w-full"
          disabled={!equipment?.[0]?.metadata?.possibleProviders?.length}
        >
          <MenuItem value="" disabled>
            Select Provider
          </MenuItem>
          {equipment?.[0]?.metadata?.possibleProviders?.map(
            (provider) =>
              provider && (
                <MenuItem key={provider?.metadata?.organizationId} value={provider?.metadata?.organizationId ?? undefined}>
                  {provider?.metadata?.organizationName}
                </MenuItem>
              ),
          )}
        </TextField>
      )}
      {userCan(internalUsers) && (
        <FlatAccordion title="Internal Options" defaultExpanded>
          <div className="flex flex-col gap-3">
            <Checkbox
              inputTestId="createAsClosed"
              label="Create with closed status"
              id="createAsClosed"
              checked={value.createAsClosed}
              onChange={(e) => onChange({ ...value, createAsClosed: e.target.checked })}
            />

            <TextField
              id="requestOrigination"
              select
              required
              label="Request Origin"
              value={value.requestOrigination}
              onChange={(event) => onChange({ ...value, requestOrigination: event.target.value as RequestOriginationType })}
              variant="outlined"
              className="w-full"
            >
              <MenuItem value="" disabled>
                Select Request Origin
              </MenuItem>
              <MenuItem value={RequestOriginationType.EquipsOnBehalfOfCustomer}>On behalf of customer</MenuItem>
              <MenuItem value={RequestOriginationType.EquipsDuringInvoicing}>During invoice entry</MenuItem>
            </TextField>

            <div className="mt-2">
              <Label id="createdAt" label="Date of Request" helper="Date of request if it took place in the past." />
              <DateInput
                id="createdAt"
                className="w-full"
                value={toDateStringFromUnixMillisecondTimestamp(value.createdAt ?? Date.now())}
                onChange={({ target: { value: date } }) => onChange({ ...value, createdAt: toUnixMillisecondsFromString(date) })}
              />
            </div>
          </div>
        </FlatAccordion>
      )}

      <div className="mt-3 flex flex-col items-center justify-between gap-3 pt-3 sm:flex-row">
        <div>
          {preventiveDisclaimer !== null && isOrganizationDispatchingExternally && (
            <Checkbox
              className="mt-1"
              label="I understand I could be charged for service on equipment not under coverage and/or beyond my allowed PM hours."
              id="preventiveDisclaimer"
              checked={preventiveDisclaimer}
              onChange={(e) => setPreventiveDisclaimer(e.target.checked)}
            />
          )}
        </div>
        <Button
          loading={props.isSaving}
          type="button"
          data-testid="submitRequest"
          disabled={
            props.isSaving ||
            !value.description ||
            (preventiveDisclaimer === false && isOrganizationDispatchingExternally) ||
            value.recurrenceInterval === undefined ||
            (userCan(internalUsers) && !value.requestOrigination)
          }
          onClick={props.onSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import FolderOpen from '@mui/icons-material/FolderOpen';
import { SvgIconComponent } from '@mui/icons-material';

type EmptyComponentProps = {
  text?: string;
  small?: boolean;
  className?: string;
  style?: React.CSSProperties;
  Icon?: SvgIconComponent;
};
export default function EmptyComponent({
  text = 'No data',
  small = false,
  className = '',
  style = {},
  Icon = FolderOpen,
}: EmptyComponentProps) {
  return (
    <div className={`mx-auto w-full p-8 ${className}`} style={style}>
      <div className="text-center text-gray-400">
        <Icon fontSize={small ? 'small' : 'large'} />
      </div>
      <div className={`text-gray-600 ${small ? 'text-sm' : 'text-base'} pt-2 text-center`}>{text}</div>
    </div>
  );
}

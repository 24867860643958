import React, { useState } from 'react';
import { EntityLabelEnum, NotesTagsEnum } from '@equips/entities-schema';
import Modal from '../Modal/Modal';
import NoteFormWithList from '../ViewPage/NoteListWithForm';
import Button from '../Buttons/Button';
import TableCellExpand from './TableCellExpand';

interface TableCellNotesProps {
  noteText?: string;
  entityId: string;
  parentLabel: EntityLabelEnum;
  noteQueryFunction(...args: any[]): any;
  tags?: NotesTagsEnum[];
}

export default function TableCellNotes({ noteText, entityId, parentLabel, noteQueryFunction, tags }: TableCellNotesProps) {
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal && (
        <Modal isOpen handleClose={() => setModal(false)}>
          <div className="p-4">
            <NoteFormWithList entityId={entityId} parentLabel={parentLabel} queryFunction={noteQueryFunction} tags={tags} />
          </div>
        </Modal>
      )}

      <div className="relative flex items-center justify-between">
        <TableCellExpand>{noteText}</TableCellExpand>
        <div>
          <Button className="-my-1 ml-1" tiny gray type="button" onClick={() => setModal(true)}>
            More
          </Button>
        </div>
      </div>
    </>
  );
}

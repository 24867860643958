import React, { useCallback } from 'react';
import { API } from 'aws-amplify';
import AsyncSelect from 'react-select/async';
import { useQuery } from '@tanstack/react-query';
import debouncePromise from 'awesome-debounce-promise';
import { AasFailGroupEnumType } from '@equips/entities-schema';
import { OrganizationSelectOption } from '../../../common/components/Selects/OrganizationsSelect';
import FormGroup from '../../../common/components/Form/FormGroup';
import { defaultSelectStyles } from '../../../common/components/Selects/defaultSelectStyles';

type AasProvidersByDistanceSelectProps = {
  locationId?: string | null;
  onChange?: (value) => any;
  fullWidth?: boolean;
  testId?: string;
  message?: string;
  className?: string;
  selectedFailGroups?: AasFailGroupEnumType[];
  defaultStyles?: boolean;
};

const AasProvidersByDistanceSelect = ({
  locationId,
  onChange,
  fullWidth = false,
  testId = 'test',
  message,
  className = '',
  selectedFailGroups,
  defaultStyles = true,
}: AasProvidersByDistanceSelectProps) => {
  const { data: defaultOptions } = useQuery(
    [`AmericanAutoShieldProviders`, locationId, selectedFailGroups],
    () =>
      API.get('equips-app', `/aas-providers`, {
        queryStringParameters: {
          locationId,
        },
        response: true,
      }),
    {
      enabled: !!locationId,
      select: (data) =>
        data?.data?.map((provider) => {
          const address = provider?.billingAddress ?? {
            line1: provider?.line1,
            zip: provider?.zip,
            city: provider?.city,
            stateUnabbreviated: provider?.stateUnabbreviated,
          };

          const typeOfWork = provider?.typeOfWork || [];

          return {
            value: provider?.organizationId,
            label: provider?.organizationName,
            address,
            provider: {
              isExternalProvider: true,
              distance: provider?.distance_in_miles,
              dispatchEmail: provider?.dispatchEmail,
              dispatchPhone: provider?.dispatchPhone,
              dispatchWebsite: provider?.dispatchWebsite,
              providerSource: provider?.providerSource,
              completedServiceRequestCount: provider?.completedServiceRequestCount,
              noteCount: provider?.noteCount,
              tradeMatch: typeOfWork.some((t) => selectedFailGroups?.includes(t)),
            },
          };
        }),
    },
  );

  const loadOptions = useCallback(
    async (search) => {
      if (!locationId) return [];

      const providers = (
        await API.get('equips-app', `/aas-providers`, {
          queryStringParameters: {
            locationId,
            search: search.toLowerCase(),
          },
          response: true,
        })
      )?.data;

      return providers?.map((provider) => {
        const typeOfWork = provider?.typeOfWork || [];
        return {
          value: provider?.organizationId,
          label: provider?.organizationName,
          address: { line1: provider?.line1, zip: provider?.zip, city: provider?.city, stateUnabbreviated: provider?.stateUnabbreviated },
          provider: {
            isExternalProvider: true,
            distance: provider?.distance_in_miles,
            dispatchEmail: provider?.dispatchEmail,
            dispatchPhone: provider?.dispatchPhone,
            dispatchWebsite: provider?.dispatchWebsite,
            providerSource: provider?.providerSource,
            completedServiceRequestCount: provider?._count?.ServiceRequest || 0,
            noteCount: provider?._count?.Note || 0,
            tradeMatch: typeOfWork.some((t) => selectedFailGroups?.includes(t)),
          },
        };
      });
    },
    [locationId, selectedFailGroups],
  );

  return (
    <FormGroup fullWidth={fullWidth} data-testid={testId}>
      <AsyncSelect
        className={className}
        key={locationId}
        components={{
          Option: OrganizationSelectOption,
          SingleValue: OrganizationSelectOption,
        }}
        styles={defaultStyles ? { ...defaultSelectStyles } : {}}
        cacheOptions
        isMulti={false}
        onChange={onChange}
        loadOptions={debouncePromise(loadOptions, 500)}
        placeholder="Select a provider"
        isClearable
        noOptionsMessage={() => message}
        defaultOptions={defaultOptions}
        menuPortalTarget={document.body}
      />
    </FormGroup>
  );
};

export default AasProvidersByDistanceSelect;

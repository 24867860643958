import { View, ViewScope } from '@equips/entities-schema';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react';
import { TableIdEnum, useNavData } from '../../providers/NavDataProvider';
import IconButton from '../Buttons/IconButton';
import { useTableViews } from '../DataTable/useTableViews';
import Spinner from '../Loaders/Spinner';
import { SimpleTab, SimpleTabs } from '../Tabs/SimpleTabs';
import { useAuth } from '../../auth/AuthContext';
import StyledSelect from './StyledSelect';

const equipsSelection = [
  { value: TableIdEnum.ServiceRequest, label: 'Service Request' },
  { value: TableIdEnum.Equipment, label: 'Equipment' },
];

const aasSelection = [
  { value: TableIdEnum.Claim, label: 'Claim' },
  { value: TableIdEnum.AasInvoice, label: 'Invoice' },
];

export default function SavedViewSelect({ navigate }: { navigate: (view: View) => void }) {
  const { isAasUser } = useAuth();
  const [tableId, setTableId] = useState(isAasUser ? TableIdEnum.Claim : TableIdEnum.ServiceRequest);
  const [selected, setSelected] = useState<View | null>(null);

  const { views, isFetching, scope, setScope } = useTableViews(tableId, { defaultScope: ViewScope.Organization });
  const { userId, updateFavoriteSavedView, favoriteSavedView, isFavoriteSavedViewMutationSaving } = useNavData();

  const totalCount = favoriteSavedView?.metadata?.count || 0;
  const badgeContent = totalCount > 99 ? '99+' : totalCount;
  const tooltipTitle = totalCount > 99 ? totalCount : '';

  const selection = isAasUser ? aasSelection : equipsSelection;

  return (
    <div className="mt-3 w-full">
      <h2 className="mb-1 text-lg font-bold tracking-wide text-blue-900">Quick Views</h2>
      <p className="text-sm">Quick views allow for a fast and easy way to quickly return to any saved view.</p>
      <h3 className="text-md mt-4 font-semibold text-blue-900">Current Favorite</h3>
      {favoriteSavedView ? (
        <Badge
          overlap="rectangular"
          className="my-3 block cursor-default"
          color="secondary"
          badgeContent={
            totalCount && (
              <Tooltip title={tooltipTitle}>
                <span>{badgeContent}</span>
              </Tooltip>
            )
          }
        >
          <div className="flex items-center justify-between truncate rounded-xl border border-gray-400 bg-gray-200 pr-3">
            <button
              type="button"
              className="w-full truncate py-3 pl-3 text-left leading-snug"
              onClick={() => favoriteSavedView && navigate(favoriteSavedView)}
            >
              <p className="truncate font-semibold">{favoriteSavedView?.metadata?.name}</p>
              <p title={favoriteSavedView.metadata?.description ?? ''} className="truncate text-xs">
                {favoriteSavedView.metadata?.description}
              </p>
            </button>
            <IconButton
              className="hover:bg-inherit font-light hover:text-red-700"
              text="Remove favorite"
              size="xs"
              Icon={Close}
              onClick={(e) => {
                e.stopPropagation();

                setSelected(null);
                userId && updateFavoriteSavedView({ userId, metadata: { favoriteSavedViewId: null } });
              }}
            />
          </div>
        </Badge>
      ) : (
        <p className="my-2 text-xs text-gray-500">No favorite saved view...</p>
      )}
      <SimpleTabs className="py-4" value={tableId} onChange={(_, newValue) => setTableId(newValue)}>
        {selection.map((item, index) => {
          return <SimpleTab key={`${item.value}__${index}`} value={item.value} label={item.label} />;
        })}
      </SimpleTabs>
      <ul className="px-2">
        <StyledSelect
          id="scopeSelect"
          data-testid="scopeSelect"
          value={scope}
          onChange={(event) => setScope(event.target.value as ViewScope)}
        >
          <option value={ViewScope.Creator}>Saved Views</option>
          <option value={ViewScope.Organization}>Shared Views</option>
        </StyledSelect>
        {isFetching ? (
          <div className="flex justify-center py-16">
            <Spinner></Spinner>
          </div>
        ) : !views || views.length < 1 ? (
          <div className="py-16 text-center text-gray-800">
            <p>No saved views found.</p>
            <p className="px-5 pt-5 text-sm text-gray-900">
              Views allow you to save the current table configuration (filters, columns, etc.) and restore it at a later time.
            </p>
          </div>
        ) : (
          views
            ?.filter((view) => view.viewId !== favoriteSavedView?.viewId)
            .map((view) => {
              const totalCount = view?.metadata?.count || 0;
              const badgeContent = totalCount > 99 ? '99+' : totalCount;
              const tooltipTitle = totalCount > 99 ? totalCount : '';

              return (
                <li key={view.viewId}>
                  <Badge
                    className="my-3 block cursor-default"
                    color="secondary"
                    overlap="rectangular"
                    badgeContent={
                      totalCount && (
                        <Tooltip title={tooltipTitle} arrow>
                          <span>{badgeContent}</span>
                        </Tooltip>
                      )
                    }
                  >
                    <div
                      className={`my-3 flex items-center justify-between truncate rounded-xl border pr-3 hover:border-gray-500 hover:bg-gray-200 ${
                        favoriteSavedView && favoriteSavedView?.viewId === view.viewId ? 'border-gray-400 bg-gray-200' : 'bg-gray-100'
                      }`}
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        className="w-full truncate pl-3 text-left leading-snug"
                        onClick={() => (!isFavoriteSavedViewMutationSaving ? view?.viewId && navigate(view) : null)}
                        onKeyDown={() => {}}
                      >
                        <div className="flex w-full justify-between py-3 pl-3 text-left leading-snug">
                          <div className="w-9/12">
                            <p className="truncate font-semibold">{view.metadata?.name}</p>
                            <p title={view.metadata?.description ?? ''} className="truncate text-xs">
                              {view.metadata?.description}
                            </p>
                          </div>
                          <div className="flex items-center">
                            {view?.viewId !== selected?.viewId && (
                              <IconButton
                                className="hover:bg-inherit font-light hover:text-red-700"
                                text="Set as favorite"
                                size="xs"
                                Icon={FavoriteBorder}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  if (!view) return;
                                  setSelected(view);
                                  userId && updateFavoriteSavedView({ userId, metadata: { favoriteSavedViewId: view?.viewId } });
                                }}
                              />
                            )}
                            {isFavoriteSavedViewMutationSaving && view?.viewId === selected?.viewId && (
                              <div className="text-xs font-light">saving...</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Badge>
                </li>
              );
            })
        )}
      </ul>
    </div>
  );
}

import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import StyledSelect from './StyledSelect';

type RecurrenceIntervalSelectProps = {
  value: string | number;
  id: string;
  label?: string;
  helper?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;
};

const RecurrenceIntervalSelect = ({ value, onChange, id, label = 'Recurrence Interval', helper }: RecurrenceIntervalSelectProps) => {
  const [recurrenceInterval, setRecurrenceInterval] = useState([undefined, 'all', 0, 90, 180, 360].includes(value) ? value : 'Custom');

  const options = [
    { label: 'Any', value: '' },
    { label: 'Recurring', value: 'all' },
    { label: 'Just Once', value: 0 },
    { label: 'Every 90 Days', value: 90 },
    { label: 'Every 180 Days', value: 180 },
    { label: 'Every 360 Days', value: 360 },
    { label: 'Custom', value: 'Custom' },
  ];

  return (
    <FormGroup fullWidth key={id}>
      <Label id={id} label={label} helper={helper} />
      <StyledSelect
        key={id}
        id={id}
        data-testid={id}
        value={recurrenceInterval}
        onChange={(event) => {
          setRecurrenceInterval(event.target.value);
          if (event.target.value !== 'Custom') {
            onChange(event);
          }
        }}
      >
        {options?.map(({ label, value }) => (
          <option value={value} key={label}>
            {label}
          </option>
        ))}
      </StyledSelect>
      {recurrenceInterval === 'Custom' && (
        <div className="mb-1 flex items-center gap-2">
          Every
          <TextField
            value={value}
            onChange={onChange}
            id="customRecurrenceValue"
            InputProps={{
              inputProps: { type: 'number', min: 0 },
            }}
            variant="outlined"
            size="small"
            margin="dense"
          />
          Days
        </div>
      )}
    </FormGroup>
  );
};

export default RecurrenceIntervalSelect;

export default {
  AAS_ARMIS: '/armis',
  AAS_ARMIS_CLAIM_FORM: '/armis/claims/create',
  AAS_ARMIS_PORTAL: '/armis/portal',
  AAS_ARMIS_STATUS: '/armis/status/search',
  AAS_CLAIMS: '/aas/claims',
  AAS_CONTRACTS: '/aas/contracts',
  AAS_CONTRACTS_DATA: '/aas/contracts-data',
  AAS_IMPORT: '/aas/import',
  AAS_INVOICES: '/u/invoices', // invoice migration
  AAS_FORM: '/aas',
  AAS_ORGANIZATIONS: '/aas/organizations',
  AAS_PAYABLES: '/aas/payables',
  AAS_PAYABLES_CREATE: '/aas/payables/create',
  AAS_CHARGES: '/aas/charges',
  AGREEMENTS: '/agreements',
  AGREEMENTS_BULK_EDIT: '/agreements-bulk',
  ALERTS: '/alerts',
  CATEGORIES_DEFAULT: '/default-categories',
  CATEGORIES: '/categories',
  CHARGES: '/charges',
  CLAIMS: '/claims',
  CLAIM_FORM: '/claims/create',
  CLAIM_EMAIL_VERIFICATION_FORM: '/claims/email-verification',
  CLAIM_STATUS: '/claims/status/search',
  CONFIRM_SIGNUP: '/confirm-signup',
  CONSUMABLES: '/consumables',
  COVERAGE_UPLOAD_PREVIEW: '/coverage/upload-preview',
  COVERAGE_UPLOAD: '/coverage/upload',
  COVERAGE: '/coverage',
  CUSTOMER_INVOICES: '/invoices',
  DEV_INCIDENTS: '/dev-incidents',
  DOCUMENTS: '/documents',
  EMAIL_AUTH_CONFIRM: '/access/confirm',
  EMAIL_AUTH_REQUEST: '/access/request',
  ENTITY_CHANGES: '/entity-changes',
  EQUIPMENT_CREATE_LEGACY: '/equipment/create/legacy',
  EQUIPMENT_CREATE: '/equipment/create',
  EQUIPMENT_QR: '/qr/equipment',
  EQUIPMENT_REQUEST_NEW_PURCHASE: '/equipment/request-purchase',
  EQUIPMENT: '/equipment',
  EQUIPMENT_PM: '/pm/equipment',
  FEEDBACK_ADD: '/feedback/create',
  FEEDBACK: '/feedback',
  HOME: '/',
  // home warranty
  HOME_WARRANTY_CHARGES: '/hw/charges',
  HOME_WARRANTY_INVOICES: '/u/invoices',
  HOME_WARRANTY_ORGANIZATIONS: '/hw/organizations',
  HOME_WARRANTY_PAYABLES: '/hw/payables',
  HOME_WARRANTY_PAYABLES_CREATE: '/hw/payables/create',
  //
  INVENTORY: '/inventory',
  IHT_INVOICES: '/service-invoices/iht',
  IMPORT_RESULTS: '/import/results',
  IMPORT: '/import',
  INACTIVE: '/inactive',
  INCIDENTS: '/incidents',
  INVOICES_UPLOAD: '/service-invoices/upload',
  INVOICES: '/service-invoices',
  LOCATION_QR: '/qr/locations',
  LOCATIONS: '/locations',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MANAGED_SERVICES: '/managed-services',
  NOTE_ATTACHMENT: '/note-attachment',
  NOTIFICATIONS: '/notifications',
  ONBOARDING: '/onboarding',
  ORGANIZATIONS: '/organizations',
  PARTS: '/parts',
  PASSWORD_RESET: '/password-reset',
  PAYMENTS: '/payments',
  PIZZA_STATUS: '/pizza/status/search',
  PIZZA: '/pizza',
  PLAYGROUND: '/playground',
  PROVIDER_INVOICES: '/provider-invoices',
  QUERY_ELASTICSEARCH_AND_ACT_ON_DATA: '/query-es-and-act',
  QUERY_GRAPHQL: '/query-graphql',
  REPORTING_AGREEMENT_CHANGE_REPORT: '/reporting/agreement-change-report',
  REPORTING_ALLOCATION_REPORT: '/reporting/allocation-report',
  REPORTING_BY_PROVIDER: '/reporting/by-provider',
  REPORTING_CANCEL_SEVERITY: '/reporting/cancel-severity',
  REPORTING_CHRONIC: '/reporting/chronic-report',
  REPORTING_COVERAGE_AND_SUBSCRIPTION_REPORT: '/reporting/coverage-and-subscriptions',
  REPORTING_DEDUCTIBLE_REPORT: '/reporting/deductible-report',
  REPORTING_EQUIPMENT_MATRIX_SUMMARY: '/reporting/equipment-matrix-summary',
  REPORTING_HANDLE_TIME: '/reporting/handle-time',
  REPORTING_LOSS_RATIO_CLAIMS: '/reporting/loss-ratio-claims',
  REPORTING_LOSS_RATIO_ITEMS: '/reporting/loss-ratio-items',
  REPORTING_ONSITE_CHECKIN: '/reporting/onsite-checkin',
  REPORTING_PROCESSING_TIME: '/reporting/processing-time',
  REPORTING_PROGRAM_FEES: '/reporting/program-fee-report',
  REPORTING_REQUESTS_BY_ACCOUNT: '/reporting/requests-by-account',
  REPORTING_SERVICE_REQUEST_ORIGINATION: '/reporting/service-request-origination',
  REPORTING_SERVICE_REQUEST_TO_INVOICE: '/reporting/service-request-to-invoice',
  REPORTING: '/reporting',
  SCHEDULE: '/schedule',
  SERVICE_CONTRACTS: '/service-contracts',
  SERVICE_CONTRACTS_CREATE: '/service-contracts/create',
  SERVICE_REQUEST_DISPATCH: '/dispatch',
  SERVICE_REQUEST_FORM: '/service-requests/create',
  SERVICE_REQUESTS: '/service-requests',
  SETTINGS_HOMEPAGE: '/settings/homepage',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_ORGANIZATION: '/settings/organizations',
  SETTINGS_AUDIT_LOG: '/settings/audit-log',
  SETTINGS: '/settings',
  SIGNUP: '/signup',
  SPECS: '/specs',
  SQUADS: '/squads',
  SUBSCRIPTIONS: '/subscriptions',
  TESTS: '/tests',
  TIMESTAMP_START_OF_DAY_EST: '/timestamp-start-of-day-est',
  TRACKER: '/tracker',
  TRACKER_STATUS: '/tracker/status/search',
  UNCONFIRMED_USERS: '/users/unconfirmed',
  UNIQUE_DEVICE_IDENTIFICATION: '/unique-device-identification',
  UPGRADE: '/upgrade',
  USERS_PROPOSED: '/users/proposed',
  USERS: '/users',
};

import React from 'react';
import PropTypes from 'prop-types';
import CheckboxMUI from '@mui/material/Checkbox';
import Label from './Label';

export default function Checkbox({
  disabled = false,
  size,
  helper,
  className = '',
  label,
  labelClassName = '',
  required,
  checkboxesAriaLabel,
  checked,
  onChange,
  id,
  inputTestId,
  name,
}) {
  return (
    <div className={`flex items-center ${className}`}>
      <CheckboxMUI
        disabled={disabled}
        style={{ width: 'auto', padding: size === 'small' ? '0' : '' }}
        className={className}
        size={size}
        checked={checked}
        aria-label={checkboxesAriaLabel}
        required={required}
        id={id}
        onChange={onChange}
        color="secondary"
        inputProps={{ 'data-testid': inputTestId || id }}
        name={name}
      />
      {label && <Label size={size} className={`ml-1 inline-block ${labelClassName}`} id={id} label={label} helper={helper} />}
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  onChange: PropTypes.func.isRequired,
  helper: PropTypes.any,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  checkboxesAriaLabel: PropTypes.string,
  inputTestId: PropTypes.string,
  name: PropTypes.string,
};
Checkbox.defaultProps = {
  className: '',
  size: 'medium',
  checked: false,
  helper: null,
  required: false,
  label: '',
  checkboxesAriaLabel: '',
  inputTestId: '',
  name: '',
};

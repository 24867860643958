import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { notEmpty } from '@equips/common-resources';
import { EntityLabelEnum } from '@equips/entities-schema';
import debouncePromise from 'awesome-debounce-promise';
import { useQuery } from '@tanstack/react-query';
import AsyncSelect from 'react-select/async';
import { components, MultiValueGenericProps, OptionProps } from 'react-select';
import Alert from '@mui/material/Alert';
import { getTagsForSelect } from '../../../graphql/queries/tagGraphQLQueries';
import { useAuth } from '../../auth/AuthContext';
import createRandomString from '../../functions/createRandomString';
import { useAlert, AlertTypes } from '../Alerts/AlertContext';
import FormGroup from '../Form/FormGroup';
import InputHelper from '../Form/InputHelper';
import InputLoader from '../Form/InputLoader';
import Label from '../Form/Label';
import ClientTag from '../Tags/ClientTag';
import { TagSelectOptionType, formatTagForReactSelect } from './ClientTagsSelect';
import ReactSelectRequiredInput from './ReactSelectRequiredInput';
import { defaultSelectStyles } from './defaultSelectStyles';

interface ClientTagsMultSelectProps {
  value?: null | string[];
  setValue: (value: TagSelectOptionType[] | null) => void;
  fullWidth?: boolean;
  isClearable?: boolean;
  label?: string;
  loading?: boolean;
  required?: boolean;
  organizationId?: string;
  entityLabel: EntityLabelEnum;
  testId?: string;
  helper?: string;
  labelHelper?: string;
  placeholder?: string;
  id: string;
  styles?: any;
  isTableFilter?: boolean;
  prependedOptions?: TagSelectOptionType[];
}

function ClientTagsMultiSelect({
  value,
  setValue,
  fullWidth = false,
  isClearable = false,
  label = 'Tags',
  loading = false,
  required = false,
  organizationId = '',
  entityLabel,
  testId = '',
  helper = '',
  labelHelper = '',
  placeholder = 'Search tags...',
  id,
  styles = {},
  isTableFilter = false,
  prependedOptions = [],
}: ClientTagsMultSelectProps) {
  const [selected, setSelected] = useState(value ?? ([] as { value: string; label: string }[]));
  const { auth } = useAuth();
  const showAlert = useAlert();

  let organizationIdForQuery = auth?.organizationId || '';
  if (organizationId.length > 0) {
    organizationIdForQuery = organizationId;
  }

  const {
    data: defaultOptions,
    error,
    isFetching,
  } = useQuery(
    ['getTagsForSelect', entityLabel, organizationIdForQuery],
    () =>
      getTagsForSelect({
        search: '',
        entityLabel,
        organizationId: organizationIdForQuery,
      }),
    {
      enabled: !!organizationIdForQuery || !!entityLabel,
      select: (data) => {
        const options = data?.data?.tag?.data || [];
        return options.map(formatTagForReactSelect).filter(notEmpty);
      },
      onError: (data: Error) => {
        showAlert({
          type: AlertTypes.error,
          content: `${data.message}`,
        });
      },
    },
  );

  useEffect(() => {
    // this is for table filters to work since we are only receiving values on filter
    if (value?.length && defaultOptions?.length && isTableFilter) {
      setSelected([...prependedOptions, ...defaultOptions]?.filter((option) => value.includes(option.value)));
    }
  }, [defaultOptions, prependedOptions, isTableFilter, value]);

  const tagIdSelect = useMemo(() => id || `TagIdSelect${createRandomString(5)}`, [id]);

  const concurrentCaller = useCallback(async (search) => {
    try {
      const { data } = await getTagsForSelect({
        search,
        entityLabel,
        organizationId: organizationIdForQuery,
      });

      return (data?.tag?.data || []).map(formatTagForReactSelect).filter(notEmpty);
    } catch (error) {
      showAlert({
        type: AlertTypes.error,
        content: `${error.message}`,
      });
    }
    return [];
  }, []);

  if (loading || isFetching) return <InputLoader />;

  const Option = (props: OptionProps<TagSelectOptionType>) => {
    const data = props?.data || {};
    return (
      <components.Option {...props} className="cursor-pointer">
        <ClientTag label={data?.label} value={data?.value} style={data?.style} />
      </components.Option>
    );
  };

  const MultiValue = (props: MultiValueGenericProps<TagSelectOptionType>) => {
    const data = props?.data || {};
    return <ClientTag label={data?.label} value={data?.value} style={data?.style} onDelete={props?.removeProps?.onClick} />;
  };

  return (
    <>
      {!error ? (
        <FormGroup fullWidth={fullWidth} data-testid={testId} disabled={error}>
          <Label id={tagIdSelect} label={label} required={required} helper={labelHelper} />
          <div className="relative">
            <AsyncSelect
              components={{ MultiValue, Option }}
              isMulti
              isClearable={isClearable}
              defaultOptions={[...prependedOptions, ...(defaultOptions || [])]}
              value={selected}
              inputId={tagIdSelect}
              noOptionsMessage={() => null}
              classNamePrefix="equips"
              placeholder={placeholder}
              loadOptions={debouncePromise(concurrentCaller, 500)}
              onChange={(selections) => {
                setSelected(selections);
                setValue(selections?.length > 0 ? selections : null);
              }}
              menuPortalTarget={document.body}
              styles={{ ...defaultSelectStyles, ...styles }}
            />
            <input
              readOnly
              className="hidden"
              hidden
              tabIndex={-1}
              value={value ? value?.join(', ') : 'empty'}
              data-testid={`${testId}InputValue`}
            />
            {helper && <InputHelper>{helper}</InputHelper>}
            {required && <ReactSelectRequiredInput isValid={(value?.length || 0) > 0} />}
          </div>
        </FormGroup>
      ) : (
        <Alert severity="error" itemType="warning">
          {error.message}
        </Alert>
      )}
    </>
  );
}

export default ClientTagsMultiSelect;

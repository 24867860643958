import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import {
  ChecklistBlueprint,
  deleteChecklistBlueprint,
  patchChecklistBlueprint,
  postChecklist,
  postChecklistBlueprint,
  queryChecklistBlueprints,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import Dialog from '@mui/material/Dialog';
import Button from '../Buttons/Button';
import IconButton from '../Buttons/IconButton';
import waitSynchronously from '../../functions/waitSynchronously';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { useAuth } from '../../auth/AuthContext';
import SearchInput from '../Form/SearchInput';
import ChecklistEditor from './ChecklistEditor';

type ChecklistManagerProps = {
  serviceRequestId?: string;
  organizationId?: string;
  onSelect?: (blueprint: ChecklistBlueprint) => void;
};

export default function ChecklistManager({ serviceRequestId, organizationId, onSelect }: ChecklistManagerProps) {
  const { auth } = useAuth();
  const showAlert = useAlert();
  const [search, setSearch] = useState<string>();
  const [editorModalOpen, setEditorModalOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState<ChecklistBlueprint>();
  const [createNewChecklistFromViewServiceRequest] = useState(serviceRequestId ? true : false);

  const organizationIdToQuery = organizationId || auth?.organizationId;

  const { data, isFetching, refetch } = useQuery(
    ['queryChecklistBlueprints', organizationId, search],
    () =>
      queryChecklistBlueprints(
        {
          search: {
            q: search,
            filters: [{ metadata: { organizationId: [{ keyword: [{ term: organizationIdToQuery }] }] } }],
            sortBy: [{ metadata: { name: { keyword: SortByElasticSearchOptions.Desc } } }],
          },
        },
        {
          query: `
          checklistBlueprintId
          metadata {
            checklistBlueprintId
            name
            steps {
              label
              type
              options
            }
          }`,
        },
      ),
    { enabled: !!organizationIdToQuery },
  );

  const { mutate: saveBlueprint, isLoading: isSaving } = useMutation(
    async (checklist: ChecklistBlueprint) => {
      const { metadata } = checklist;
      const { checklistBlueprintId, name, steps } = metadata || {};

      if (checklistBlueprintId) {
        await patchChecklistBlueprint({
          checklistBlueprintId,
          metadata: {
            name: name || undefined,
            steps,
            organizationId,
          },
        });
      } else {
        if (createNewChecklistFromViewServiceRequest) {
          await postChecklist({
            metadata: {
              name: name || undefined,
              steps,
              serviceRequestId,
            },
          });
        } else {
          await postChecklistBlueprint({
            metadata: {
              name: name || undefined,
              steps,
              organizationId,
            },
          });
        }
      }
    },
    {
      onSuccess: async () => {
        if (onSelect && createNewChecklistFromViewServiceRequest) {
          onSelect({ checklistBlueprintId: null });
          return;
        }

        setEditorModalOpen(false);
        await waitSynchronously(1000);
        refetch();
      },
      onError: () => showAlert({ type: AlertTypes.error, content: 'An error occurred while saving the checklist.  Please try again.' }),
    },
  );

  const { mutate: deleteBlueprint, isLoading: isDeleting } = useMutation(
    async (entityId: string | null | undefined) => {
      if (entityId) await deleteChecklistBlueprint({ entityId });
    },
    {
      onSuccess: async () => {
        await waitSynchronously(1000);
        refetch();
      },
      onError: () => showAlert({ type: AlertTypes.error, content: 'An error occurred while deleting the checklist.  Please try again.' }),
    },
  );

  const checklists = data?.data?.checklistBlueprint?.data?.filter((checklist): checklist is ChecklistBlueprint => !!checklist) || [];

  const handleEdit = (checklist?: ChecklistBlueprint) => {
    setSelectedChecklist(checklist);
    setEditorModalOpen(true);
  };

  const disabled = isSaving || isDeleting;

  return (
    <div className="w-full" data-testid="ChecklistManager">
      {isFetching ? (
        <Skeleton variant="rectangular" height={300} />
      ) : (
        <div>
          <div className="flex justify-between gap-4">
            <SearchInput query={search} onSearch={setSearch} />
            <Button
              className="whitespace-nowrap"
              blue
              tiny
              onClick={() => {
                setSelectedChecklist(undefined);
                setEditorModalOpen(true);
              }}
            >
              New Checklist
            </Button>
          </div>
          <div className="my-4 rounded-xl border p-4">
            {checklists.length < 1 && (
              <div>
                <p className="text-sm text-gray-700">
                  {`No checklists found. Use the "New Checklist" button to create one${search ? ' or try a different search' : ''}.`}
                </p>
              </div>
            )}
            {checklists.map((checklist, index) => (
              <div
                key={checklist.checklistBlueprintId}
                className={`my-2 flex items-center justify-between gap-4 border p-2 text-sm ${
                  selectedChecklist === checklist
                    ? 'border-equipsLightBlue bg-blue-50 font-semibold text-equipsLightBlue'
                    : 'border-gray-300 text-gray-700'
                } leading-none rounded${onSelect ? ' hover:border-equipsLightBlue hover:bg-blue-50' : ''}`}
                role="button"
                tabIndex={index}
                onKeyPress={() => {}}
                onClick={() => {
                  if (onSelect) {
                    setSelectedChecklist(checklist);
                    onSelect(checklist);
                  }
                }}
              >
                <div>{checklist.metadata?.name}</div>
                <div className="flex gap-4">
                  <IconButton
                    text="Edit"
                    type="button"
                    Icon={Edit}
                    disabled={disabled}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEdit(checklist);
                    }}
                    size="large"
                  />
                  <IconButton
                    text="Delete"
                    type="button"
                    Icon={Close}
                    disabled={disabled}
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteBlueprint(checklist.checklistBlueprintId);
                    }}
                    size="large"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Dialog open={editorModalOpen} onClose={() => setEditorModalOpen(false)} maxWidth="xl" fullWidth>
        <ChecklistEditor checklist={selectedChecklist} onSave={saveBlueprint} disabled={disabled} />
      </Dialog>
    </div>
  );
}

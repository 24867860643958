import React from 'react';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Settings from '@mui/icons-material/Settings';
import Notifications from '@mui/icons-material/Notifications';
import Feedback from '@mui/icons-material/Feedback';
import TrackChanges from '@mui/icons-material/TrackChanges';
import Category from '@mui/icons-material/Category';
import DeviceHub from '@mui/icons-material/DeviceHub';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Help from '@mui/icons-material/Help';
import Policy from '@mui/icons-material/Policy';
import Email from '@mui/icons-material/Email';
import { UserAuthorizationRoleEnum } from '@equips/entities-schema';
import Avatar from '@mui/material/Avatar';
import LogRocket from 'logrocket';
import Urls from '../../../routes/Urls';
import DropDown from '../DropDown';
import { useAuth } from '../../auth/AuthContext';
import { abbreviateName } from '../../functions/abbreviateName';
import { useAlert } from '../Alerts/AlertContext';
import { isAprilFools, rockMode } from '../../../screens/settings/user/aprilfoolz/haha';

type Props = {
  icon: any;
  text: string;
  color?: string;
};

function DropDownLink({ icon: Icon, text, color }: Props) {
  return (
    <span data-testid={`dropDownLink${text}`}>
      <Icon fontSize="small" className={`mr-2 ${color ? color : 'text-gray-500'}`} /> {text}
    </span>
  );
}

export function UserMenu() {
  const { auth, userCan } = useAuth();
  const alert = useAlert();

  const src = isAprilFools() ? rockMode() : auth?.profilePicture?.presignedGetUrl;

  return (
    <DropDown
      containerClasses="inline-block"
      actions={[
        {
          Component: <DropDownLink icon={Notifications} text="Notifications" />,
          to: Urls.SETTINGS_NOTIFICATIONS,
        },
        {
          Component: <DropDownLink icon={Settings} text="Settings" />,
          to: Urls.SETTINGS,
        },
        ...(auth?.stikkyDataRedirectUrlToken
          ? [
              { border: true },
              {
                Component: <DropDownLink icon={AccountBalance} text="Stikkydata" />,
                href: `https://equips.stikkydata.com/equipslogin.aspx?req=${auth?.stikkyDataRedirectUrlToken}`,
              },
              { border: true },
            ]
          : []),
        {
          Component: <DropDownLink icon={Feedback} text="Feedback" />,
          to: Urls.FEEDBACK_ADD,
        },
        ...(userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin, UserAuthorizationRoleEnum.GlobalAdmin])
          ? [
              { border: true },

              { Component: <DropDownLink icon={Category} text="Category Overview" />, to: Urls.CATEGORIES },
              { Component: <DropDownLink icon={TrackChanges} text="Entity Changes" />, to: Urls.ENTITY_CHANGES },
              {
                Component: <DropDownLink icon={Help} text="Copy Session URL" />,
                action: () => {
                  LogRocket.getSessionURL((v) => window.navigator.clipboard.writeText(v));
                  alert({ content: 'LogRocket session link copied.' });
                },
              },
            ]
          : []),
        ...(userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin])
          ? [{ Component: <DropDownLink icon={DeviceHub} text="Query GraphQL" />, to: Urls.QUERY_GRAPHQL }]
          : []),
        { border: true },
        {
          Component: <DropDownLink icon={Policy} text="Privacy Policy" />,
          href: 'https://www.equips.com/privacy-policy/',
        },
        {
          Component: <DropDownLink icon={Email} text="Contact Us" />,
          href: 'https://www.equips.com/helpcenter/',
        },
        { border: true },
        {
          Component: <DropDownLink icon={PowerSettingsNew} text="Signout" />,
          to: Urls.LOGOUT,
        },
      ]}
    >
      <button type="button">
        <Avatar src={src} alt={auth?.fullName ?? 'Unknown User'} className="h-10 w-10 bg-blue-500">
          {abbreviateName(auth?.fullName)}
        </Avatar>
      </button>
    </DropDown>
  );
}

import { SortByElasticSearchOptions, ViewScope, queryViews } from '@equips/entities-schema';

const viewFields = `
  viewId
  metadata {
    viewId
    count,
    description,
    name,
    organizationId
    scope,
    search,
    tableId,
    tableState,
    visibleColumns,
  }
`;

export const getViewsByTableId = ({ tableId, userId, organizationId }) => {
  if (!tableId) throw new Error('Missing data in "getViewsByTableId"');

  return queryViews(
    {
      search: {
        filters: [
          {
            metadata: {
              tableId: [{ keyword: [{ term: tableId }] }],
              createdByUserId: [{ keyword: [{ term: userId }] }],
              scope: [{ keyword: [{ term: ViewScope.Creator }] }],
            },
          },
          {
            metadata: {
              tableId: [{ keyword: [{ term: tableId }] }],
              organizationId: [{ keyword: [{ term: organizationId }] }],
              scope: [{ keyword: [{ term: ViewScope.Organization }] }],
            },
          },
        ],
        sortBy: [{ metadata: { name: { keyword: SortByElasticSearchOptions.Asc } } }],
      },
    },
    {
      query: `
        ${viewFields}
      `,
    },
  );
};

import React from 'react';
import states from '../../constants/states';
import FormGroup from '../Form/FormGroup';
import Label from '../Form/Label';
import StyledSelect from './StyledSelect';

interface StateSelectorProps {
  value: string;
  label: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
  loading?: boolean;
  labelHelper?: string;
  fullWidth?: boolean;
  discardFormGroup?: boolean;
  required?: boolean;
}

export default function StateSelector({
  value,
  label,
  id,
  onChange,
  name,
  loading,
  labelHelper = '',
  fullWidth,
  discardFormGroup,
  required = false,
}: StateSelectorProps) {
  function InputInners() {
    return (
      <>
        <Label label={label} id={id} helper={labelHelper} />
        <StyledSelect id={id} name={name} value={value} loading={loading} required={required} onChange={(e) => onChange(e)}>
          <option value="">-- Select State --</option>
          {states.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </StyledSelect>
      </>
    );
  }

  if (discardFormGroup) return <InputInners />;

  return (
    <FormGroup data-testid="state-selector" fullWidth={fullWidth}>
      {InputInners()}
    </FormGroup>
  );
}

import { Maybe, FloatSearchFilterInputType } from '@equips/entities-schema';
import React, { useMemo } from 'react';
import CurrencyInput from './CurrencyInput';

const separator = '#';

const extractCustomDateFromString = (value: string) => {
  const [fromString = '', toString = ''] = value.split(separator);

  return { from: parseInt(fromString), to: parseInt(toString) };
};

function toDailyPriceInDollars(price: number): number {
  return price / 365 / 100;
}

export function transformCurrencyFilterAnnualizedCentsIntoDailyPrice(valueInCents = ''): Maybe<FloatSearchFilterInputType[]> | undefined {
  if (!valueInCents) return;

  const { from, to } = extractCustomDateFromString(valueInCents);

  if (!from && !to) return;

  return [
    {
      range: {
        gte: from ? toDailyPriceInDollars(from) : undefined,
        lte: to ? toDailyPriceInDollars(to) : undefined,
      },
    },
  ];
}

export function transformCurrencyFilterIntoIntGraphQLQuery(value = ''): Maybe<any[]> | undefined {
  if (!value) return;
  const { from, to } = extractCustomDateFromString(value);

  if (!from && !to) return;

  return [
    {
      range: {
        gte: from ? from : undefined,
        lte: to ? to : undefined,
      },
    },
  ];
}

export default function CurrencyRangeFilter({ value, setValue, id, label }) {
  const { from, to } = useMemo(() => extractCustomDateFromString(value || ''), [value]);

  return (
    <div className="flex space-x-1">
      <div className="w-1/2">
        <CurrencyInput
          label={`From ${label}`}
          fullWidth
          data-testid={`${id}From`}
          tiny
          id={`${id}FromCurrency`}
          cents={from}
          placeholder="$0.00"
          handleCents={(value) => {
            const otherComponent = to ? `${separator}${to}` : '';
            setValue(`${value}${otherComponent}`);
          }}
        />
      </div>
      <div className="w-1/2">
        <CurrencyInput
          label={`To ${label}`}
          fullWidth
          tiny
          data-testid={`${id}To`}
          id={`${id}ToCurrency`}
          cents={to}
          placeholder="$0.00"
          handleCents={(value) => {
            setValue(`${from || ''}${separator}${value}`);
          }}
        />
      </div>
    </div>
  );
}

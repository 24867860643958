import React from 'react';
import { Link } from 'react-router-dom';
import { EntityLabelEnum, NotesTagsEnum, OrganizationTagsEnum, patchServiceRequest, ServiceRequest } from '@equips/entities-schema';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { JsonParam } from 'use-query-params';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import Urls from '../../../routes/Urls';
import { toDateStringFromUnixMillisecondTimestamp, toUnixMillisecondsFromString } from '../../../common/functions/dateFunctions';
import {
  getEnumDisplayName,
  getEnumValue,
  allowedNoteParentLabels,
  enumObjectToSelectOptions,
  EnumObjectType,
  aasClaimStatuses,
  aasProcessingStatuses,
  aasFailGroups,
} from '../../../graphql/enums';
import TableCellNotes from '../../../common/components/DataTable/TableCellNotes';
import { getServiceRequestInternalNoteList } from '../../../graphql/queries/serviceRequestGraphQLQueries';
import DisplayTime from '../../../common/components/Date/DisplayTime';
import { TableFilterTypes } from '../../../common/components/DataTable/TableFilters';
import { standardNoteSerializerAndDeserializer } from '../../../common/functions/standardSerializersAndDeserializers';
import { providerValueForFilter } from '../../../graphql/queries/organizationGraphQLQueries';
import { TableColumn } from '../../../common/components/DataTable';
import { defaultColumnWidths } from '../../../common/components/DataTable/columns';
import { TableCellStacked } from '../../../common/components/DataTable/TableCellStacked';
import { TableCellExpandDangerouslySetInnerHTML } from '../../../common/components/DataTable/TableCellExpandDangerouslySetInnerHTML';
import { invoiceStatuses, invoiceStatusesForAAS } from '../../invoices/invoiceStatuses';
import { centsToMoney } from '../../../common/functions/moneyFunctions';
import TableCellCopyData from '../../../common/components/DataTable/TableCellCopyData';
import { abbreviateName } from '../../../common/functions/abbreviateName';
import AddressColumn from '../../../common/components/Address/AddressColumn';
import { stripHtml } from '../../../common/functions/stripHtml';
import { dateRangeOptions } from '../../../common/components/Date/DateRangeFilter';
import TableCellInlineEditable from '../../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../../common/components/ViewPage/DisplayDescriptionValue';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../../common/functions/phoneFunctions';
import TableCellAasFailCodes from '../components/TableCellAasFailCodes';
import { AasFailGroupToTextMap } from '../components/AasFailCodesSelect';
import ClientTag from '../../../common/components/Tags/ClientTag';
import VipIndicator from '../components/VipIndicator';

export function getAasClaimsColumns(serviceRequestSelectEnum: EnumObjectType<string>): TableColumn<ServiceRequest>[] {
  return [
    {
      id: 'metadata.shortId',
      Header: 'Number',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => {
        return (
          <>
            <Link
              data-testid="serviceRequestViewLink"
              className="active font-bold"
              to={`${Urls.AAS_CLAIMS}/${data.row.original?.metadata?.serviceRequestId}`}
            >
              {data.value}
            </Link>
          </>
        );
      },
      toText: (data) => data.value,
    },
    {
      Header: 'Contract',
      id: 'metadata.aasContractId',
      accessor: (data) => data?.metadata?.aasContract?.metadata?.locationId,
      Cell: (data) => (
        <Link className="active font-bold" to={`${Urls.AAS_CONTRACTS}/${data.value}`} target="_blank" rel="noopener noreferrer">
          {data.row?.original?.metadata?.aasContract?.metadata?.homeMainContractNo}{' '}
          {data.row?.original?.metadata?.aasContract?.metadata?.vip && <VipIndicator />}
        </Link>
      ),
      disableSortBy: true,
    },
    {
      id: 'metadata.aasContract.metadata.location.address.line1',
      Header: 'Address',
      accessor: (data) => data?.metadata?.aasContract?.metadata?.location?.address?.line1,
      width: defaultColumnWidths.mediumText,
      disableSortBy: true,
      Cell: (d) => <AddressColumn addressObject={d.row.original?.metadata?.aasContract?.metadata?.location?.address} />,
      toText: (d) => formatAddressAsOneLine(d.row.original?.metadata?.aasContract?.metadata?.location?.address),
    },
    {
      id: 'metadata.aasContract.metadata.location.address.city',
      Header: 'City',
      accessor: (data) => data?.metadata?.aasContract?.metadata?.location?.address?.city,
      disableSortBy: true,
    },
    {
      id: 'metadata.aasContract.metadata.location.address.state',
      Header: 'State',
      accessor: (data) => data?.metadata?.aasContract?.metadata?.location?.address?.stateUnabbreviated,
      disableSortBy: true,
    },
    {
      id: 'metadata.aasContract.metadata.location.address.zip',
      Header: 'Zip',
      accessor: (data) => data?.metadata?.aasContract?.metadata?.location?.address?.zip,
      width: defaultColumnWidths.extraSmall,
      disableSortBy: true,
    },
    {
      id: 'metadata.aasContract.metadata.location.metadata.contacts.fullName',
      Header: 'Contract Holder Name',
      disableSortBy: true,
      accessor: (data) => data?.metadata?.aasContract?.metadata?.location?.metadata?.contacts?.[0]?.fullName,
    },
    {
      id: 'metadata.aasContract.metadata.location.metadata.contacts.phone',
      Header: 'Phone',
      disableSortBy: true,
      accessor: (data) =>
        toDisplayPhoneFromPhoneWithCountryCode(data?.metadata?.aasContract?.metadata?.location?.metadata?.contacts?.[0]?.phone),
    },
    {
      id: 'metadata.description',
      Header: 'Description',
      accessor: (data) => data.metadata?.description,
      width: defaultColumnWidths.largeText,
      toText: (d) => stripHtml(d?.value),
      Cell: (d) => <TableCellExpandDangerouslySetInnerHTML data-testid="viewAllOfDescription" html={d.value} />,
    },
    {
      id: 'metadata.aasClaimStatus',
      Header: 'Claim Status',
      accessor: (data) => data.metadata?.aasClaimStatus,
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(aasClaimStatuses),
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => {
            return capitalize(value);
          }}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(aasClaimStatuses)}
          onSave={(value) =>
            patchServiceRequest({ serviceRequestId: data.row?.original?.metadata?.serviceRequestId, metadata: { aasClaimStatus: value } })
          }
        />
      ),
    },
    {
      id: 'metadata.aasProcessingStatus',
      Header: 'Processing Status',
      accessor: (data) => data.metadata?.aasProcessingStatus,
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: [{ value: 'none', label: 'None' }, ...enumObjectToSelectOptions(aasProcessingStatuses)],
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (
        <TableCellInlineEditable
          {...data}
          formatter={(value) => {
            return capitalize(value);
          }}
          inputType={descriptionListInputTypes.select}
          options={enumObjectToSelectOptions(aasProcessingStatuses)}
          onSave={(value) =>
            patchServiceRequest({
              serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
              metadata: { aasProcessingStatus: value },
            })
          }
        />
      ),
    },
    {
      id: 'metadata.aasPaymentStatus',
      Header: 'Payment Status',
      accessor: (data) => data.metadata?.aasPaymentStatus,
      width: defaultColumnWidths.normalText,
    },
    {
      id: 'metadata.assignedInternalUserId',
      Header: 'Assignee',
      accessor: (data) => {
        return data?.metadata?.assignedInternalUser?.metadata?.fullName;
      },
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            fieldToPatch="assignedInternalUserId"
            inputType={descriptionListInputTypes.inlineUserSelect}
            formatter={(_value) => {
              const fullName = data.row?.original?.metadata?.assignedInternalUser?.metadata?.fullName;
              const url = data.row?.original?.metadata?.assignedInternalUser?.metadata?.profilePictureUrl;
              if (fullName) {
                return (
                  <div className="flex items-center gap-2">
                    <Avatar src={url ?? ''} alt={fullName ?? 'Unknown User'} className="h-8 w-8 bg-blue-700">
                      {abbreviateName(fullName)}
                    </Avatar>
                    {fullName}
                  </div>
                );
              } else {
                return <p>Unassigned</p>;
              }
            }}
            onSave={async (_value) => {
              data.refetchData();
            }}
            entityLabel={EntityLabelEnum.ServiceRequest}
            entityId={data?.row?.original?.metadata?.serviceRequestId}
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.userSelect,
        showOnMobile: true,
      },
    },
    {
      id: 'aasFailCodeMetadata.aasFailCodeId',
      Header: 'Failure Group',
      accessor: (data) => data?.metadata?.aasFailCodes,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) =>
        data?.metadata?.aasFailCodes?.length ? data?.metadata?.aasFailCodes?.map((failCode) => failCode?.metadata?.code).join(', ') : 'N/A',
      Cell: (data) => <TableCellAasFailCodes failCodes={data.row.original?.metadata?.aasFailCodes} />,
      filterOptions: {
        type: TableFilterTypes.aasFailCodesMultiSelect,
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
    },
    {
      id: 'metadata.aasFailCategories',
      Header: 'Failure Category',
      accessor: (data) => data?.metadata?.aasFailCategories,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      Cell: (data) => {
        const failCategories = data.row.original?.metadata?.aasFailCategories || [];
        if (!failCategories.length) return 'N/A';
        return failCategories.map((failCategory) => AasFailGroupToTextMap[failCategory]).join(', ');
      },
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        options: enumObjectToSelectOptions(aasFailGroups),
        defaultValue: [],
        filterType: JsonParam,
        showOnMobile: true,
      },
    },
    {
      id: 'metadata.requestedProviderId',
      Header: 'Provider',
      accessor: (data) => data.metadata?.requestedProvider?.metadata?.organizationName,
      width: defaultColumnWidths.normalText,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.organizationSelect,
        typeOfOrganizationToFind: providerValueForFilter,
        allowNullNotNull: true,
        organizationTags: [OrganizationTagsEnum.Aas],
      },
      Cell: (data) => (
        <Link
          target="_blank"
          className="text-xs"
          rel="noopener noreferrer"
          to={`${Urls.AAS_ORGANIZATIONS}/${data.row.original?.metadata?.requestedProviderId}`}
        >
          {data?.value}
        </Link>
      ),
    },
    {
      id: 'metadata.checklist.metadata.completedPercentage',
      Header: 'Checklist',
      width: defaultColumnWidths.extraSmall,
      accessor: (data) => {
        return data?.metadata?.checklist?.metadata?.completedPercentage;
      },
      toText: (data) => (data.row.original?.metadata?.checklist?.metadata?.checklistId ? `${Math.round(data.value)}%` : ''),
      Cell: (data) => (data.row.original?.metadata?.checklist?.metadata?.checklistId ? `${Math.round(data.value)}%` : ''),
      disableSortBy: true,
    },
    {
      id: 'metadata.externalWorkOrderNo',
      Header: 'External WO #',
      accessor: (data) => data.metadata?.externalWorkOrderNo,
      width: defaultColumnWidths.normalText,
    },
    {
      id: 'invoiceMetadata.shortId',
      Header: 'Invoice #',
      accessor: (data) => data.invoiceMetadata?.shortId,
      disableSortBy: true,
      Cell: (data) => (
        <div className="flex justify-between">
          <Link to={`${Urls.AAS_INVOICES}/${data?.row?.original?.invoiceMetadata?.invoiceId}`}>{data.value}</Link>
        </div>
      ),
    },
    {
      id: 'invoiceMetadata.invoiceStatus',
      Header: 'Invoice Status',
      accessor: (data) => data.invoiceMetadata?.invoiceStatus,
      filterOptions: {
        label: 'Invoice Status',
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(invoiceStatusesForAAS, { anyText: 'Any' }),
      },
      toText: (data) => getEnumDisplayName(data.value, invoiceStatuses),
      Cell: (data) => getEnumDisplayName(data.value, invoiceStatuses),
    },
    {
      id: 'invoiceMetadata.invoiceTotal',
      Header: 'Invoice Total',
      accessor: (data) => data.invoiceMetadata?.invoiceTotal,
      Cell: (data) => centsToMoney(data?.row?.original?.invoiceMetadata?.invoiceTotal),
    },
    {
      id: 'tagMetadata.tagId',
      Header: 'Tags',
      accessor: (data) => data.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.ServiceRequest,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.ServiceRequest}
            label=""
            onSave={(selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];

              patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
                metadata: { clientTags },
              });
            }}
          />
        );
      },
    },
    {
      id: 'notes',
      Header: 'Notes',
      accessor: (data) => data.notes,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (d) => {
        return standardNoteSerializerAndDeserializer.serializer(d.row.original?.notes || []);
      },
      Cell: (d) => (
        <TableCellNotes
          entityId={d.row.original?.metadata?.serviceRequestId || ''}
          parentLabel={getEnumValue(allowedNoteParentLabels.serviceRequest)}
          noteQueryFunction={getServiceRequestInternalNoteList}
          noteText={d.row.original?.notes?.[0]?.metadata?.message}
          tags={[NotesTagsEnum.InternalNote]}
        />
      ),
    },
    {
      id: 'metadata.completedAt',
      Header: 'Completed',
      accessor: (data) => data.metadata?.completedAt,
      width: defaultColumnWidths.smallText,
      toText: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.completedAt, {
          customFormatString: 'M/D/YY h:mma',
        });
        const user = data.row.original?.metadata?.completedByUser?.metadata?.fullName ?? '';
        return `${time} ${user}`;
      },
      Cell: (data) => {
        const completedBy = data.row.original?.metadata?.completedByUser?.metadata?.fullName;
        return <TableCellStacked line1={<DisplayTime timestamp={data.value} />} line2={completedBy} />;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.closedAt',
      Header: 'Closed At',
      accessor: (data) => data.metadata?.closedAt,
      width: defaultColumnWidths.smallText,
      toText: (data) => {
        const time = toDateStringFromUnixMillisecondTimestamp(data.row.original?.metadata?.closedAt, {
          customFormatString: 'M/D/YY h:mma',
        });
        const user = data.row.original?.metadata?.closedByUser?.metadata?.fullName ?? '';
        return `${time} ${user}`;
      },
      Cell: (data) => {
        const closedBy = data.row.original?.metadata?.closedByUser?.metadata?.fullName;
        return <TableCellStacked line1={<DisplayTime timestamp={data.value} />} line2={closedBy} />;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'closed',
      Header: 'Closed',
      accessor: (data) => data.metadata?.closedAt,
      Cell: (data) => {
        return <> {data.row.original?.metadata?.closedAt ? 'Closed' : 'Not Closed'} </>;
      },
      filterOptions: {
        label: 'Closed',
        type: TableFilterTypes.select,
        options: [
          { label: 'All', value: 'all' },
          { label: 'Not Closed', value: 'notClosed' },
          { label: 'Closed', value: 'closed' },
        ],
      },
    },
    {
      id: 'invoiceMetadata.quoteFileId',
      Header: 'Estimate File',
      accessor: (data) => data.invoiceMetadata?.quoteFileId,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has estimate file', value: 'true' },
          { label: 'No estimate file', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (data.value ? 'Yes' : 'No'),
    },
    {
      id: 'invoiceMetadata.fileId',
      Header: 'Invoice File',
      accessor: (data) => data.invoiceMetadata?.fileId,
      disableSortBy: true,
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has invoice file', value: 'true' },
          { label: 'No invoice file', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
      Cell: (data) => (data.value ? 'Yes' : 'No'),
    },
    {
      id: 'metadata.scheduledDate',
      Header: 'Scheduled date',
      accessor: (data) => data.metadata?.scheduledDate,
      toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value}
            formatter={(value) => {
              const dateTimeString =
                toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }) || '';
              const displayValue = value ? (value !== dateTimeString ? dayjs(value)?.format('MM/DD/YYYY hh:mma') : dateTimeString) : '';

              return <>{`${displayValue} ${displayValue ? data?.row?.original?.metadata?.mainContactUser?.metadata?.fullName : ''}`}</>;
            }}
            inputType={descriptionListInputTypes.datetime}
            onSave={async (value) => {
              const timestamp = toUnixMillisecondsFromString(value);

              await patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
                metadata: { scheduledDate: timestamp },
              });

              data.refetchData();
            }}
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
        options: [
          dateRangeOptions.today,
          dateRangeOptions.yesterday,
          dateRangeOptions.last7,
          dateRangeOptions.last30,
          dateRangeOptions.last90,
          dateRangeOptions.none,
          dateRangeOptions.notEmpty,
          dateRangeOptions.custom,
        ],
      },
    },
    {
      id: 'metadata.failureDate',
      Header: 'Failure date',
      accessor: (data) => data.metadata?.failureDate,
      width: defaultColumnWidths.smallText,
      toText: (data) => {
        return toDateStringFromUnixMillisecondTimestamp(data?.value);
      },
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={toDateStringFromUnixMillisecondTimestamp(data?.value) ?? 'None'}
            formatter={(value) => (value ? dayjs(value).format('MM/DD/YYYY') : '')}
            inputType={descriptionListInputTypes.date}
            dateValidation="past"
            onSave={(value) => {
              patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
                metadata: { failureDate: toUnixMillisecondsFromString(value) },
              });
            }}
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'metadata.clientFollowUpDate',
      Header: 'Follow-up date',
      accessor: (data) => data.metadata?.clientFollowUpDate,
      width: defaultColumnWidths.mediumText,
      toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }),
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value}
            formatter={(value) => {
              const dateTimeString =
                toDateStringFromUnixMillisecondTimestamp(data?.value, { customFormatString: 'MM/DD/YYYY hh:mma' }) || 'None';

              return <>{value ? (value !== dateTimeString ? dayjs(value)?.format('MM/DD/YYYY hh:mma') : dateTimeString) : ''}</>;
            }}
            inputType={descriptionListInputTypes.datetime}
            onSave={async (value) => {
              const timestamp = toUnixMillisecondsFromString(value);

              await patchServiceRequest({
                serviceRequestId: data.row?.original?.metadata?.serviceRequestId,
                metadata: { clientFollowUpDate: timestamp },
              });

              data.refetchData();
            }}
          />
        );
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      id: 'serviceRequestId',
      accessor: (data) => data.metadata?.serviceRequestId,
      Header: 'Service Request ID',
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      toText: (data) => data.value || '',
      Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
    },
  ];
}

import React, { useState, lazy } from 'react';
import Search from '@mui/icons-material/Search';
import KeyboardEventHandler from '@infinium/react-keyboard-event-handler';
import Tooltip from '@mui/material/Tooltip';

const SearchBar = lazy(() => import('./SearchBar'));

export default function OmniSearch() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div data-testid="omniSearch" className="search-form flex items-center">
      {isOpen ? (
        <>
          <SearchBar closeSearchBar={() => setIsOpen(false)} />
          <KeyboardEventHandler
            handleKeys={['esc']}
            onKeyEvent={() => {
              setIsOpen(false);
            }}
          />
        </>
      ) : (
        <>
          <KeyboardEventHandler
            handleKeys={['/']}
            onKeyEvent={(key, event) => {
              event.preventDefault();
              setIsOpen(true);
            }}
          />
          <Tooltip title={`Click or press "/" to search`}>
            <button
              className="cursor-pointer rounded-xl px-3 py-1 text-lg text-white hover:bg-gray-900"
              onClick={(event) => {
                event.preventDefault();
                setIsOpen(true);
              }}
              type="button"
              data-testid="omniSearchButtonTrigger"
            >
              <Search />
            </button>
          </Tooltip>
        </>
      )}
    </div>
  );
}

import { EntityLabelEnum, postToTagsEndpoint, queryTags, TagFiltersInput } from '@equips/entities-schema';
import { defaultSearchParamsForAsyncSelects } from '../defaultSearchParamsForAsyncSelects';
import { gql } from '../graphqlHelperFunctions';

const getTagById = async ({ tagId }) =>
  postToTagsEndpoint({
    query: gql`
      query ($tagId: String) {
        tag(tagId: $tagId) {
          data {
            metadata {
              tagId
              entityLabel
              name
              style
              organizationId
            }
          }
        }
      }
    `,
    variables: { tagId },
  });

const getTagsBySearch = async (searchQuery) =>
  postToTagsEndpoint({
    query: gql`
      query ($search: TagSearchInput) {
        tag(search: $search) {
          data {
            metadata {
              tagId
              entityLabel
              name
              style
              organizationId
            }
          }
        }
      }
    `,
    variables: { search: { q: searchQuery } },
  });

const getTagsForSelect = async ({
  search = '',
  entityLabel,
  organizationId,
}: {
  search?: string;
  entityLabel: EntityLabelEnum | EntityLabelEnum[];
  organizationId: string | string[];
}) => {
  if (!entityLabel || !organizationId) {
    throw new Error('Unable to load tags for select');
  }

  const filters = [
    {
      metadata: {
        entityLabel: [{ keyword: [{ terms: entityLabel }] }],
        ...(organizationId
          ? {
              organizationId: [
                {
                  keyword: Array.isArray(organizationId) ? [{ terms: organizationId }] : [{ term: organizationId }],
                },
              ],
            }
          : {}),
      },
    },
  ] as TagFiltersInput[];

  return queryTags(
    {
      search: {
        ...defaultSearchParamsForAsyncSelects(search, { keyToSortBy: 'name' }),
        filters,
      },
    },
    {
      query: `
        tagId
        metadata {
          tagId
          entityLabel
          name
          style
          organizationId
        }
      `,
    },
  );
};

export { getTagById, getTagsBySearch, getTagsForSelect };

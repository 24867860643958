import React from 'react';
import { formatAddressAsOneLine } from '@equips/common-resources';
import { Link } from 'react-router-dom';
import { CoverageStatusType, EntityLabelEnum, Equipment, OrganizationFeatureFlagEnum, patchEquipment } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { Avatar } from '@mui/material';
import { arrayToGrammaticalList } from '../../common/functions/arrayToGrammaticalList';
import Urls from '../../routes/Urls';
import { AuthContextValue, AuthorizationData } from '../../common/auth/AuthContext';
import AddressColumn from '../../common/components/Address/AddressColumn';
import { getEquipmentNotes } from '../../graphql/queries/equipmentGraphQLQueries';
import { rolesThatCanViewCoverageData } from '../coverages/rolesThatCanViewCoverageData';
import statesAsObject from '../../common/constants/statesAsObject';
import { toESTDateStringFromUnixMillisecondTimestamp } from '../../common/functions/expensiveDateFunctions';
import {
  allowedNoteParentLabels,
  clientOrganizationTypes,
  enumObjectToSelectOptions,
  equipmentStatusType,
  equipmentHealthType,
  getEnumDisplayName,
  getEnumValue,
  equipsCoveragePmCadenceType,
  monthsToPmEquipmentType,
  organizationIndustryTypes,
} from '../../graphql/enums';
import { centsToMoney, convertDailyPriceToAnnualized } from '../../common/functions/moneyFunctions';
import NestedArrayPopover from '../../common/components/NestedArrayPopover';
import { TableColumn } from '../../common/components/DataTable';
import { clientValueForFilter, providerValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import {
  standardEntityOptionsSerializerAndDeserializer,
  standardNoteSerializerAndDeserializer,
} from '../../common/functions/standardSerializersAndDeserializers';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import TableCellNotes from '../../common/components/DataTable/TableCellNotes';
import TableCellCopyData from '../../common/components/DataTable/TableCellCopyData';
import { customers, internalUsers } from '../../common/auth/roles';
import { defaultColumnWidths } from '../../common/components/DataTable/columns';
import { dateRangeOptions } from '../../common/components/Date/DateRangeFilter';
import TableCellExpand from '../../common/components/DataTable/TableCellExpand';
import TableCellInlineEditable from '../../common/components/DataTable/TableCellInlineEditable';
import { descriptionListInputTypes } from '../../common/components/ViewPage/DisplayDescriptionValue';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';
import ClientTag from '../../common/components/Tags/ClientTag';
import TierIndicator from '../organizations/components/TierIndicator';
import { abbreviateName } from '../../common/functions/abbreviateName';
import { toDisplayPhoneFromPhoneWithCountryCode } from '../../common/functions/phoneFunctions';
import EquipmentCoverageCell, { equipmentCoverageText } from './components/EquipmentCoverageCell';
import { agreementFilterTypeOptions } from './equipmentScreenQuery';
import { EquipmentRowActions } from './components/EquipmentRowActions';

export function getEquipmentColumns(
  userCan: AuthContextValue['userCan'],
  auth: AuthorizationData | null | undefined,
  determineFeatureFlagVisibility: AuthContextValue['determineFeatureFlagVisibility'],
): TableColumn<Equipment>[] {
  return [
    {
      Header: 'Location',
      id: 'metadata.locationId',
      accessor: (data) => data.locationMetadata?.locationDisplayName,
      filterOptions: {
        type: TableFilterTypes.locationSelect,
        hidden: false,
        showOnMobile: true,
      },
    },
    {
      Header: 'Location address',
      id: 'locationAddress.line1',
      accessor: (data) => data.locationAddress?.line1,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => formatAddressAsOneLine(data.row.original?.locationAddress),
      Cell: (data) => <AddressColumn addressObject={data.row.original?.locationAddress} />,
    },
    {
      Header: 'City',
      id: 'locationAddress.city',
      accessor: (data) => data.locationAddress?.city,
      disableSortBy: true,
    },
    {
      Header: 'State',
      id: 'locationAddress.stateUnabbreviated',
      accessor: (data) => data.locationAddress?.stateUnabbreviated,
      disableSortBy: true,
      Cell: (data) => (data?.value && statesAsObject[data?.value]) || 'NA',
    },
    {
      Header: 'Zip',
      id: 'locationAddress.zip',
      accessor: (data) => data.locationAddress?.zip,
      disableSortBy: true,
    },
    {
      id: 'metadata.mainContactUserId',
      Header: 'Main contact',
      accessor: (data) => {
        return data?.metadata?.mainContactUser?.metadata?.fullName;
      },
      Cell: (data) => {
        const fullName = data.row?.original?.metadata?.mainContactUser?.metadata?.fullName;
        const url = data.row?.original?.metadata?.mainContactUser?.metadata?.profilePictureUrl;
        if (fullName) {
          return (
            <div className="flex items-center gap-2">
              <Avatar src={url ?? ''} alt={fullName ?? 'Unknown User'} className="h-8 w-8 bg-blue-700">
                {abbreviateName(fullName)}
              </Avatar>
              {fullName}
            </div>
          );
        } else {
          return <p>Unassigned</p>;
        }
      },
    },
    {
      id: 'metadata.mainContact.phoneNumberWithCountryCode',
      Header: 'Main contact phone',
      disableSortBy: true,
      accessor: (data) => toDisplayPhoneFromPhoneWithCountryCode(data?.metadata?.mainContactUser?.metadata?.phoneNumberWithCountryCode),
    },
    {
      id: 'metadata.mainContact.email',
      Header: 'Main contact email',
      disableSortBy: true,
      accessor: (data) => data?.metadata?.mainContactUser?.metadata?.email,
    },
    {
      Header: 'Number',
      id: 'metadata.shortId',
      accessor: (data) => data.metadata?.shortId,
      Cell: (data) => (
        <div className="flex justify-between">
          <Link
            className="active font-bold"
            data-testid="equipmentEditButton"
            to={`${Urls.EQUIPMENT}/${data.row.original.metadata?.equipmentId}`}
          >
            {data?.value}
          </Link>
          <EquipmentRowActions {...data}></EquipmentRowActions>
        </div>
      ),
      toText: (data) => data.value,
      filterOptions: {
        type: TableFilterTypes.text,
      },
    },
    {
      Header: 'Name',
      id: 'metadata.equipmentName',
      accessor: (data) => data.metadata?.equipmentName,
    },
    {
      Header: 'Category',
      id: 'specMetadata.categoryId',
      accessor: (data) => data.specMetadata?.categoryId,
      Cell: (data) => data.row.original.specMetadata?.category?.metadata?.name,
      filterOptions: {
        type: TableFilterTypes.categorySelect,
        filterType: JsonParam,
        showOnMobile: true,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: 'Subcategory',
      id: 'metadata.subclasses',
      accessor: (data) => data.metadata?.subclasses,
      disableSortBy: true,
      Cell: (data) => arrayToGrammaticalList(data?.value),
      filterOptions: {
        type: TableFilterTypes.subcategorySelect,
        defaultValue: [],
        filterType: JsonParam,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: 'Manufacturer',
      id: 'specMetadata.manufacturer',
      accessor: (data) => data.specMetadata?.manufacturer,
      width: defaultColumnWidths.normalText,
    },
    {
      Header: 'Equipment Status',
      id: 'metadata.equipmentStatus',
      accessor: (data) => data.metadata?.equipmentStatus,
      Cell: (d) => getEnumDisplayName(d?.value, equipmentStatusType) || '',
      toText: (d) => getEnumDisplayName(d?.value, equipmentStatusType) || '',
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(equipmentStatusType, { anyText: 'Any' }),
      },
    },
    {
      Header: 'Equipment Health',
      id: 'metadata.equipmentHealth',
      accessor: (data) => data.metadata?.equipmentHealth,
      Cell: (d) => getEnumDisplayName(d?.value, equipmentHealthType) || '',
      toText: (d) => getEnumDisplayName(d?.value, equipmentHealthType) || '',
      filterOptions: {
        type: TableFilterTypes.select,
        options: enumObjectToSelectOptions(equipmentHealthType, { anyText: 'Any', allowNull: true }),
      },
    },
    {
      Header: 'Open SRs',
      id: 'metadata.openServiceRequestCount',
      accessor: (data) => data.metadata?.openServiceRequestCount,
      disableSortBy: true,
      filterOptions: {
        label: 'Open Service Requests',
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Has Open Service Requests', value: 'true' },
          { label: 'No Open Service Requests', value: 'false' },
        ],
      },
    },
    {
      Header: userCan(internalUsers) ? 'Client Tags' : 'Tags',
      id: 'tagMetadata.tagId',
      accessor: (data) => data.metadata?.clientTags,
      disableSortBy: true,
      width: defaultColumnWidths.largeText,
      filterOptions: {
        type: TableFilterTypes.clientTagsMultiSelect,
        prependedOptions: [{ label: 'None', value: 'none' }],
        defaultValue: [],
        filterType: JsonParam,
        entityLabel: EntityLabelEnum.Equipment,
      },
      toText: (data) => data?.value?.map((t) => t.name)?.join(',') || '',
      Cell: (data) => {
        return (
          <TableCellInlineEditable
            {...data}
            value={data?.value?.map((item) => ({ value: item?.tagId, label: item?.name, style: item?.style }))}
            formatter={(value) => {
              return (
                <div className="inline-block overflow-x-auto">
                  {value?.map(({ value, label, style }) => <ClientTag key={value} label={label} style={style} value={value} />) || ''}
                </div>
              );
            }}
            inputType={descriptionListInputTypes.clientTagsMultiSelect}
            entityLabel={EntityLabelEnum.Equipment}
            label=""
            onSave={async (selections) => {
              const clientTags = selections ? selections?.map((selection) => ({ tagId: selection?.value })) : [];
              await patchEquipment({
                equipmentId: data.row?.original?.metadata?.equipmentId,
                metadata: { clientTags },
              });
              data.refetchData();
            }}
          />
        );
      },
    },
    {
      Header: 'Model',
      id: 'specMetadata.model',
      accessor: (data) => data.specMetadata?.model,
      width: defaultColumnWidths.normalText,
      Cell: (data) => (
        <div className="flex justify-between">
          {userCan(internalUsers) ? (
            <Link to={`${Urls.SPECS}/${data.row.original.metadata?.specId}`}>{data?.value}</Link>
          ) : (
            <>{data?.value}</>
          )}
        </div>
      ),
      toText: (data) => data.value,
    },
    {
      Header: 'Serial number',
      id: 'metadata.serialNumber',
      accessor: (data) => data.metadata?.serialNumber,
    },
    {
      Header: 'External ID',
      id: 'metadata.externalId',
      accessor: (data) => data.metadata?.externalId,
    },
    {
      Header: 'Service end date',
      id: 'metadata.serviceEndDate',
      accessor: (data) => data.metadata?.serviceEndDate,
      Cell: (d) => toESTDateStringFromUnixMillisecondTimestamp(d?.value),
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: 'PM Compliance',
      id: 'metadata.pmCompliance',
      accessor: (data) => data?.metadata?.pmCompliance,
      Cell: (d) => d?.value || '',
      disableSortBy: true,
    },
    {
      Header: 'Useful life end date',
      id: 'metadata.usefulLifeEndDate',
      accessor: (data) => {
        const usefulLifeEndDate = data.metadata?.usefulLifeEndDate;
        return (typeof usefulLifeEndDate === 'number' && dayjs(usefulLifeEndDate).format('MM/DD/YYYY')) || null;
      },
      filterOptions: {
        type: TableFilterTypes.dateRange,
        options: [dateRangeOptions.custom],
      },
    },
    {
      Header: 'Manufacturer End of life',
      id: 'specMetadata.manufacturerEOL',
      accessor: (data) => data.specMetadata?.manufacturerEOL,
      Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: 'Vendor Limited Support',
      id: 'specMetadata.vendorLimitedSupportDate',
      accessor: (data) => data.specMetadata?.vendorLimitedSupportDate,
      Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      width: defaultColumnWidths.normalText,
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
    },
    {
      Header: 'Location No.',
      id: 'locationMetadata.shortId',
      accessor: (data) => data.locationMetadata?.shortId,
    },
    {
      Header: 'Parent Location',
      id: 'metadata.location.metadata.parentLocation.metadata.locationDisplayName',
      accessor: (data) => data?.metadata?.location?.metadata?.parentLocation?.metadata?.locationDisplayName,
    },
    {
      Header: 'Installation date',
      id: 'metadata.installationDate',
      accessor: (data) => data.metadata?.installationDate,
      Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
      width: defaultColumnWidths.normalText,
    },
    {
      Header: 'Warranty On Date',
      id: 'metadata.warrantyOnDate',
      accessor: (data) => data.metadata?.warrantyOnDate,
      Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
      filterOptions: {
        type: TableFilterTypes.dateRange,
      },
      width: defaultColumnWidths.normalText,
    },
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldsWarranty)
      ? [
          {
            Header: 'Warranty Off date',
            id: 'metadata.warrantyOffDate',
            accessor: (data) => data.metadata?.warrantyOffDate,
            Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.value),
            filterOptions: {
              type: TableFilterTypes.dateRange,
            },
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Warranty Info',
            id: 'metadata.warrantyInfo',
            accessor: (data) => data.metadata?.warrantyInfo,
            width: defaultColumnWidths.largeText,
            Cell: (data) => <TableCellExpand data-testid="viewAllOfTitle">{data?.value}</TableCellExpand>,
            disableSortBy: true,
          },
        ]
      : []),
    ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentFieldCostCenter)
      ? [
          {
            Header: 'Cost Center',
            id: 'metadata.costCenter',
            accessor: (data) => data.metadata?.costCenter,
            width: defaultColumnWidths.normalText,
            Cell: (data) => data?.value,
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.text,
            },
          },
        ]
      : []),
    {
      Header: 'TID',
      id: 'metadata.tid',
      accessor: (data) => data.metadata?.tid,
      width: defaultColumnWidths.normalText,
    },
    {
      id: 'metadata.assetPrice',
      Header: 'Asset Price',
      accessor: (data) => data.metadata?.assetPrice,
      Cell: (data) => centsToMoney(data?.row?.original?.metadata?.assetPrice),
    },
    {
      id: 'metadata.serviceTotal',
      Header: 'Service Total',
      accessor: (data) => data.metadata?.serviceTotal,
      Cell: (data) => centsToMoney(data?.row?.original?.metadata?.serviceTotal),
    },
    {
      Header: 'Options',
      disableSortBy: true,
      id: 'options',
      accessor: (data) => data.options,
      toText: (d) => standardEntityOptionsSerializerAndDeserializer.serializer(d.row.original?.options || {}),
      Cell: (d) => standardEntityOptionsSerializerAndDeserializer.serializer(d.row.original?.options || {}),
      width: defaultColumnWidths.largeText,
    },
    ...(!userCan(customers)
      ? [
          {
            id: 'referredByOrg',
            filterOptions: {
              label: 'Referred by My Organization',
              type: TableFilterTypes.select,
              options: [
                { label: 'Any', value: '' },
                { label: 'Yes', value: 'true' + auth?.organizationId },
                { label: 'No', value: 'false' + auth?.organizationId },
              ],
            },
          },
        ]
      : []),
    ...(userCan(internalUsers)
      ? [
          {
            Header: 'Organization',
            id: 'metadata.organizationId',
            accessor: (data) => data.organizationMetadata?.organizationName,
            Cell: (data) => (
              <>
                {data?.value} <TierIndicator tier={data?.row?.original?.organizationMetadata?.tier} />
              </>
            ),
            width: defaultColumnWidths.normalText,
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
              label: 'Client',
              typeOfOrganizationToFind: clientValueForFilter,
            },
          },
          {
            Header: 'Client Organization Type',
            id: 'organizationMetadata.clientOrganizationType',
            accessor: (data) => data.organizationMetadata?.clientOrganizationType,
            Cell: (d) => getEnumDisplayName(d?.value, clientOrganizationTypes) || '',
            toText: (d) => getEnumDisplayName(d?.value, clientOrganizationTypes) || '',
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(clientOrganizationTypes, { anyText: 'Any' }),
            },
          },
          {
            Header: 'Industry',
            id: 'organizationMetadata.industry',
            accessor: (data) => data.organizationMetadata?.industry,
            Cell: (d) => getEnumDisplayName(d?.value, organizationIndustryTypes) || '',
            toText: (d) => getEnumDisplayName(d?.value, organizationIndustryTypes) || '',
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(organizationIndustryTypes, { anyText: 'Any' }),
            },
          },
          {
            Header: '',
            id: 'agreementFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.agreementSelect,
              label: 'Agreement',
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.organizationId']),
              labelHelper:
                'Select an agreement to see equipment that were on that agreement. This overrides any organization that maybe selected to the organization on the agreement.',
            },
          },
          {
            Header: '',
            id: 'coverageFilter',
            disableSortBy: true,
            filterOptions: {
              type: TableFilterTypes.select,
              label: 'Agreement Filter Type',
              showFilterOptionWhen: (filterMap) => Boolean(filterMap['metadata.contractId']),
              labelHelper:
                'Select if you want to see all active equipment at the time of the agreement, or equipment with coverage on the agreement',
              options: [
                { label: 'All Equipment On Agreement', value: agreementFilterTypeOptions.allEquipment },
                { label: 'Equipment With Coverage', value: agreementFilterTypeOptions.equipmentWithCoverage },
              ],
            },
          },
          {
            Header: 'On date',
            id: 'coveragesMetadata.onDate',
            accessor: (data) => data.effectiveCoverage?.metadata?.onDate,

            filterOptions: {
              type: TableFilterTypes.dateRange,
              label: 'On date',
            },
            toText: (d) => toESTDateStringFromUnixMillisecondTimestamp(d.row.original?.effectiveCoverage?.metadata?.onDate),
            Cell: (d) => (
              <NestedArrayPopover
                title="View dates"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(d) => toESTDateStringFromUnixMillisecondTimestamp(d?.onDate)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Off date',
            id: 'coveragesMetadata.offDate',
            accessor: (data) => data.effectiveCoverage?.metadata?.offDate,

            filterOptions: {
              type: TableFilterTypes.dateRange,
              label: 'Off date',
            },
            toText: (d) => toESTDateStringFromUnixMillisecondTimestamp(d.row.original?.effectiveCoverage?.metadata?.offDate),
            Cell: (d) => (
              <NestedArrayPopover
                title="View dates"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => toESTDateStringFromUnixMillisecondTimestamp(item?.offDate)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Coverage price',
            id: 'coveragesMetadata.annualizedCoveragePrice',
            accessor: (data) => data.effectiveCoverage?.metadata?.dailyCoveragePrice,
            filterOptions: {
              type: TableFilterTypes.currencyInput,
            },
            toText: (d) => convertDailyPriceToAnnualized(d.row.original?.effectiveCoverage?.metadata?.dailyCoveragePrice),
            Cell: (d) => (
              <NestedArrayPopover
                title="View all pricing"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => convertDailyPriceToAnnualized(item?.dailyCoveragePrice)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Maintenance contract fee',
            id: 'coveragesMetadata.dailyMaintenanceContractFee',
            accessor: (data) => data.effectiveCoverage?.metadata?.dailyMaintenanceContractFee,

            toText: (d) => convertDailyPriceToAnnualized(d.row.original?.effectiveCoverage?.metadata?.dailyMaintenanceContractFee),
            Cell: (d) => (
              <NestedArrayPopover
                title="View all pricing"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => convertDailyPriceToAnnualized(item?.dailyMaintenanceContractFee)}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Managed services fee',
            id: 'metadata.dailyManagedServicesFee',
            accessor: (data) => data.metadata?.dailyManagedServicesFee,

            Cell: (d) => convertDailyPriceToAnnualized(d.row.original?.metadata?.dailyManagedServicesFee),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Managed service dispatch fee',
            id: 'metadata.dailyServiceDispatchFee',
            accessor: (data) => data.metadata?.dailyServiceDispatchFee,

            Cell: (d) => convertDailyPriceToAnnualized(d.row.original?.metadata?.dailyServiceDispatchFee),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Conditions',
            id: 'coveragesMetadata.conditions',
            accessor: (data) => data.coveragesMetadata?.conditions,
            disableSortBy: true,
            toText: (d) => d.row.original?.coveragesMetadata?.[0]?.conditions,
            Cell: (d) => (
              <NestedArrayPopover
                title="View all conditions"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => item?.conditions}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'Exclusions',
            id: 'coveragesMetadata.exclusions',
            accessor: (data) => data.coveragesMetadata?.exclusions,
            disableSortBy: true,
            toText: (d) => d.row.original?.coveragesMetadata?.[0]?.exclusions,
            Cell: (d) => (
              <NestedArrayPopover
                title="View all exclusions"
                items={d.row.original?.coveragesMetadata || []}
                itemFilter={(item) => item?.coverageStatus === CoverageStatusType.Effective}
                itemMapper={(item) => item?.exclusions}
              />
            ),
            width: defaultColumnWidths.normalText,
          },
          {
            Header: 'PM Cadence',
            id: 'metadata.equipsCoveragePmCadence',
            accessor: (data) => data.metadata?.equipsCoveragePmCadence,
            toText: (d) => capitalize(d.row.original?.metadata?.equipsCoveragePmCadence),
            Cell: (d) => capitalize(d.row.original?.metadata?.equipsCoveragePmCadence),
            filterOptions: {
              type: TableFilterTypes.select,
              options: enumObjectToSelectOptions(equipsCoveragePmCadenceType, { anyText: 'Any', allowNull: true }),
            },
          },
          {
            Header: 'Months to PM Equipment',
            id: 'metadata.monthsToPmEquipment',
            accessor: (data) => data.metadata?.monthsToPmEquipment,
            Cell: (d) => {
              const months = (d.row.original?.metadata?.monthsToPmEquipment ?? []).sort((a, b) => {
                const weightA = monthsToPmEquipmentType[a]?.weight || 0;
                const weightB = monthsToPmEquipmentType[b]?.weight || 0;
                return weightA - weightB;
              });
              return months.join(', ');
            },
          },
        ]
      : []),
    {
      Header: 'Chronic',
      id: 'metadata.hasChronicIssues',
      accessor: (data) => data.metadata?.hasChronicIssues,
      disableSortBy: true,
      toText: (d) => (d.row.original?.metadata?.hasChronicIssues ? 'Yes' : 'No'),
      Cell: (d) => (d.row.original?.metadata?.hasChronicIssues ? 'Yes' : 'No'),
      filterOptions: {
        type: TableFilterTypes.select,
        options: [
          { label: 'Any', value: '' },
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      width: defaultColumnWidths.normalText,
    },
    ...(userCan(rolesThatCanViewCoverageData)
      ? [
          {
            Header: 'Provider',
            id: 'metadata.providerId',
            accessor: (data) => data.metadata?.providerId,

            width: defaultColumnWidths.largeText,
            Cell: (data) => data.row.original?.metadata?.provider?.metadata?.organizationName || '',
            toText: (data) => data.row.original?.metadata?.provider?.metadata?.organizationName || '',
            filterOptions: {
              type: TableFilterTypes.organizationSelect,
              hidden: false,
              typeOfOrganizationToFind: providerValueForFilter,
            },
          },
          {
            Header: 'Coverage',
            id: 'coverages',
            accessor: (data) => data.coverages,
            width: defaultColumnWidths.largeText,
            disableSortBy: true,
            Cell: EquipmentCoverageCell,
            toText: (data) => equipmentCoverageText({ data }).text,
          },
          {
            Header: 'Coverage Expired',
            id: 'metadata.coveragesMetadata.expired',
            accessor: (data) => data.coverages,
            Cell: (d) => {
              const expired = d.row?.original?.coveragesMetadata?.[0]?.expired;

              return expired === true ? 'Yes' : expired === false ? 'No' : 'N/A';
            },
          },
        ]
      : []),
    {
      Header: 'Notes',
      id: 'notes',
      accessor: (data) => data.notes,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: async (d) => {
        return standardNoteSerializerAndDeserializer.serializer(d.row.original?.notes || []);
      },
      Cell: (d) => (
        <TableCellNotes
          entityId={d.row.original?.metadata?.equipmentId || ''}
          parentLabel={getEnumValue(allowedNoteParentLabels.equipment)}
          noteQueryFunction={getEquipmentNotes}
          noteText={d.row.original?.notes?.[0]?.metadata?.message}
        />
      ),
    },
    {
      id: 'sublocationIds',
    },
    {
      id: 'equipmentId',
      Header: 'Equipment ID',
      accessor: (data) => data.equipmentId,
      width: defaultColumnWidths.largeText,
      disableSortBy: true,
      toText: (data) => data.value || '',
      Cell: (data) => <TableCellCopyData data={data.value}>{data.value}</TableCellCopyData>,
      filterOptions: {
        type: TableFilterTypes.text,
        hidden: !userCan(internalUsers),
      },
    },
  ];
}

import React from 'react';

interface TableCellStackedProps {
  testId?: string;
  line1: string | React.ReactNode;
  line2?: string | React.ReactNode;
}

export function TableCellStacked({ testId, line1, line2 }: TableCellStackedProps) {
  if (!line2) return <span data-testid={testId}>{line1}</span>;

  return (
    <div data-testid={testId} className="-my-2">
      <div className="text-sm leading-tight">{line1}</div>
      <div className="text-xs leading-tight text-gray-500">{line2}</div>
    </div>
  );
}

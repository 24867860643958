import React, { useState, useEffect } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import AttachFile from '@mui/icons-material/AttachFile';
import { SvgIconComponent } from '@mui/icons-material';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import Button from '../Buttons/Button';
import NoteAttachment from '../ViewPage/NoteAttachment';
import ActionButton from '../Buttons/ActionButton';

type AttachmentDropzoneProps = {
  label?: string;
  maxAttachments: number;
  acceptedFileTypes: string[];
  errorMessage: string;
  disabled?: boolean;
  onChange: (value: FileWithPath[]) => any;
  display?: AttachmentDropzoneDisplayType;
  Icon?: SvgIconComponent;
  className?: string;
  defaultAttachments?: FileWithPath[];
};

export enum AttachmentDropzoneDisplayType {
  Details = 'details',
  ButtonOnly = 'buttonOnly',
}

export default function AttachmentDropzone({
  maxAttachments,
  acceptedFileTypes,
  errorMessage,
  disabled,
  onChange,
  display = AttachmentDropzoneDisplayType.Details,
  Icon = AttachFile,
  className = '',
  defaultAttachments = [],
  ...props
}: AttachmentDropzoneProps) {
  const [attachments, setAttachments] = useState<FileWithPath[]>(defaultAttachments);
  const showAlert = useAlert();

  const maxAttachmentSize = 5 * 1e6; // 5MB

  const addAttachments = (newFiles: FileWithPath[]) => {
    if (attachments.length + newFiles.length > maxAttachments) {
      return showAlert({ content: `There is a ${maxAttachments} attachment limit.`, type: AlertTypes.error });
    }

    const acceptedFiles = newFiles.filter((file) => {
      const oversized = file.size > maxAttachmentSize;

      if (oversized) {
        showAlert({
          content: `${file.name} is above the ${maxAttachmentSize / 1e6}MB size limit.`,
          type: AlertTypes.error,
        });
      }

      return !oversized;
    });
    setAttachments([...acceptedFiles, ...attachments]);
  };

  const removeAttachment = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: addAttachments,
    onDropRejected: (fileRejections, event) => {
      console.log('Image upload failed', fileRejections);
      showAlert({
        content: errorMessage,
        type: AlertTypes.error,
      });
    },
    accept: acceptedFileTypes,
    maxSize: maxAttachmentSize,
    multiple: true,
  });

  useEffect(() => {
    onChange(attachments);
  }, [attachments]);

  return (
    // Don't show file picker onClick. Dummy handler doesn't need a11y lint.
    // eslint-disable-next-line
    <div {...getRootProps({ className: 'relative' })} onClick={() => {}}>
      {isDragActive && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex scale-105 transform items-center justify-center rounded-2xl border-4 border-dashed border-gray-700 bg-gray-400 bg-opacity-75 p-10 text-lg font-bold opacity-75">
          <Icon />
          Drop files here
        </div>
      )}
      <input data-testid="fileInput" {...getInputProps()} />
      {display === AttachmentDropzoneDisplayType.Details ? (
        <Button type="button" disabled={disabled} blue className={`mx-1 mt-2 ${className}`} onClick={open}>
          <Icon className="mr-1" />
          {props.label ?? 'Attach file'}
        </Button>
      ) : (
        <ActionButton onClick={open} text={props.label ?? 'Attach file'} Icon={Icon} color="green" />
      )}
      {display === AttachmentDropzoneDisplayType.Details && attachments.length > 0 && (
        <div className="ml-1 mt-5 text-sm text-gray-800">
          <strong>Attachments</strong>
          <span className="ml-3 text-xs text-gray-500">These attachments will be visible to your organization and assigned provider.</span>
          <ul className="my-2 flex flex-wrap gap-2">
            {attachments.map((file, index) => (
              <NoteAttachment key={file.name} name={file.name} type={file.type} onRemove={() => removeAttachment(index)} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

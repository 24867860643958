import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

interface SearchInputProps {
  query?: string;
  onSearch?: (query: string) => void;
}

export default function SearchInput({ query, onSearch }: SearchInputProps) {
  const [value, setValue] = useState(query || '');

  useEffect(() => {
    setValue(query || '');
  }, [query]);

  const handleSearch = () => {
    if (onSearch) {
      onSearch(value);
    }
  };

  return (
    <TextField
      fullWidth
      value={value}
      onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="flex justify-between gap-3 p-1 text-gray-700">
              <IconButton size="small" onClick={() => setValue('')}>
                <Close />
              </IconButton>
              <IconButton size="small" onClick={handleSearch}>
                <Search />
              </IconButton>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { Equipment, Location, Maybe } from '@equips/entities-schema';
import { EquipmentMultiCard } from '../Equipment/EquipmentMultiCard';
import LocationCard from '../Selects/LocationCard';

type EquipmentLocationCardProps = {
  equipment?: Maybe<Equipment>[];
  setEquipment?: Dispatch<SetStateAction<Equipment[] | undefined>>;
  location?: Maybe<Location>;
  titleText?: string;
};

export default function EquipmentLocationCard({ equipment, setEquipment, location, titleText = 'Selected' }: EquipmentLocationCardProps) {
  return (
    <>
      <div className="mb-1 font-semibold text-gray-700">
        {titleText} {equipment && equipment.length > 0 ? 'Equipment' : 'Location'}
      </div>
      <div className="rounded-lg border border-gray-400 px-5 py-3 text-gray-800">
        {equipment && equipment.length > 0 ? (
          <EquipmentMultiCard equipment={equipment} setSelectedEquipment={setEquipment} />
        ) : location ? (
          <LocationCard location={location} />
        ) : (
          <p className="py-3 text-gray-700">No equipment or location selected.</p>
        )}
      </div>
    </>
  );
}

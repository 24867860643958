import React from 'react';
import { toDateStringFromUnixMillisecondTimestamp } from '../../functions/dateFunctions';
import DisplayTime from '../Date/DisplayTime';
import StackedColumn from './StackedColumn';

export const defaultColumns = {
  createdColumnQueryString: `createdAt createdByUser { metadata { fullName } }`,
  createdColumn: {
    Header: 'Created',
    accessor: 'metadata.createdAt',
    sortable: true,
    width: 150,
    toText: (data) =>
      `${toDateStringFromUnixMillisecondTimestamp(data?.original?.metadata?.createdAt, { customFormatString: 'MM/DD/YYYY hh:mm a' })} ${
        data?.original?.metadata?.createdByUser?.metadata?.fullName || ''
      }`,
    Cell: (data) => {
      const createdBy = data?.original?.metadata?.createdByUser?.metadata?.fullName;

      return <StackedColumn line1={<DisplayTime timestamp={data.value} />} line2={createdBy} />;
    },
  },
  modifiedColumnQueryString: `modifiedAt modifiedByUser { metadata { fullName } }`,
  modifiedColumn: {
    Header: 'Modified',
    accessor: 'metadata.modifiedAt',
    sortable: true,
    width: 150,
    toText: (data) =>
      `${toDateStringFromUnixMillisecondTimestamp(data?.original?.metadata?.modifiedAt, { customFormatString: 'MM/DD/YYYY hh:mm a' })} ${
        data?.original?.metadata?.modifiedByUser?.metadata?.fullName || ''
      }`,
    Cell: (data) => {
      const createdBy = data?.original?.metadata?.modifiedByUser?.metadata?.fullName;

      return <StackedColumn line1={<DisplayTime timestamp={data.value} />} line2={createdBy} />;
    },
  },
};

import React, { useContext } from 'react';
import {
  ClientOrganizationEnumType,
  OrganizationBillingMethodType,
  OrganizationFeatureFlagEnum,
  ServiceTypeEnum,
  UserAuthorizationRoleEnum,
  UserTagsType,
} from '@equips/entities-schema';
import Urls from '../../../routes/Urls';
import SubmitRequest from '../ServiceRequest/SubmitRequest';
import { ModalContext } from '../../providers/ModalProvider';
import { admins, nonProviders } from '../../auth/roles';
import { AuthContextValue } from '../../auth/AuthContext';

type MenuItemType = { content: string; nested: { to?: string; content: string; onClick?: () => any }[] };

const useNavItems = (authData: AuthContextValue): MenuItemType[] => {
  const {
    auth,
    userCan,
    internalUsers,
    permissions,
    roles,
    clientOrganizationType,
    determineFeatureFlagVisibility,
    organizationBillingMethod,
    isAasUser,
  } = authData;

  const { showModal } = useContext(ModalContext);

  const navItems = {
    service: {
      viewRequests: { to: Urls.SERVICE_REQUESTS, content: 'View Requests' },
      viewRecurringPMs: {
        to: `${Urls.SERVICE_REQUESTS}?metadata.recurrenceInterval=all&metadata.requestStatus=%5B"internalDispatch"%2C"equipsDispatch"%2C"providerDispatch"%5D&metadata.serviceType=%5B"Preventive"%5D`,
        content: 'View Recurring PMs',
      },
      submitServiceRequest: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Corrective} />),
        content: 'Submit Service Request',
      },
      schedulePreventiveMaintenance: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Preventive} />),
        content: 'Schedule Preventive Maintenance',
      },
      orderConsumable: {
        onClick: () => showModal(() => <SubmitRequest serviceType={ServiceTypeEnum.Consumables} />),
        content: 'Order Consumable',
      },
      dispatch: { to: Urls.SERVICE_REQUEST_DISPATCH, content: 'Dispatch' },
      schedule: { to: Urls.SCHEDULE, content: 'Schedule' },
      squads: { to: Urls.SQUADS, content: 'Squads' },
    },
    equipment: {
      viewEquipment: { to: Urls.EQUIPMENT, content: 'View Equipment' },
      addEquipment: { to: Urls.EQUIPMENT_CREATE, content: 'Add Equipment' },
      viewChronicIssues: { to: `${Urls.EQUIPMENT}?metadata.hasChronicIssues=true`, content: 'View Chronic Issues' },
      generateQRCodes: { to: Urls.EQUIPMENT_QR, content: 'Generate QR Codes' },
      pmSchedule: { to: Urls.EQUIPMENT_PM, content: 'PM Schedule' },
    },
    admin: {
      locations: { to: Urls.LOCATIONS, content: 'Locations' },
      users: { to: Urls.USERS, content: 'Users' },
      agreements: { to: Urls.AGREEMENTS, content: 'Agreements' },
      serviceContracts: { to: Urls.SERVICE_CONTRACTS, content: 'Service Contracts' },
      customerInvoices: { to: Urls.CUSTOMER_INVOICES, content: 'Invoices' }, // payment screen
      providerInvoices: { to: Urls.PROVIDER_INVOICES, content: 'Invoices' }, // legacy payment screen
      documents: { to: Urls.DOCUMENTS, content: 'Documents' },
      ihtInvoices: { to: Urls.IHT_INVOICES, content: 'In House Tech' },
      import: { to: Urls.IMPORT, content: 'Import' },
      nonEquipsInvoices: { to: Urls.AAS_INVOICES, content: 'Invoices' }, // invoice migration
      inventory: { to: Urls.INVENTORY, content: 'Inventory' },
    },
    aas: {
      contracts: { to: Urls.AAS_CONTRACTS, content: 'Contracts' },
      contractsData: { to: Urls.AAS_CONTRACTS_DATA, content: 'Contracts Data' },
      claims: { to: Urls.AAS_CLAIMS, content: 'Claims' },
      claimForm: { to: Urls.AAS_FORM, content: 'Claim Form' },
      import: { to: Urls.AAS_IMPORT, content: 'Import' },
      invoices: { to: Urls.AAS_INVOICES, content: 'Invoices' },
      payables: { to: Urls.AAS_PAYABLES, content: 'Payables' },
      providers: { to: Urls.AAS_ORGANIZATIONS, content: 'Providers' },
    },
    homeWarranty: {
      contracts: { to: Urls.SERVICE_CONTRACTS, content: 'Contracts' },
      claims: { to: Urls.CLAIMS, content: 'Claims' },
      invoices: { to: Urls.AAS_INVOICES, content: 'Invoices' },
      payables: { to: Urls.AAS_PAYABLES, content: 'Payables' },
      providers: { to: Urls.HOME_WARRANTY_ORGANIZATIONS, content: 'Providers' },
    },
    equips: {
      serviceInvoices: { to: Urls.INVOICES, content: 'Service Invoices' }, // invoices screen
      providerInvoices: { to: Urls.PROVIDER_INVOICES, content: 'Provider Invoices' }, // legacy payment screen
      coverages: { to: Urls.COVERAGE, content: 'Coverages' },
      charges: { to: Urls.CHARGES, content: 'Charges' },
      subscriptions: { to: Urls.SUBSCRIPTIONS, content: 'Subscriptions' },
      incidents: { to: Urls.INCIDENTS, content: 'Incidents' },
      organizations: { to: Urls.ORGANIZATIONS, content: 'Organizations' },
      categories: { to: Urls.CATEGORIES, content: 'Categories' },
      specs: { to: Urls.SPECS, content: 'Specs' },
      reporting: { to: Urls.REPORTING, content: 'Reports' },
      notifications: { to: Urls.NOTIFICATIONS, content: 'Notifications' },
      feedback: { to: Urls.FEEDBACK, content: 'Feedback' },
      tests: { to: Urls.TESTS, content: 'Tests ' },
    },
  };

  const buildLeft = () => {
    const leftItems: MenuItemType[] = [];

    if (!auth || (auth && !auth.initialized) || userCan([UserAuthorizationRoleEnum.ProviderEmployee])) {
      return leftItems;
    }

    const addMenuItem = (content, nested) => {
      leftItems.push({
        content,
        nested,
      });
    };

    if (isAasUser) {
      addMenuItem('Claim Form', [navItems.aas.claimForm]);
      addMenuItem('Claims', [navItems.aas.claims]);
      addMenuItem('Contracts', [navItems.aas.contracts]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)) {
        addMenuItem('Invoices', [navItems.aas.invoices]);
      }

      addMenuItem('Admin', [
        ...(userCan([UserAuthorizationRoleEnum.CustomerLocationAdmin])
          ? [navItems.admin.locations]
          : [
              navItems.admin.locations,
              navItems.aas.contractsData,
              navItems.aas.providers,
              ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
              navItems.aas.payables,
              navItems.aas.import,
            ]),
        ...(auth?.tags?.includes(UserTagsType.AasAdmin) || auth.tags?.includes(UserTagsType.AasUserAdministrator)
          ? [navItems.admin.users]
          : []),
      ]);
      return leftItems;
    }

    if (clientOrganizationType === ClientOrganizationEnumType.EquipsHomeWarranty) {
      addMenuItem('Claims', [navItems.homeWarranty.claims]);
      addMenuItem('Contracts', [navItems.homeWarranty.contracts]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)) {
        addMenuItem('Invoices', [navItems.homeWarranty.invoices]);
      }

      addMenuItem('Admin', [
        ...(userCan([UserAuthorizationRoleEnum.CustomerLocationAdmin]) ? [navItems.admin.locations] : []),
        ...(userCan([UserAuthorizationRoleEnum.CustomerOrganizationAdmin])
          ? [
              navItems.admin.locations,
              navItems.homeWarranty.providers,
              ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
              navItems.homeWarranty.payables,
              navItems.admin.import,
            ]
          : []),
        ...(userCan(internalUsers) ? [navItems.admin.users] : []),
      ]);
      return leftItems;
    }

    if (userCan(internalUsers)) {
      addMenuItem('Service', [
        navItems.service.viewRequests,
        ...(permissions.isCoverageInternalUser || permissions.isWOMInternalUser
          ? [
              navItems.service.viewRecurringPMs,
              navItems.service.submitServiceRequest,
              navItems.service.schedulePreventiveMaintenance,
              navItems.service.orderConsumable,
              ...(permissions.isCoverageInternalUser ? [navItems.service.dispatch] : []),
              navItems.service.schedule,
              ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Squad) ? [navItems.service.squads] : []),
            ]
          : []),
      ]);

      if (
        (permissions.isCoverageInternalUser || permissions.isWOMInternalUser) &&
        determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)
      ) {
        addMenuItem('Equipment', Object.values(navItems.equipment));
      }

      addMenuItem('Admin', [
        navItems.admin.locations,
        navItems.admin.users,
        ...(userCan(internalUsers) ? [navItems.admin.serviceContracts] : []),
        ...(permissions.isCoverageInternalUser ? [navItems.admin.agreements, navItems.admin.customerInvoices] : []),
        // invoice migration
        ...(permissions.isWOMInternalUser && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)
          ? [navItems.admin.nonEquipsInvoices]
          : []),
        navItems.admin.documents,
        ...(permissions.userCanViewInHouseTechForm ? [navItems.admin.ihtInvoices] : []),
        navItems.admin.import,
        ...(determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.Parts) ? [navItems.admin.inventory] : []),
      ]);

      if ((userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin]) && permissions.isAASInternalUser) || isAasUser) {
        addMenuItem('AAS', Object.values(navItems.aas));
      }

      addMenuItem('Equips', [
        ...(permissions.isCoverageInternalUser
          ? [
              navItems.equips.serviceInvoices,
              navItems.equips.providerInvoices,
              navItems.equips.coverages,
              navItems.equips.charges,
              navItems.equips.subscriptions,
              navItems.equips.incidents,
            ]
          : []),
        // not using !permissions.isAASInternalUser to make it work for userTags = [] (assume allow all)
        ...(permissions.isCoverageInternalUser || permissions.isWOMInternalUser
          ? [navItems.equips.organizations, navItems.equips.categories, navItems.equips.specs, navItems.equips.reporting]
          : []),
        navItems.equips.notifications,
        navItems.equips.feedback,
        ...(userCan([UserAuthorizationRoleEnum.SuperGlobalAdmin, UserAuthorizationRoleEnum.GlobalAdmin]) ? [navItems.equips.tests] : []),
      ]);
    } else {
      const canSubmitServiceRequest = userCan(nonProviders);
      const canSchedulePreventiveMaintenance =
        userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.DashboardPreventativeMaintenance);
      const canOrderConsumable = userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.DashboardConsumables);
      const canPmSchedule =
        userCan(admins) && userCan(nonProviders) && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.PmScheduleAdvanced);

      addMenuItem('Service', [
        navItems.service.viewRequests,
        navItems.service.viewRecurringPMs,
        ...(canSubmitServiceRequest ? [navItems.service.submitServiceRequest] : []),
        ...(canSchedulePreventiveMaintenance ? [navItems.service.schedulePreventiveMaintenance] : []),
        ...(canOrderConsumable ? [navItems.service.orderConsumable] : []),
        ...(clientOrganizationType === ClientOrganizationEnumType.EquipsWorkOrderManagement ? [navItems.service.schedule] : []),
      ]);

      if (determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.EquipmentVisibility)) {
        addMenuItem('Equipment', [
          navItems.equipment.viewEquipment,
          ...(userCan(admins) && userCan(nonProviders) ? [navItems.equipment.addEquipment] : []),
          ...(userCan(nonProviders) ? [navItems.equipment.viewChronicIssues] : []),
          ...(userCan(admins) && userCan(nonProviders) ? [navItems.equipment.generateQRCodes] : []),
          ...(canPmSchedule ? [navItems.equipment.pmSchedule] : []),
        ]);
      }

      if (userCan(admins)) {
        addMenuItem('Admin', [
          navItems.admin.locations,
          navItems.admin.users,
          ...(userCan(nonProviders) ? [navItems.admin.agreements] : []),
          ...(clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
          organizationBillingMethod === OrganizationBillingMethodType.SingleSourceBilling
            ? [navItems.admin.customerInvoices]
            : []),
          // invoice migration
          ...(permissions.isWOMInternalUser && determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.InvoiceVisibility)
            ? [navItems.admin.nonEquipsInvoices]
            : []),
          ...(clientOrganizationType === ClientOrganizationEnumType.EquipsCoverage &&
          organizationBillingMethod === OrganizationBillingMethodType.ExternalBilling
            ? [navItems.admin.providerInvoices]
            : []),
          navItems.admin.documents,
          ...(permissions.userCanViewInHouseTechForm ? [navItems.admin.ihtInvoices] : []),
          navItems.admin.import,
        ]);
      }

      if (auth.authorizationRole === roles.customerLocationAdmin) {
        addMenuItem('Admin', [navItems.admin.locations]);
      }
    }
    return leftItems;
  };

  return buildLeft();
};

export default useNavItems;
